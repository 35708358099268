import {feedbackConstants} from "../constants/feedbackConstants";
import API from "../utils/API";
import {dateFormat, toDateTime} from "../utils/helpers";
import {history} from "../utils/history";

export const setFeedbacks = (data) => ({type: feedbackConstants.SET_FEEDBACK, payload: data});

export const receiveFeedbacks = (params = {page: 1}) => (dispatch) => {
   API.get('review',{params})
      .then(({data}) => {
         const items = [];
         data.items.forEach(review => {
            items.push({
               id: review.id,
               name: review.name,
               email: review.email,
               text: review.text.slice(0,40),
               published: review.published === 1 ? "Да" : "Нет",
               links: {
                  delete: true,
                  edit: `/update/${review.id}`
               }
            });
         })
         dispatch(setFeedbacks({data:items, meta: data._meta}));
      }).catch(console.log);
}

export const receiveCurrentFeedback = (id) => (dispatch) => {
   return API.get(`review/view/${id}`)
      .then(({data}) => {
         return {
            id: data.id,
            name: data.name,
            email: data.email,
            text: data.text,
            published: dateFormat(data['date_create']),
            links: {
               delete: true,
               edit: `/update/${data.id}`
            }
         };
      })
}

export const deleteFeedback = (id) => (dispatch) => {
   API.delete(`review/delete/${id}`)
      .then(() => {
         dispatch(receiveFeedbacks());
      });
}

export const updateFeedback = (id, formData) => {
   API.put(`review/update/${id}`, formData)
      .then(() => {
         history.push(`/feedback`);
      });
}
