import React, { useState, useEffect } from "react";

import API from "../../../../utils/API";

import { RenderImage } from "../../../Fields/RenderImage";
import { RenderFile } from "../../../Fields/RenderFile";
import { ButtonSave } from "../../../Buttons/ButtonSave";

const PATH_STATIC = "http://dc.websalamat.ru/img/";

export const City = ({ id, name, ...props }) => {
   const [background1, setBackground1] = useState("");
   const [background2, setBackground2] = useState("");
   const [video1, setVideo1] = useState("");
   const [video2, setVideo2] = useState("");
   const [isNightMenu, setIsNightMenu] = useState("0");
   const [isNightBackground, setIsNightBackground] = useState(false);

   useEffect(() => {
      setBackground1(
         props.background1 === null ? "" : PATH_STATIC + props.background1
      );
      setBackground2(
         props.background2 === null ? "" : PATH_STATIC + props.background2
      );
      setVideo1(props.video1 === null ? "" : props.video1);
      setVideo2(props.video2 === null ? "" : props.video2);
      setIsNightMenu(props.iswhite);
      setIsNightBackground(props.isnight);
   }, [
      props.background1,
      props.background2,
      props.video1,
      props.video2,
      props.iswhite,
      props.isnight,
   ]);

   const handleAddFile = (name) => (file) => {
      if (name === "background1") {
         setBackground1(file);
      } else if (name === "background2") {
         setBackground2(file);
      } else if (name === "video1") {
         setVideo1(file);
      } else if (name === "video2") {
         setVideo2(file);
      }
   };

   const handleDeleteFile = (name) => () => {
      if (name === "background1") {
         setBackground1("");
      } else if (name === "background2") {
         setBackground2("");
      } else if (name === "video1") {
         setVideo1("");
      } else if (name === "video2") {
         setVideo2("");
      }
   };

   const handleChangeCheckbox = (name) => ({ target: { checked } }) => {
      if (name === "isWhite") {
         setIsNightMenu(checked ? 1 : 0);
      } else if (name === "isNightBackground") {
         setIsNightBackground(checked ? 1 : 0);
      }
   };

   const handleSubmit = () => {
      let data = new FormData();

      if (typeof background1 !== "string") {
         data.append("background1", background1);
      }
      if (typeof background2 !== "string") {
         data.append("background2", background2);
      }
      if (typeof video1 !== "string") {
         data.append("video1", video1);
      }
      if (typeof video2 !== "string") {
         data.append("video2", video2);
      }

      const config = { headers: { "Content-Type": "multipart/form-data" } };

      API.post(`city/updateimage/${id}`, data, config)
         .then()
         .catch((error) => {
            console.log(error);
         });

      const data2 = {
         iswhite: isNightMenu,
         isnight: isNightBackground,
      };

      API.put(`city/update/${id}`, data2)
         .then()
         .catch((error) => {
            console.log(error);
         });
   };

   return (
      <div className="background-settings__col">
         <div className="background-settings__city">
            <h3 className="background-settings__city__name">{name}</h3>
            <div className="background-settings__form-group">
               <div className="background-settings__form-group__label">
                  Загрузить видео или фото:
               </div>
               <RenderFile
                  type="file"
                  accept="image/*,video/*"
                  input={{ value: video1 }}
                  setMediaData={(e) => handleAddFile("video1")(e)}
               />
            </div>
            <div className="background-settings__form-group">
               <div className="background-settings__form-group__label">
                  Если видео не воспроизводится (для фото):
               </div>
               <RenderImage
                  input={{ value: background1 }}
                  setMediaData={(e) => handleAddFile("background1")(e)}
                  handleDelete={() => handleDeleteFile("background1")()}
               />
            </div>
            <div className="background-settings__form-group">
               <div className="background-settings__form-group__checkbox">
                  <input
                     id={`is-white-${id}`}
                     className="check-custom"
                     type="checkbox"
                     checked={isNightMenu === 1 ? true : false}
                     onChange={(e) => handleChangeCheckbox("isWhite")(e)}
                  />
                  <label htmlFor={`is-white-${id}`}>
                     Белое меню для ночного режима
                  </label>
               </div>
            </div>
            <div className="background-settings__form-group">
               <div className="background-settings__form-group__checkbox">
                  <input
                     id={`is-other-bg-${id}`}
                     className="check-custom"
                     name="check"
                     type="checkbox"
                     checked={isNightBackground === 1 ? true : false}
                     onChange={(e) =>
                        handleChangeCheckbox("isNightBackground")(e)
                     }
                  />
                  <label htmlFor={`is-other-bg-${id}`}>
                     Другой фон для ночного режима
                  </label>
               </div>
            </div>
            {isNightBackground === 1 && (
               <>
                  <div className="background-settings__form-group">
                     <div className="background-settings__form-group__label">
                        Загрузить видео или фото:
                     </div>
                     <RenderFile
                        type="file"
                        input={{ value: video2 }}
                        setMediaData={(e) => handleAddFile("video2")(e)}
                     />
                  </div>
                  <div className="background-settings__form-group">
                     <div className="background-settings__form-group__label">
                        Если видео не воспроизводится (для фото):
                     </div>
                     <RenderImage
                        input={{ value: background2 }}
                        setMediaData={(e) => handleAddFile("background2")(e)}
                        handleDelete={() => handleDeleteFile("background2")()}
                     />
                  </div>
               </>
            )}
            <ButtonSave type="button" onClick={handleSubmit} />
         </div>
      </div>
   );
};
