import React from "react";
import { Header } from "../../Header/Header";
import Form from "./Form";
import {useDispatch} from "react-redux";
import {updateQuestion} from "../../../actions/questionsActions";
import {useHistory} from "react-router-dom";

export const AnswersContainer = () => {
   const dispatch = useDispatch();
   let history = useHistory();

   const handleSave = (values) => {
     dispatch(updateQuestion(values.id,{...values,published: 1}));
     history.goBack();
   }

   return (
      <>
         <Header title="Вопрос-ответ" />

         <div className="card">
            <Form onSubmit={(e) => handleSave(e)} />
         </div>
      </>
   );
};
