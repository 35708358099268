import React, {useEffect, useState} from "react";
import { a11yProps, AntTab, AntTabs, TabPanel } from "./Tabs";

export const TabsContent = ({ tabs, activeTab = 0, handleSave = false }) => {
   const [value, setValue] = useState(activeTab);

   const handleChange = (event, newValue) => {
      setValue(newValue);
      handleSave && handleSave(newValue);
   };

   useEffect(() => {
      setValue(activeTab);
   },[activeTab]);

   return (
      <>
         <AntTabs aria-label="Вкладки" value={value} onChange={handleChange}>
            {tabs.map((tab, index) => (
               <AntTab key={index} label={tab.label} {...a11yProps(index)} />
            ))}
         </AntTabs>

         {tabs.map((panel, index) => (
            <TabPanel key={index} value={value} index={index}>
               {panel.components}
            </TabPanel>
         ))}
      </>
   );
};
