/* создание страницы */
import API from "../utils/API";
import {reviewsConstants} from "../constants/reviewsConstants";
import store from "../store/configureStore";
import {receiveQuestionsTotal, receiveRequestsTotal} from "./noticeActions";

export const setQuestions = (data) => ({type: reviewsConstants.SET_ITEMS, payload: {data}});
export const setThemes = (data) => ({type: reviewsConstants.SET_THEMES, payload: {data}});
export const setDetail = (data) => ({type: reviewsConstants.SET_DETAIL, payload: {data}});

export const receiveDetail = (id, initialize) => (dispatch) => {
   API.get(`quest/view/${id}`)
      .then(({data}) => {
         let quest = {
            ...data,
            theme: {label: data.theme.trim(), value: data.theme.trim()},
            photos: data.images.map(item => ({...item, src: item ? 'http://dc.websalamat.ru/img/' + item.image : false, alt: data.id}))
         }
         dispatch(setDetail(quest));
         initialize(quest);
      });
};

export const receiveThemes = () => (dispatch) => {
   API.get(`quest/themes`)
      .then(({data}) => {
         dispatch(setThemes(data.filter(item => item !== "null").map(item => item.trim())));
      });
}

export const deleteQuestion = (id) => (dispatch) => {
   API.delete(`quest/delete/${id}`)
      .then(r => {
         if(r)
         {
            dispatch(receiveQuestions());
            dispatch(receiveQuestionsTotal());
         }
      });
};

export const updateQuestion = (id, data) => (dispatch) => {
   let formData = new FormData();

   Object.keys(data).map(key => {
      if(data[key] && typeof data[key] === 'object')
         formData.append(key,data[key].value);
      else if(data[key])
         formData.append(key,data[key]);
   });

   API.put(`quest/update/${id}`, formData)
      .then(r => {
         if(r)
         {
            if(data.images)
            {
               let difference = data.images.filter(x => !data.photos.some(y => x.id === y.id));
               if(difference)
                  difference.map( item => {
                     API.delete(`media/delete/${item.id}`);
                  });
            }
            dispatch(setQuestions([]));
         }
      });
}

export const receiveQuestions = (url,filter, page) => (dispatch) => {
   API.get(`quest`, {
      params: {
         page: page,
         theme: filter && filter.value,
      }
   })
      .then(async ({data}) => {

         let listData = data.items;

        /* if(filter)
            listData = listData.filter(item => item.theme == filter.value);*/

         const dataList = await listData.map(item => ({
            id: item.id,
            name: item.name,
            email: item.email,
            theme: item.theme,
            quest: item.quest && item.quest.substr(0,40),
            published: item.published,
            links: {
               delete: true,
               edit: `/${item.id}/update`,
            },
         }));

         dispatch(setQuestions({
            items: dataList,
            meta: data._meta
         }));
      });
}
