import React from "react";
import { Header } from "../../Header/Header";
import { CanvasUpdateHome } from "../../Canvas/CanvasCreate/CanvasUpdateHome";

export const HomeUpdate = () => {
   return (
      <>
         <Header title="Редактировать дом на карте" />
         <div className="card card-create">
            <CanvasUpdateHome />
         </div>
      </>
   );
};
