export const cityConstants = {
   FETCH_CITY_SUCCESS: "FETCH_CITY_SUCCESS",
   FETCH_CITY_FAILURE: "FETCH_CITY_FAILURE",

   DELETE_CITY: "DELETE_CITY",
   IS_FETCHING_CITY: "IS_FETCHING_CITY",

   FETCH_CITY_DETAILS_SUCCESS: "FETCH_CITY_DETAILS_SUCCESS",
   FETCH_CITY_DETAILS_FAILURE: "FETCH_CITY_DETAILS_FAILURE",
};
