import React, {useEffect, useState} from "react";
import Form from "./Form/Form";
import { Header } from "../../../Header/Header";
import {addNews, updateNews} from "../../../../actions/newsAction";
import {useDispatch} from "react-redux";

export const CreateOrUpdatePage = ({match}) => {

   const dispatch = useDispatch();
   const [mediaData, setMediaData] = useState(null);
   const [files, setFiles] = useState([]);
   const [isEditMode, setIsEditMode] = useState(match.path.includes('update'));
   const [deleteIDs, setDeleteIDs] = useState([]);
   useEffect(() => {
      setIsEditMode(match.path.includes('update'));
   }, [match.path]);


   const onSubmit = (formData) => {
      const data = {...formData};
      data.published = '1';
      data.image = mediaData;
      data.images = files;
      dispatch(isEditMode ? updateNews(match.params.id, data, deleteIDs) : addNews(data));
   }

   return (
      <>
         <Header title={`${isEditMode ? 'Изменить' : 'Добавить'} новость`} />

         <div className="card">
            <Form
               onSubmit={onSubmit}
               setMediaData={setMediaData}
               files={files}
               setFiles={setFiles}
               id={match.params.id}
               setDeleteIDs={setDeleteIDs}
            />
         </div>
      </>
   );
};
