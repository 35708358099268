import React, {useState} from "react";
import {Link, useRouteMatch} from "react-router-dom";
import { ButtonDelete } from "../../Buttons/ButtonDelete";
import { HideModal } from "../../Modal/AlertModal";
import {useDispatch} from "react-redux";
import {deleteWorker} from "../../../actions/workerActions";

export const EmployerListContainer = ({ workers }) => {
   let { url } = useRouteMatch();
   const dispatch = useDispatch();

   const [workerId, setWorkerId] = useState(null);
   const [open, setOpen] = useState(false);

   const handleOpenModal = (id) => {
      setOpen(true);
      setWorkerId(id);
   };

   const handleDelete = () => {
      if(workerId){
         dispatch(deleteWorker(workerId));
         setOpen(false);
      }
   };

   return (
      <>
         <div className="group min-mb manager-control ">
            <Link to={`${url}/employer/create`} className="btn btn--green">
               + Добавить сотрудника
            </Link>
         </div>
         <div className="card card--width">
            <ul className="list-none list-sub">
               {
                  workers
                  &&
                     workers.map((item,index) =>
                        <li key={index} className="list-sub__item flex flex--sb">
                           <Link className="list-sub__link" to={`${url}/${item.id}/employer`}>
                              {item.name}
                           </Link>
                           <div className="group">
                              <ButtonDelete onClick={() => handleOpenModal(item.id)} w={22} h={22} />
                              <Link
                                 to={`${url}/employer/update/${item.id}`}
                                 className="btn-option  btn-reset"
                                 title="Редактировать"
                                 aria-label="Редактировать"
                              >
                                 <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                 >
                                    <path
                                       d="M16.6 1L1.51429 16.3936L1 21L5.91429 20.8834L21 5.4898L16.6 1Z"
                                       stroke="black"
                                    />
                                 </svg>
                              </Link>
                           </div>
                        </li>
                     )
               }
            </ul>
         </div>
         <HideModal open={open} setOpen={setOpen} title="Удалить сотрудника?" handleDelete={() => handleDelete()} />
      </>
   );
};
