export const parkingConstants = {
   SET_PARKING_LIST: "SET_PARKING_LIST",
   CREATE_PARKING: "CREATE_PARKING",
   UPDATE_PARKING: "UPDATE_PARKING",
   DELETE_PARKING: "DELETE_PARKING",
   GET_PARKING_BY_ID: "GET_PARKING_BY_ID",
   UPDATE_PARKING_IMAGE: "UPDATE_PARKING_IMAGE",

   SET_PARKING_LEVELS_LIST: "SET_PARKING_LEVELS_LIST",
   CREATE_PARKING_LEVEL: "CREATE_PARKING_LEVEL",
   UPDATE_PARKING_LEVEL: "UPDATE_PARKING_LEVEL",
   DELETE_PARKING_LEVEL: "DELETE_PARKING_LEVEL",
   GET_PARKING_LEVEL_BY_ID: "GET_PARKING_LEVEL_BY_ID",
   UPDATE_PARKING_LEVEL_IMAGE: "UPDATE_PARKING_LEVEL_IMAGE",

   SET_PARKING_PLACES_LIST: "SET_PARKING_PLACES_LIST",
   CREATE_PARKING_PLACE: "CREATE_PARKING_PLACE",
   UPDATE_PARKING_PLACE: "UPDATE_PARKING_PLACE",
   DELETE_PARKING_PLACE: "DELETE_PARKING_PLACE",
   GET_PARKING_PLACE_BY_ID: "GET_PARKING_PLACE_BY_ID",
   UPDATE_PARKING_PLACE_IMAGE: "UPDATE_PARKING_PLACE_IMAGE",

   RESET_PARKING_LEVELS: 'RESET_PARKING_LEVELS',
   RESET_PARKING_LEVEL: 'RESET_PARKING_LEVEL',
   RESET_PARKING_PLACES: 'RESET_PARKING_PLACES',
   RESET_PARKING_PLACE: 'RESET_PARKING_PLACE',
};
