import { Header } from "../Header/Header";
import { CanvasContainer } from "./CanvasContainer";
import React, {useEffect, useState} from "react";
import DescriptionForm from "./DescriptionForm";
import {FooterFloor} from "./Footer";
import {useHistory, useRouteMatch} from "react-router-dom";
import {addFloor, updateFloor} from "../../actions/floorActions";
import {useSelector} from "react-redux";

export const FloorContainer = () => {
   const history = useHistory();
   let match  = useRouteMatch();
   const { floor_id, entrance_id } = match.params;
   const [image, setImage] = useState(false);

   const floor  = useSelector(state => state.floor.data);
   const { tplaparts } = floor;

   useEffect(() => {
      if(floor.image)
         setImage(floor.image);
   },[floor]);

   const handleSave = async (values) => {
      const formData = new FormData();
      await Object.keys(values).map(key => {
         formData.append(key,values[key]);
      });
      formData.append("entrance_id",entrance_id);

      formData.delete("image");
      if(typeof image === 'object')
      {
         formData.append("image", image);
      }

      if(floor_id)
         updateFloor(floor_id, formData).then(() => {
            history.goBack();
         });
      else
         addFloor(formData).then(() => {
            history.goBack();
         });
   }

   return (
      <>
         <Header title="Редактировать этаж" />
         <DescriptionForm
            onSubmit={handleSave} />
         <CanvasContainer
            file={image}
            tplaparts={tplaparts}
            setImage={(images) => setImage(images.photo[0])}
         />
         <FooterFloor />
      </>
   );
};
