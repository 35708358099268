import React from "react";
import Form from "./Form";
import {useSelector} from "react-redux";
import { Redirect } from "react-router-dom"

export const Login = () => {
   const auth = useSelector(state => state.auth);

   if (auth.loggedIn) {
      return <Redirect to="/" />
   }

   return (
      <div className="auth-page flex flex--ac">
         <Form />
      </div>
   );
};
