import React, {useState} from 'react';
import {Line} from 'react-konva';

export const Figure = ({polygon, handleRedirect}) => {
   const [hover, setHover] = useState(false);

   const handleMouseOver = () => {
      setHover(true);
      document.body.style.cursor = 'pointer';
   };

   const handleMouseOverOut = () => {
      document.body.style.cursor = 'default';
      setHover(false);
   };

  return (
     <Line
        points={polygon.points.flatMap(p => [p.x, p.y])}
        stroke="red"
        strokeWidth={3}
        closed="true"
        fill = {hover ? '#cadeb4' : '#e0f5c0'}
        opacity={0.5}
        onMouseOver={() => handleMouseOver()}
        onMouseOut={() => handleMouseOverOut()}
        onClick={() => handleRedirect()}
        // onMouseMove={(e) => handleHover(e, polygon.id)}
     />
  )
};
