import React, {useEffect, useState} from "react";
import { Field, reduxForm } from "redux-form";
import { ButtonBack } from "../../Buttons/ButtonBack";
import { ButtonPublished } from "../../Buttons/ButtonPublised";
import { ButtonUnpublish } from "../../Buttons/ButtonUnpublish";
import {receiveCurrentFeedback} from "../../../actions/feedbackAction";
import {useDispatch} from "react-redux";

const Form = ({ handleSubmit, id, initialize, handleUnpublish, handlePublish}) => {

   const dispatch = useDispatch();
   const [feedBack, setFeedBack] = useState({});

   useEffect(() => {
      dispatch(receiveCurrentFeedback(id))
         .then((feedBack) => {
            setFeedBack(feedBack);
            initialize({text: feedBack.text})
         });
   }, [id]);

   return (
      <form className="form form-feedback" onSubmit={handleSubmit}>
         <div className="form-feedback__head form__control form__border">
            <div className="form__item">
               <b>Email:</b>
               {feedBack.email}
            </div>
         </div>

         <div className="form-info flex flex--sb">
            <p className="mb-none mt-none">{feedBack.name}</p>
            <span>{feedBack.published}</span>
         </div>
         <label htmlFor="text" className="visually-hidden">
            Описание отзыва
         </label>
         <Field
            name="text"
            id="text"
            component="textarea"
            className="control"
         />
         <div className="form__foot button-group flex">
            <ButtonBack />
            <ButtonPublished onClick={handlePublish} />
            <ButtonUnpublish onClick={handleUnpublish}/>
         </div>
      </form>
   );
};

export default reduxForm({
   form: "flat-form",
})(Form);
