import React, { useEffect, useState } from "react";
import { Header } from "../../Header/Header";
import { Link, useRouteMatch } from "react-router-dom";
import { TableList } from "../../TableList/TableList";
import { useDispatch, useSelector } from "react-redux";
import { deletePromo, receivePromo } from "../../../actions/promoAction";
import { getPromos } from "../../../selectors/promo-selectors";
import { Pagination } from "../../Pagination/Pagination";

export const OfferListContainer = () => {
   let { url } = useRouteMatch();
   const dispatch = useDispatch();
   const [filter, setFilter] = useState({ page: 1 });
   let head = [
      "ID",
      "Заголовок",
      "Порядок",
      "Дата действия с",
      "Дата действия по",
      "Ссылки",
   ];
   const { data } = useSelector(getPromos);
   const { items, metaData } = data || {};
   const { totalCount, pageCount, currentPage } = metaData || {};

   useEffect(() => {
      dispatch(receivePromo(filter));
   }, [dispatch, JSON.stringify(filter)]);

   const onSetPage = (page) => {
      setFilter((state) => ({
         ...state,
         page,
      }));
   };
   return (
      <>
         <Header title="Акции" />

         <div className="card">
            <div className="card__head">
               <Link
                  to={`${url}/create`}
                  className="btn btn--green btn--no-border"
               >
                  Добавить
               </Link>
            </div>
            <TableList
               head={head}
               list={items || []}
               handleDelete={deletePromo}
               pageName="offer"
            />
            <Pagination
               pageCount={pageCount}
               currentPage={currentPage}
               onSetPage={onSetPage}
               totalCount={totalCount}
               itemCount={Array.isArray(items) && items.length}
            />
         </div>
      </>
   );
};
