import React, {useState} from "react";
import {Gallery} from "../Gallery/Gallery";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {requestConstants} from "../../constants/requestConstants";
import {updateRequest} from "../../actions/requestActions";
import {ButtonDelete} from "../Buttons/ButtonDelete";
import {dateFormat} from "../../utils/helpers";

export const RequestCard = ({props,deleteItem}) => {
   const [statusItem, setStatus] = useState(props.yesno);
   const update = (id,status) => {
      updateRequest(id,status);
      setStatus(status);
   }
   return (
      <div className="card">
         <div className={`box box-status-${statusItem}`}>
            <ButtonDelete w={22} h={22} onClick={() => deleteItem(props.id)} />
            <div className="box__head">
               <div className="box__head-top flex flex--ac">
                  <label htmlFor="check" className="visually-hidden">
                     Прочитано
                  </label>
                  <input
                     id="check"
                     className="check-custom"
                     name="check"
                     type="checkbox"
                     onChange={(e) => update(props.id, e.currentTarget.checked ? 1 : 0)}
                     defaultChecked={parseInt(props.yesno) === 1}
                  />
                  Предложение от { dateFormat(props.date_create, 'DD MMMM YYYY г. в HH:mm') }.
               </div>
               <div className="box__head-bottom">
                  <ul className="flex list-none flex--ac">
                     <li>
                        <b>Имя:</b> {props.name}
                     </li>
                     <li>
                        <b>Email:</b> {props.email}
                        <CopyToClipboard text={props.email}>
                           <button className="btn tiny">
                              Скопировать e-mail
                           </button>
                        </CopyToClipboard>
                     </li>
                  </ul>
               </div>
            </div>
            <p className="mb-none mt-none">
               {props.txt}
            </p>
            {
               (props.images && props.images.length > 0) &&
               <Gallery images={props.images.map((item) => {
                  return {
                     ...item,
                     src: requestConstants.MAIN_URL_IMAGE+item.image,
                     alt: item.id
                  }
               })} />
            }
         </div>
      </div>
   )
}
