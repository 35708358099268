import React, {useEffect, useState} from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Header } from "../../Header/Header";
import { TableList } from "../../TableList/TableList";
import FormSearch from "../../Form/FormSearch";
import { useDispatch, useSelector } from "react-redux";
import { deleteNews, receiveNews } from "../../../actions/newsAction";
import { newsSelector } from "../../../selectors/news-selectors";
import { Pagination } from "../../Pagination/Pagination";

export const NewsListContainer = () => {
   let { url } = useRouteMatch();
   let head = ["ID", "Заголовок", "Порядок", "Дата создания", "Ссылки"];
   const dispatch = useDispatch();
   const [filter, setFilter] = useState({ page: 1 });
   const { data } = useSelector(newsSelector);
   const { items, metaData } = data || {};
   const { totalCount, pageCount, currentPage } = metaData || {};

   useEffect(() => {
      dispatch(receiveNews(filter));
   }, [dispatch, JSON.stringify(filter)]);

   const onSetPage = (page) => {
      setFilter((state) => ({
         ...state,
         page,
      }));
   };

   if (data.hasError) {
      return <h2>{data.hasError}</h2>;
   }

   return (
      <>
         <Header title="Новости" />
         <div className="card card--md">
            <FormSearch />
         </div>

         <div className="card">
            <div className="card__head">
               <Link
                  to={`${url}/create`}
                  className="btn btn--green btn--no-border"
               >
                  Добавить
               </Link>
            </div>
            <TableList
               head={head}
               list={items || []}
               handleDelete={deleteNews}
               pageName="news"
            />
            <Pagination
               pageCount={pageCount}
               currentPage={currentPage}
               onSetPage={onSetPage}
               totalCount={totalCount}
               itemCount={Array.isArray(items) && items.length}
            />
         </div>
      </>
   );
};
