import React, {useEffect, useState} from "react";
import { Header } from "../../Header/Header";
import FormEditor from "../../Form/FormEditor";
import API from "../../../utils/API";
import {Redirect} from "react-router-dom";

export const TradeInContainer = () => {
   const [redirect, setRedirect] = useState(false);
   const [page, setPage] = useState({
      description: ''
   });
   const handleSubmit = (values) => {
      let data = {
         content: values.description,
         published: 1
      }
      API.put(`page/update/trade-in`,data)
         .then(({data}) => {
            if(data)
               setRedirect(true);
         });
   };
   useEffect(() => {
      API.get(`page/trade-in`)
         .then(({data}) => {
            setPage({
               ...data,
               description: data.content
            });
         });
   },[]);

   if(redirect)
      return <Redirect to="/pages" />;

   return (
      <>
         <Header title="Текст для “Трейд-ин”" />

         <div className="card">
            <FormEditor
               initialValues={
                  page
               }
               onSubmit={(values) => {
                  handleSubmit(values);
               }}
            />
         </div>
      </>
   );
};
