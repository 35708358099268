import React, {useEffect} from 'react';
import { Image} from "react-konva";
import useImage from "use-image";

export const BaseImage = ({photo, size, setSize, setScale, }) => {
   const [image] = useImage(photo);

   useEffect(() => {
      if (!image) {
         return;
      }
      const scale = Math.min(size.width / image.width, size.height / image.height);
      setScale(scale);

      const ratio = image.width / image.height;
      setSize({
         width: size.width,
         height: size.width / ratio
      });
   }, [image,  size.height, size.width, setScale, setSize]);


   return (
     <Image image={image} />
   )
};
