import React, {useEffect, useState} from "react";
import { Header } from "../Header/Header";
import { TableList } from "../TableList/TableList";
import {Pagination} from "../Pagination/Pagination";
import API from "../../utils/API";
import {dateFormat} from "../../utils/helpers";

export const ExcursionHouse = () => {

   const [list, setList] = useState({
      items: []
   });
   let head = ["id", "Имя, Тел", "ЖК", "Дом", "Дата", "Ссылки"];

   const getList = (page = 1) => {
      API.get(`requesthouse`, {params: {page}})
         .then(({data}) => {
            let dataList = data.items.map(item => ({
               id: item.id,
               name: item.name + ',' + item.phone,
               complex: item.complex,
               house: item.house,
               date: dateFormat(item.date_create),
               links: {
                  delete: true,
               },
            }));

            setList({
               meta: data._meta,
               items: dataList
            });
         });
   }

   const handleDelete = (e) => (dispatch) => {
      API.delete(`requesthouse/delete/${e}`)
         .then((r) => {
            if(r)
               getList();
         });
   }

   useEffect(() => {
      getList();
   },[]);

   return (
      <>
         <Header title="Запрос заявок на экскурсии" />
         <div className="card">
            <TableList handleDelete={handleDelete} head={head} list={list.items} />
            {
               list.meta &&
               <Pagination
                  onSetPage={getList}
                  pageCount={list.meta.pageCount}
                  currentPage={list.meta.currentPage}
                  itemCount={list.items.length}
                  totalCount={list.meta.totalCount}
               />
            }
         </div>
      </>
   );
};
