import React, { useCallback, useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { reduxForm } from "redux-form";
import { useDispatch, useSelector } from "react-redux";

import { Header } from "../../Header/Header";

import {
   receiveOfficeById,
   receiveGenPlanCommercialProperty,
   receiveUpdateOffice,
   receiveOfficesByCommrealty,
} from "../../../actions/commercialPropertyAction";
import { createMedia, deleteMedia } from "../../../actions/media";

import { Form } from "./OfficeForm";
import { isEmpty } from "../../../utils/utils";

const NAME_FORM = "update_office";

const CreateOfficeForm = reduxForm({
   form: NAME_FORM,
})(Form);

export const UpdateOffice = () => {
   const [mediaData, setMediaData] = useState();
   const [image, setImage] = useState(null);
   const match = useRouteMatch();
   const history = useHistory();
   const dispatch = useDispatch();
   const { id, office_id } = match.params;

   const currentOffice = useSelector(
      (state) => state.commercialProperty.currentOffice
   );
   const commercialProperty = useSelector(
      (state) => state.commercialProperty.currentCommercial
   );
   const offices = useSelector((state) => state.commercialProperty.offices);

   const onSubmit = async (values) => {
      try {
         const formData = new FormData();
         formData.append("plan", JSON.stringify({ points: values.points }));
         if (id) {
            formData.append("comrealty_id", id);
         }

         // if (image !== null) {
         //    formData.append("image", mediaData);
         // } else {
         //    formData.append("image", currentOffice.image);
         // }

         delete values.plan;
         delete values.image;
         delete values.points;
         for (let key in values) {
            formData.append(key, values[key]);
         }

         await dispatch(receiveUpdateOffice(office_id, formData));
         history.goBack();
      } catch (error) {
         console.error(error.message);
      }
   };

   const handleGetOffice = useCallback(async () => {
      try {
         await dispatch(receiveOfficeById(office_id));
      } catch (error) {
         console.error(error.message);
      }
   }, [dispatch, office_id]);

   const handleReceiveGenPlanCommercialProperty = useCallback(async () => {
      const imgUrl = await receiveGenPlanCommercialProperty(id);
      setImage(imgUrl);
   }, [id]);

   useEffect(() => {
      handleReceiveGenPlanCommercialProperty();
   }, [handleReceiveGenPlanCommercialProperty]);

   useEffect(() => {
      handleGetOffice();
   }, [handleGetOffice]);

   useEffect(() => {
      if (id && isEmpty(offices)) {
         dispatch(receiveOfficesByCommrealty(id));
      }
   }, [offices, dispatch, id]);

   return (
      <>
         <Header title="Редактировать офис" />
         <CreateOfficeForm
            data={{
               ...currentOffice,
               image: "http://dc.websalamat.ru/img/" + currentOffice.image,
            }}
            offices={offices}
            setMediaData={setMediaData}
            onSubmit={onSubmit}
            image={
               commercialProperty.imageplan
                  ? `http://dc.websalamat.ru/img/${commercialProperty.imageplan}`
                  : Image
            }
            nameForm={NAME_FORM}
            isUpdating
            urlBack={`/commercial-property/update/${id}`}
         />
      </>
   );
};
