import React from "react";
import { Field, reduxForm } from "redux-form";
import { FieldImage } from "../Fields/FieldImage";

const UploadPlan = ({ handleSubmit }) => {
   return (
      <form className="form" onSubmit={handleSubmit}>
         <div className="item">
            <Field name="photo" component={FieldImage} />
         </div>
      </form>
   );
};

export default reduxForm({
   form: "uploadPlan",
   onChange: (values, dispatch, props) => {
      props.submit();
   },
})(UploadPlan);
