import {useEffect, useState} from 'react';

export const useCheckSize = () => {
   let width = window.innerWidth;
   let height = window.innerHeight;
   const [size, setSize] = useState({width, height});

   useEffect(() => {
      function checkSize() {
         const container = document.querySelector(".frame-canvas");
         setSize({
            width: container.offsetWidth,
            height: size.height
         });
      }

      checkSize();
      window.addEventListener("resize", checkSize);
      return () => window.removeEventListener("resize", checkSize);
   }, [size.height]);


   return [size, setSize]
};
