export const pagesConstants = {
   FETCH_PAGES_SUCCESS: "FETCH_PAGES_SUCCESS",
   FETCH_PAGES_FAILURE: "FETCH_PAGES_FAILURE",

   DELETE_PAGE: "DELETE_PAGE",
   IS_FETCHING: "IS_FETCHING",
   IN_PROGRESS_PAGE: "IN_PROGRESS_PAGE",

   FETCH_PAGE_DETAILS_SUCCESS: "FETCH_PAGE_DETAILS_SUCCESS",
   FETCH_PAGE_DETAILS_FAILURE: "FETCH_PAGE_DETAILS_FAILURE",

   SET_PAGE_SUCCESS: "SET_PAGE_SUCCESS",
   SET_PAGE_FAILURE: "SET_PAGE_FAILURE",
};
