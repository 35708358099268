import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { pollApi } from "../../../actions/pollAction";
import { ButtonBack } from "../../Buttons/ButtonBack";
import { ButtonSave } from "../../Buttons/ButtonSave";
import { ButtonPublished } from "../../Buttons/ButtonPublised";
import { ButtonUnpublish } from "../../Buttons/ButtonUnpublish";
import { Answers } from "./Answers";

export const Form = ({ mode = "create", ...props }) => {
   const history = useHistory();

   const [question, setQuestion] = useState("");
   const [answers, setAnswer] = useState([]);
   const [published, setPublished] = useState("0");

   const getPoll = () => {
      pollApi.getPollView(props.id).then(({ name, answers, published }) => {
         setQuestion(name);
         setAnswer(answers);
         setPublished(published === null ? "0" : published);
      });
   };

   useEffect(() => {
      if (mode === "edit") {
         getPoll();
      }
   }, [mode, props.id]);

   const handleChangeName = (event) => {
      setQuestion(event.target.value);
   };

   const handleChangeAnswer = (answers) => {
      setAnswer(answers);
   };

   const handleDeleteAnswer = (id) => {
      pollApi.deleteAnswer(id).then((status) => status && getPoll());
   };

   const handleCancel = () => {
      history.goBack();
   };

   const handlePublished = () => {
      const revertPublished = published === "1" ? "0" : "1";

      pollApi
         .publicPoll(props.id, revertPublished)
         .then((status) => status && getPoll());
   };

   const handleSubmit = (event) => {
      event.preventDefault();

      const data = {
         name: question,
         answers: answers,
      };

      if (mode === "create") {
         pollApi.createPoll(data, history);
      } else {
         pollApi
            .updatePoll(props.id, question, answers)
            .then((status) => status && getPoll());
      }
   };

   return (
      <form className="card" onSubmit={handleSubmit}>
         <div className="form-group">
            <div className="form-group__label">Вопрос</div>
            <input
               type="text"
               className="control"
               value={question}
               onChange={handleChangeName}
            />
         </div>
         <div className="form-group">
            <div className="form-group__label">Ответы</div>
            <Answers
               answers={answers}
               mode={mode}
               onChangeAnswer={handleChangeAnswer}
               onDeleteAnswer={handleDeleteAnswer}
            />
         </div>
         <div className="form__foot button-group flex">
            <ButtonBack onClick={handleCancel} />
            <ButtonSave type="submit" />
            {mode === "edit" && (
               <>
                  {published === "0" ? (
                     <ButtonPublished type="button" onClick={handlePublished} />
                  ) : (
                     <ButtonUnpublish type="button" onClick={handlePublished} />
                  )}
               </>
            )}
         </div>
      </form>
   );
};
