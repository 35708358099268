import {complexConstants} from "../../constants/complexConstants";

const initialState = {
   data: [],
   hasError: false,
   complexDetail: false,
   house: false,
   houseList: [],
}

export const complexReducer = (state = initialState, action) => {
   switch (action.type) {
      case complexConstants.SET_COMPLEX:
         return {
            ...state,
            data: action.payload.data,
         }
      case complexConstants.SET_ERROR:
         return {
            ...state,
            hasError: true,
         }
      case complexConstants.SET_COMPLEX_DETAIL:
         console.log("test::::", action.payload.data);
         return {
            ...state,
            complexDetail: action.payload.data,
         }
      case complexConstants.SET_HOUSE:
         return {
            ...state,
            house: action.payload.data,
         }
      case complexConstants.SET_HOUSE_LIST:
         return {
            ...state,
            houseList: action.payload.data,
         }
      case complexConstants.DELETE_COMPLEX_HOME:
         const homeId = action.payload;
         return {
            ...state,
            complexDetail: {
               ...state.complexDetail,
               houses: [
                  ...state.complexDetail.houses.filter(item => item.id !== homeId)
               ]
            }
         }
      case complexConstants.DELETE_ENTRANCE:
         const id = action.payload;
         return {
            ...state,
            house: {
               ...state.house,
               entrances: [
                  ...state.house.entrances.filter((item,index) => index !== id)
               ]
            }
         }
      default:
         return state;
   }
}
