import { parkingConstants } from "../../constants/parkingConstants";

const initialState = {
   data: [],
   parking: null,
   levels: [],
   level: null,
   places: [],
   place: null,
};

export const parkingReducer = (state = initialState, action) => {
   switch (action.type) {
      case parkingConstants.SET_PARKING_LIST: {
         const { data } = action.payload;
         return {
            ...state,
            data,
            parking: null,
            levels: [],
            level: null,
            places: [],
            place: null,
         }
      }
      case parkingConstants.GET_PARKING_BY_ID: {
         const { data } = action.payload;
         return {
            ...state,
            parking: data,
         }
      }
      case parkingConstants.DELETE_PARKING: {
         const { data } = action.payload;
         return {
            ...state,
            data: [
               ...state.data.filter(item => item.id !== data),
            ]
         }
      }
      case parkingConstants.SET_PARKING_LEVELS_LIST: {
         const { data } = action.payload;
         return {
            ...state,
            levels: [...state.levels.filter(level => !level.parking_id), ...data],
         }
      }
      case parkingConstants.CREATE_PARKING_LEVEL: {
         const { data } = action.payload;
         return {
            ...state,
            levels: [
               ...state.levels,
               { ...data }
            ]
         }
      }
      case parkingConstants.GET_PARKING_LEVEL_BY_ID: {
         const { data } = action.payload;
         return {
            ...state,
            level: data,
         }
      }
      case parkingConstants.DELETE_PARKING_LEVEL: {
         const { data } = action.payload;
         return {
            ...state,
            levels: [
               ...state.levels.filter(item => item.id !== data),
            ]
         }
      }
      case parkingConstants.UPDATE_PARKING_LEVEL: {
         const { data } = action.payload;
         return {
            ...state,
            levels: [
               ...state.levels.filter(item => +item.id !== +data.id),
               { ...data, parking_id: true }
            ]
         }
      }
      case parkingConstants.SET_PARKING_PLACES_LIST: {
         const { data } = action.payload;
         return {
            ...state,
            places: data,
         }
      }
      case parkingConstants.GET_PARKING_PLACE_BY_ID: {
         const { data } = action.payload;
         return {
            ...state,
            place: data,
         }
      }
      case parkingConstants.DELETE_PARKING_PLACE: {
         const { data } = action.payload;
         return {
            ...state,
            places: [
               ...state.places.filter(item => item.id !== data),
            ]
         }
      }
      case parkingConstants.RESET_PARKING_LEVELS: {
         return {
            ...state,
            levels: [...state.levels.filter(level => !level.parking_id || level.parking_id === true)]
         }
      }
      case parkingConstants.RESET_PARKING_LEVEL: {
         return {
            ...state,
            level: null
         }
      }
      case parkingConstants.RESET_PARKING_PLACES: {
         return {
            ...state,
            places: []
         }
      }
      case parkingConstants.RESET_PARKING_PLACE: {
         return {
            ...state,
            place: null
         }
      }
      default:
         return state;
   }
};
