import React from "react";
import { Field, reduxForm } from "redux-form";
import { RenderInput } from "../../Fields/RenderInput";
import { LayoutFormBasis } from "../../Fields/LayoutForm";
import { WYSIWYGEditor } from "../../Fields/WYSIWYGEditor";
import { ButtonBack } from "../../Buttons/ButtonBack";
import { ButtonSave } from "../../Buttons/ButtonSave";
import { RenderSelect } from "../../Fields/RenderSelect";
import {publishedOptions} from "../../Fields/options";
import { validate } from "../../Fields/validate";

const Form = ({ handleSubmit }) => {
   return (
      <form className="form" onSubmit={(e) => handleSubmit(e)}>
         <LayoutFormBasis id="name" title="Название страницы:">
            <div className="form__item form__item--all">
               <Field
                  id="name"
                  name="name"
                  className="control"
                  component={RenderInput}
                  placeholder="Название страницы"
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis label={false} id="group-description" title="Описание">
            <div className="form__item form__item--all">
               <Field component={WYSIWYGEditor} name="content" type="text" />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis label={false} id="group-hide" title="Видимость:">
            <div className="form__item form__item--min">
               <Field
                  id="published"
                  name="published"
                  placehoder="Выберите"
                  options={publishedOptions}
                  component={RenderSelect}
               />
            </div>
         </LayoutFormBasis>
         <div className="form__foot button-group flex">
            <ButtonBack />
            <ButtonSave />
         </div>
      </form>
   );
};

export default reduxForm({
   form: "section-form",
   enableReinitialize: true,
   validate
})(Form);
