import React, { useEffect, useState, useCallback } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import IconHome from "../../../../assets/img/icon/home.png";
import Image from "../../../../assets/img/Office.jpg";
import UploadPlan from "../../../UploadPlan/UploadPlan";
import { CanvasOfficePreview } from "../../CanvasOffice/CanvasOfficePreview";
import { createMedia, deleteMedia } from "../../../../actions/media";
import {
   receiveGenPlanCommercialProperty,
   receiveMediaById,
   uploadGenPlanCommercialProperty,
   uploadImagePlanCommercialProperty,
   resetCommercialProperty,
   receiveOfficesByCommrealty,
   receiveCurrentCommercialProperty,
} from "../../../../actions/commercialPropertyAction";
import { RightListOffices } from "../../RightListOffices/RightListOffices";

import { isEmpty } from "../../../../utils/utils";

export const OfficesListContainer = () => {
   const match = useRouteMatch();
   const [image, setImage] = useState(Image);
   const dispatch = useDispatch();

   const { url } = match;
   const { id } = match.params;

   const offices = useSelector((state) => state.commercialProperty.offices);
   const commercialProperty = useSelector(
      (state) => state.commercialProperty.currentCommercial
   );

   const handleSubmit = useCallback(
      async (value) => {
         if (value.photo[0]) {
            const commrealtyFormData = new FormData();
            commrealtyFormData.append("imageplan", value.photo[0]);

            await uploadImagePlanCommercialProperty(commrealtyFormData, id);
            await dispatch(receiveCurrentCommercialProperty(id));
         }
      },
      [id, dispatch]
   );

   const handleRecieveGenPlan = useCallback(async () => {
      const imgURL = await receiveGenPlanCommercialProperty(id);
      setImage(imgURL);
   }, [id]);

   const handleRecieveOffices = useCallback(async () => {
      try {
         await dispatch(receiveOfficesByCommrealty(id));
      } catch (error) {
         console.error(error.message);
      }
   }, [id, dispatch]);

   useEffect(() => {
      handleRecieveGenPlan();
   }, [handleRecieveGenPlan]);

   useEffect(() => {
      handleRecieveOffices();
   }, [handleRecieveOffices]);

   useEffect(() => {
      if (id) {
         dispatch(receiveCurrentCommercialProperty(id));
      } else {
         dispatch(resetCommercialProperty());
      }
   }, [dispatch, id]);

   return (
      <>
         <div className="ling-group ling-group--mb">
            <Link
               to={`${url}/office/create`}
               className="flex flex--ac btn btn--mw btn--green item-indent"
            >
               <img
                  src={IconHome}
                  alt=""
                  aria-hidden="true"
                  className="item-indent"
               />
               Добавить офис
            </Link>

            <UploadPlan onSubmit={handleSubmit} />
         </div>
         <div className="flex">
            <CanvasOfficePreview
               image={
                  commercialProperty.imageplan
                     ? `http://dc.websalamat.ru/img/${commercialProperty.imageplan}`
                     : Image
               }
               offices={offices}
               customUrl={`${url}/update`}
            />
            <RightListOffices url={url} />
         </div>
      </>
   );
};
