import React, { useEffect, useState } from "react";
import { Header } from "../../Header/Header";
import ParkingLevelForm from "./ParkingLevelForm";
import { useDispatch, useSelector } from "react-redux";
import {
   createParkingLevelObject,
   getParkingLevelById,
   receiveParkingPlaces,
   resetParkingLevel,
   resetParkingPlaces,
   updateParkingLevelObject,
} from "../../../actions/parkingActions";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
   getParkingLevelSelect,
   getParkingPlacesListSelect,
} from "../../../selectors/parking-selectors";

export const ParkingLevelCreateOrUpdate = () => {
   const [mediaData, setMediaData] = useState(undefined);
   const [image, setImage] = useState(null);
   let {
      params: { id },
   } = useRouteMatch();

   const isUpdating = !!id;

   let history = useHistory();
   const dispatch = useDispatch();

   const currentParkingLevel = useSelector(getParkingLevelSelect);
   const placesList = useSelector(getParkingPlacesListSelect);
   const placesTable = placesList.map((place, i) => ({
      id: place.id,
      name: place.name,
   }));
   let placesPolygons = currentParkingLevel?.parkingplaces;

   if (placesPolygons?.length) {
      placesPolygons = placesPolygons.map((place) => {
         return {
            id: place.id,
            plan: JSON.stringify({ points: JSON.parse(place.plan) }),
         };
      });
   }

   const updateParkingPlacesList = () => {
      if (isUpdating) {
         dispatch(receiveParkingPlaces(currentParkingLevel?.id));
      }
   };

   useEffect(() => {
      if (isUpdating && currentParkingLevel?.image) {
         setImage("http://dc.websalamat.ru/img/" + currentParkingLevel.image);
         updateParkingPlacesList();
      }
   }, [currentParkingLevel]);

   useEffect(() => {
      if (mediaData) {
         setImage(URL.createObjectURL(mediaData));
      } else {
         setImage(null);
         if (currentParkingLevel) {
            currentParkingLevel.image = null;
         }
      }
   }, [mediaData]);

   const updateParkingLevel = () => {
      if (isUpdating) {
         dispatch(getParkingLevelById(id));
      } else {
         dispatch(resetParkingLevel());
         dispatch(resetParkingPlaces());
      }
   };

   useEffect(() => {
      updateParkingLevel();
   }, [dispatch]);

   const handleSubmit = (values) => {
      const formData = new FormData();
      let imageFormData = null;
      formData.append("name", values.name);
      if (!isUpdating) {
         formData.append("image", mediaData);
      }
      if (isUpdating) {
         formData.append("id", currentParkingLevel?.id);
         if (currentParkingLevel?.parking_id) {
            formData.append("parking_id", currentParkingLevel.parking_id);
         }
      }
      if (mediaData) {
         imageFormData = new FormData();
         imageFormData.append("image", mediaData);
      }

      const action = isUpdating
         ? updateParkingLevelObject
         : createParkingLevelObject;

      dispatch(
         action(
            formData,
            () => history.goBack(),
            values.name,
            id,
            imageFormData
         )
      );
   };
   return (
      <>
         <Header
            title={
               isUpdating
                  ? "Редактировать уровень паркинга"
                  : "Добавить уровень паркинга"
            }
         />

         <div className="card">
            <ParkingLevelForm
               setMediaData={setMediaData}
               image={image}
               onSubmit={handleSubmit}
               initialValues={isUpdating ? currentParkingLevel : null}
               isUpdating={isUpdating}
               placesList={placesTable}
               placesInfo={{ houses: placesPolygons }}
            />
         </div>
      </>
   );
};
