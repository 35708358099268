import React, { useEffect, useState, useRef, useCallback } from "react";
import { Header } from "../../../Header/Header";
import Form from "./Form";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
   getParkingLevelSelect,
   getParkingPlaceSelect,
} from "../../../../selectors/parking-selectors";
import {
   getParkingLevelById,
   getParkingPlaceById,
   resetParkingPlace,
} from "../../../../actions/parkingActions";
import {
   createParkingPlaceObject,
   updateParkingPlaceObject,
} from "../../../../actions/parkingActions";

export const CreateOrUpdatePlace = () => {
   let {
      params: { id, placeId },
   } = useRouteMatch();
   const [image, setImage] = useState(null);
   const [defaultPoints, setDefaultPoints] = useState(null);
   const dispatch = useDispatch();
   let history = useHistory();

   const points = useRef();
   const setNewPoint = (newPoints) => {
      points.current = newPoints;
   };

   const isUpdating = !!placeId;

   const currentParkingLevel = useSelector(getParkingLevelSelect);
   const currentParkingPlace = useSelector(getParkingPlaceSelect);

   useEffect(() => {
      if (currentParkingLevel?.image) {
         setImage("http://dc.websalamat.ru/img/" + currentParkingLevel.image);
      }
   }, [currentParkingLevel]);

   useEffect(() => {
      if (currentParkingPlace?.plan) {
         setDefaultPoints(JSON.parse(currentParkingPlace.plan));
      }
   }, [currentParkingPlace]);

   const updateParkingLevel = useCallback(() => {
      if (!currentParkingLevel) {
         dispatch(getParkingLevelById(id));
      }
   }, [currentParkingLevel, dispatch, id]);

   const updateParkingPlace = useCallback(() => {
      if (isUpdating) {
         dispatch(getParkingPlaceById(placeId));
      } else {
         dispatch(resetParkingPlace());
      }
   }, [dispatch, isUpdating, placeId]);

   useEffect(() => {
      updateParkingPlace();
      updateParkingLevel();
   }, [dispatch, updateParkingLevel, updateParkingPlace]);

   const handleSubmit = (values) => {
      const dataForCreate = {
         ...values,
         name: values?.name,
         id: currentParkingPlace?.id,
         plan: JSON.stringify(points.current),
         parkinglvl_id: currentParkingLevel.id,
      };

      const action = isUpdating
         ? updateParkingPlaceObject
         : createParkingPlaceObject;

      dispatch(
         action(dataForCreate, () => history.goBack(), currentParkingPlace?.id)
      );
   };

   return (
      <>
         <Header
            title={
               isUpdating
                  ? "Редактировать парковочное место"
                  : "Добавить парковочное место"
            }
         />

         <div className="card">
            <Form
               setNewPoint={setNewPoint}
               image={image}
               onSubmit={handleSubmit}
               initialValues={currentParkingPlace}
               defaultPoints={defaultPoints}
            />
         </div>
      </>
   );
};
