export const complexConstants = {
   SET_COMPLEX: 'SET_COMPLEX',
   SET_ERROR: 'SET_ERROR',
   DELETE_COMPLEX_HOME: 'DELETE_HOME',
   SET_COMPLEX_DETAIL: 'SET_COMPLEX_DETAIL',
   SET_HOUSE: 'SET_HOUSE',
   UPDATE_ENTRANCES: 'UPDATE_ENTRANCES',
   DELETE_ENTRANCE: "DELETE_ENTRANCE",
   SET_HOUSE_LIST: "SET_HOUSE_LIST",
};
