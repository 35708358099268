import API from "../utils/API";
import { workerConstants } from "../constants/workerConstants";

export const saveWorker = (data, id) => (dispatch) => {
   API.post(`worker/create`,data)
      .then(({data}) => {
         if(data)
            dispatch(getAllWorkers(id));
   });
}
/* удаление worker с redux */
export const deleteWorkerRedux = (id) => ({type: workerConstants.DELETE_WORKER, payload: {id}});
export const setWorkersRedux = (data) => ({type: workerConstants.FETCH_WORKER_SUCCESS, payload: {data}});

export const updateWorker = (data, id = false) => (dispatch) => {
   API.put(`worker/update/${id}`,data);
   if(data.get('photo'))
   {
      let formData = new FormData();
      formData.append('photo', data.get('photo'));
      API.post(`worker/updateimage/${id}`,formData);
   }
}

/* получение worker */
export function receiveWorkerDetails(id, initialize) {
   return (dispatch) => {
      API.get(`worker/view/${id}`)
         .then(({data}) => {
            initialize({
               ...data,
               photo: data.photo ? `http://dc.websalamat.ru/img/${data.photo}` : null,
            });
         })
         .catch((err) => {
            console.log(err.message);
         });
   };
}

export const deleteWorker = (id) => (dispatch) => {
   API.delete(`/worker/delete/${id}`)
      .then(({data}) => {
         if(data)
         {
            dispatch(deleteWorkerRedux(id));
         }
      });
}

export const getAllWorkers = (id) => (dispatch) => {
   API.get(`worker/${id}`)
      .then(({data}) => {
            dispatch(setWorkersRedux(data.items));
      });
}
