import React, {useEffect, useState} from "react";
import { Field, reduxForm } from "redux-form";
import { validate } from "../Fields/validate";
import { RenderInput } from "../Fields/RenderInput";
import { LayoutFormBasis } from "../Fields/LayoutForm";
import {useDispatch, useSelector} from "react-redux";
import {receiveFloor} from "../../actions/floorActions";
import {useRouteMatch} from "react-router-dom";
import {RenderRadioImage} from "../Fields/RenderRadioImage";

const images = require.context( "../../assets/img/compas",false);
const paths = images.keys();
const radioImages = paths.map(path => images(path));

paths.forEach(function(item,key) {
   paths[key] = item.substr(2);
});


const DescriptionForm = ({ initialize, handleSubmit }) => {
   const dispatch = useDispatch();
   let match  = useRouteMatch();
   const { floor_id } = match.params;
   const floor = useSelector(state => state.floor.data);

   useEffect(() => {
      if(floor_id)
         dispatch(receiveFloor(floor_id,initialize));
   },[floor_id]);

   return (
      <form className="form form--description" onSubmit={handleSubmit}>
         <LayoutFormBasis title="Id этажа" id="floor">
            <div className="form__item">
               <label>
                  {floor.id}
               </label>
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="Этажи" id="floor">
            <div className="form__item">
               <label>
                  <Field
                     className="control"
                     name="name"
                     placeholder="1-13"
                     component={RenderInput}
                  />
               </label>
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="Улицы вокруг" id="street">
            <div className="flex-wrap">
               <div className="form__group-inline form__group-bottom flex">
                  <div className="form__item form__item-mr">
                     <label>
                        <Field
                           className="control bg-left"
                           name="street1"
                           placeholder="Улица слева"
                           component={RenderInput}
                        />
                     </label>
                  </div>
                  <div className="form__item form__item-mr">
                     <label>
                        <Field
                           className="control bg-right"
                           name="street3"
                           placeholder="Улица справа"
                           component={RenderInput}
                        />
                     </label>
                  </div>
               </div>
               <div className="form__group-inline form__group-bottom flex">
                  <div className="form__item form__item-mr">
                     <label>
                        <Field
                           className="control bg-top"
                           name="street2"
                           placeholder="Улица сверху"
                           component={RenderInput}
                        />
                        </label>
                     </div>
                     <div className="form__item form__item-mr">
                        <label>
                           <Field
                              className="control bg-bottom"
                              name="street4"
                              placeholder="Улица снизу"
                              component={RenderInput}
                           />
                        </label>
                     </div>
                  </div>
               </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="Компас" id="compass">
            {
               paths.length > 0 &&
               paths.map((item,index) =>
                     <div className="form__item" key={index}>
                        <Field
                           name="compas"
                           image={radioImages[index]}
                           id={`radio_${index}`}
                           component={RenderRadioImage}
                           type="radio"
                           value={item}
                        />
                     </div>
               )
            }
         </LayoutFormBasis>
         <br/>
      </form>
   );
};

export default reduxForm({
   form: "floor-description",
   validate,
})(DescriptionForm);
