import API from "../utils/API";
import { complexConstants } from "../constants/complexConstants";
import { history } from "../utils/history";
import store from "../store/configureStore";
import moment from "moment-timezone";
import { addMedia } from "../utils/FetchApi";

export const addComplex = (formData) => (dispatch) => {
   API.post("/complex/create", formData).then(() => {
      history.push("/residential-complex");
      dispatch(receiveComplex());
   });
};

export const setComplex = (data) => ({
   type: complexConstants.SET_COMPLEX,
   payload: { data },
});
export const setHouseList = (data) => ({
   type: complexConstants.SET_HOUSE_LIST,
   payload: { data },
});

export const handleError = (error) => ({
   type: complexConstants.SET_ERROR,
   payload: error,
});

export const receiveComplex = () => (dispatch) => {
   const items = [];
   API.get("complex")
      .then(async ({ data }) => {
         for (let i = 0; i < data.items.length; i++) {
            const val = data.items[i];
            await API.get(`city/${val["city_id"]}`).then(({ data }) => {
               items.push({
                  id: val.id,
                  name: val.name,
                  city: data.name,
                  links: {
                     delete: true,
                     hide: true,
                     edit: `update/${val.id}`,
                  },
               });
            });
         }
      })
      .then(() => {
         dispatch(setComplex(items));
      })
      .catch((error) => dispatch(handleError(error)));
};

export const deleteComplex = (id) => (dispatch) => {
   API.delete(`complex/delete/${id}`).then(() => {
      dispatch(receiveComplex());
   });
};

export const updateComplex = (id, data) => (dispatch) => {
   const formData = new FormData();

   formData.append("image", data.get("image"));
   formData.append("genplan", data.get("genplan"));

   data.delete("image");
   data.delete("genplan");

   API.post(`complex/updateimage/${id}`, formData).then((err) => {
      API.put(`complex/update/${id}`, data).then(async () => {
         /*Обновим дома из массива Redux в комплексе*/
         //Получаем текущий список домов и инфорции из базы
         await new Promise((res) => setTimeout(res, 500));
         API.get(`complex/view/${id}`).then(async (res) => {
            const houses = res.data.houses;
            const storeData = store.getState();
            //получим срез домов которые надо удалить
            let difference = await houses.filter(
               (x) =>
                  !storeData.complex.complexDetail.houses.some(
                     (y) => x.id === y.id
                  )
            );
            if (difference)
               await difference.map(async (item) => {
                  API.delete(`house/delete/${item.id}`);
                  await new Promise((res) => setTimeout(res, 500));
               });

            //Добавим / обновим дома
            if (storeData.complex.complexDetail.houses)
               await storeData.complex.complexDetail.houses.map(
                  async (item) => {
                     if (item.id) {
                        API.put(`house/update/${item.id}`, item).then((res) =>
                           console.log("Update: ", res)
                        );
                     } else {
                        API.post(`house/create`, item).then((res) =>
                           console.log("Post: ", res)
                        );
                     }
                     await new Promise((res) => setTimeout(res, 500));
                  }
               );
         });
         /**/
         history.push("/residential-complex");
         dispatch(receiveComplex());
      });
   });
};

export const receiveComplexById = (id, initialize) => (dispatch) => {
   API.get(`complex/view/${id}`).then(async ({ data }) => {
      let social = await API.get(`social/complex/${id}`)
         .then(({ data }) => data.items)
         .catch((err) => console.log(err));

      API.get(`city/${data["city_id"]}`).then((city) => {
         const currentComplex = {
            ...data,
            id: data.id,
            social: social,
            name: data.name,
            city_id: { label: city.data.name, value: city.data.id },
            area: { label: data.area, value: data.area },
            order: data.order,
            photo: `http://dc.websalamat.ru/img/` + data.image,
            photoGenPlan: `http://dc.websalamat.ru/img/` + data.genplan,
         };
         //Добавим информацию о комплексе в Redux
         dispatch(setComplexDetail(currentComplex));
         initialize({ ...currentComplex });
      });
   });
};

export const receiveHouse = () => (dispatch) => {
   API.get("house", {params: {'per-page': 1000}})
      .then((data) => {
         const { data: itemData } = data;
         dispatch(setHouseList(itemData.items));
      })
      .catch((error) => {
         console.warn(error);
      });
};

export const loadFinishingById = (id, initialize) => async (dispatch) => {
   const finishing = await API.get(`finishing/house/${id}`)
      .then((res) => res.data.items[0])
      .catch((err) => {
         console.log(err);
         return false;
      });
   initialize(finishing || "");
   return finishing;
};

export const loadHomeById = (id, initialize) => (dispatch) => {
   const storeData = store.getState();
   /*if (storeData.complex.house) initialize(storeData.complex.house);
   else*/
      API.get(`house/view/${id}`).then(async ({ data }) => {
         await new Promise((res) => setTimeout(res, 500));
         const entrances = await API.get(`entrance/house/${id}`)
            .then((res) => res.data)
            .catch((err) => {
               console.log(err);
               return false;
            });

         const finishing = await API.get(`finishing/house/${id}`)

         let updateData = Object({});

         for (const dataKey in data) {
            const valueUpdate = parseInt(data[dataKey]);
            if (valueUpdate || isNaN(valueUpdate)) {
               updateData[dataKey] = data[dataKey];
            }
         }

         let home = {
            ...updateData,
            finish: finishing.data.items[0].text,
            finishInfo:  finishing.data ? finishing.data.items[0] : false,
            virtualtour: data.virtualtour && JSON.parse(data.virtualtour),
            entrances: entrances && entrances.items || [],
            imageplan: data.imageplan ? `http://dc.websalamat.ru/img/` + data.imageplan : null,
            imagesGallery:
               data.images.length > 0 &&
               data.images.filter((image) => image.type === "gallery"),
            imagesViews:
               data.images.length > 0 &&
               data.images.filter((image) => image.type === "view"),
            imagesDesign:
               data.images.length > 0 &&
               data.images.filter((image) => image.type === "design_house"),
            counter_date_end: moment
               .unix(data.counter_date_end)
               .format("YYYY-MM-DD"),
            counter_date_begin: moment
               .unix(data.counter_date_begin)
               .format("YYYY-MM-DD"),
         };
         initialize(home);
         dispatch(setHouse(home));
      });
};

export const updateHome = (id, data, gallery, views) => async (dispatch) => {
   let result_form = new Object({});
   const storeData = store.getState();

   data.append(
      "counter_date_end",
      moment(new Date(data.get("counter_date_end"))).unix()
   );
   data.append(
      "counter_date_begin",
      moment(new Date(data.get("counter_date_begin"))).unix()
   );

   await API.put(`house/update/${id}`, data).then((r) =>
      console.log("update home result: ", r)
   );


   if(data.get("imageplan")){
      await API.post(`house/updateimage/${id}`, data).then((res) =>
         console.log("Post: ", res)
      );
   }


      let finish = {
        published: 1,
        text:  data.get('finish')
      };

   if(storeData.complex.house.finishInfo)
   {
      dispatch(updateFinishing(id,finish,{id: storeData.complex.house.finishInfo.id}));
   }
   else {
      dispatch(updateFinishing(id,finish));
   }



   await new Promise((res) => setTimeout(res, 500));
   //удалим дома которых нет из базы
   const entrances = await API.get(`entrance/house/${id}`)
      .then((r) => r.data)
      .catch((err) => {
         console.log("err: ", err);
      });

   if (entrances && storeData.complex.house.entrances) {
      let difference = await entrances.items.filter(
         (x) => !storeData.complex.house.entrances.some((y) => x.id === y.id)
      );
      if (difference)
         await difference.map(async (item) => {
            await API.delete(`entrance/delete/${item.id}`);
            await new Promise((res) => setTimeout(res, 500));
         });
   }

   //добавим подхезд
   if (storeData.complex.house.entrances)
      storeData.complex.house.entrances
         .filter((x) => x.new)
         .map(async (item) => {
            API.post(`entrance/create`, item).then((res) =>
               console.log("Post: ", res)
            );
            await new Promise((res) => setTimeout(res, 500));
         });

   //обновим существущие подъезды
   if (storeData.complex.house.entrances)
      storeData.complex.house.entrances
         .filter((x) => !x.new)
         .map(async (item) => {
            API.put(`entrance/update/${item.id}`, item).then((res) =>
               console.log("Update: ", res)
            );
            await new Promise((res) => setTimeout(res, 500));
         });

   //добавим галлерею и выходы из окна
   if (gallery.length > 0) {
      gallery.map(async (image, i) => {
         if (!image.id) {
            const result = await addMedia({
               parent: "house",
               id: id,
               type: "gallery",
               published: "1",
               image: image,
               order: i
            });

            let new_image_id = result.data.id ?? null;
            if (new_image_id) {
               result_form.new_gallery_images = {
                  [i]: new_image_id,
               }
            }

            console.log("add media: ", result);
         }
      });
      await new Promise((res) => setTimeout(res, 500));
   }
   if (views.length > 0) {
      views.map(async (image, i) => {
         if (!image.id) {
            const result = await addMedia({
               parent: "house",
               id: id,
               type: "view",
               published: "1",
               image: image,
               order: i
            });

            let new_image_id = result.data.id ?? null;
            if (new_image_id) {
               result_form.new_views_images = {
                  [i]: new_image_id,
               }
            }

            console.log("add media: ", result);
         }
      });
      await new Promise((res) => setTimeout(res, 500));
   }

   //удалим лишние фото
   const deletedIds = data.getAll('deletedIds[]');
   if (deletedIds) {
      for (const deleteId of deletedIds) {
         API.delete(`media/delete/${deleteId}`).then(async () => await new Promise((res) => setTimeout(res, 500)))
      }
   }

   return result_form;
};

//update designed
export const updateDesign = (homeId, data, form) => async (dispatch) => {
   const storeData = store.getState();
   let result_form = new Object({});

   if (data.length > 0) {
      data.map(async (image, i) => {
         if (!image.id) {
            const result = await addMedia({
               parent: "house",
               id: homeId,
               type: "design_house",
               published: !!form.published  ? "1" : "0",
               image: image,
               order: i
            });

            let new_image_id = result.data.id ?? null;
            if (new_image_id) {
               result_form.new_images = {
                  [i]: new_image_id,
               }
            }

            console.log("add design: ", result);
         }
      });
      await new Promise((res) => setTimeout(res, 500));
   }

   if (form) {
      const formData = new FormData();
      formData.append("published", !!form.published ? "1" : "0");

      storeData.complex.house.images
         .filter((image) => image.type === "design_house")
         .map(async (image) => {
            if (image.id) {
               await API.put(`media/update/${image.id}`, formData).then((r) =>
                  console.log("update image design: ", r)
               );
               await new Promise((res) => setTimeout(res, 500));
            }
         });
   }

   //удалим лишние фото
   if (form.deletedIds.length) {
      for (const deleteId of form.deletedIds) {
         API.delete(`media/delete/${deleteId}`).then(async () => await new Promise((res) => setTimeout(res, 500)))
      }
   }

   return result_form;
};

//обновим отделку
export const updateFinishing = (homeId, data, update) => async (dispatch) => {
   const formData = new FormData();
   formData.append("house_id", homeId);
   formData.append("published", data.published ? "1" : "0");
   formData.append("text", data.text);

   if (update)
      await API.put(`finishing/update/${update.id}`, formData).then((r) =>
         console.log("update finishing result: ", r)
      );
   else
      await API.post(`finishing/create`, formData).then((r) =>
         console.log("Add finishing result: ", r)
      );
};

export const saveSocial = (data) => (dispatch) => {
   if (data.social)
      API.post(`social/create`, data.social).then(async (r) => {
         let social = await API.get(`social/complex/${data.complex.id}`)
            .then(({ data }) => data.items)
            .catch((err) => console.log(err));
         dispatch(
            setComplexDetail({
               ...data.complex,
               social: social,
            })
         );
      });
};

export const deleteSocial = (id) => (dispatch) => {
   if (id)
      API.delete(`social/delete/${id}`).then(() => {
         const storeData = store.getState();
         dispatch(
            setComplexDetail({
               ...storeData.complex.complexDetail,
               social: storeData.complex.complexDetail.social
                  ? storeData.complex.complexDetail.social.filter(
                       (item) => item.id !== id
                    )
                  : false,
            })
         );
      });
};

export const updateSocial = (data, id) => (dispatch) => {
   if (data)
      API.put(`social/update/${id}`, data).then(async () => {
         let social = await API.get(`social/complex/${data.get("complex_id")}`)
            .then(({ data }) => data.items)
            .catch((err) => console.log(err));
         const storeData = store.getState();
         dispatch(
            setComplexDetail({
               ...storeData.complex.complexDetail,
               social: social,
            })
         );
      });
};

//добавим детально комплекс в Redux
export const setComplexDetail = (data) => ({
   type: complexConstants.SET_COMPLEX_DETAIL,
   payload: { data },
});

//добавим дом в Redux
export const setHouse = (data) => ({
   type: complexConstants.SET_HOUSE,
   payload: { data },
});

//удалим дом в Redux из комплекса
export const deleteComplexHome = (homeId) => ({
   type: complexConstants.DELETE_COMPLEX_HOME,
   payload: homeId,
});

export const deleteEntrance = (id) => ({
   type: complexConstants.DELETE_ENTRANCE,
   payload: id,
});
