import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Header } from "../Header/Header";
import { TableList } from "../TableList/TableList";
import { pollApi } from "../../actions/pollAction";

export const Poll = () => {
   const [poll, setPoll] = useState([]);
   const titlesTable = [
      "ID",
      "Имя",
      "Опубликован",
      "Кол-во вариантов ответов",
      "",
   ];

   const getPoll = () => {
      pollApi.getPoll().then((res) => {
         const polls = res.map((item) => ({
            ...item,
            published:
               item.published !== null && item.published === 1
                  ? "Опубликован"
                  : "-",
            answers: item.answers.length,
            links: {
               delete: true,
               edit: true,
            },
         }));

         setPoll(polls);
      });
   };

   useEffect(() => {
      getPoll();
   }, []);

   const handleDelete = (id) => {
      pollApi.deletePoll(id).then((isSave) => isSave && getPoll());
   };

   return (
      <>
         <Header title="Опросник" />
         <div className="card">
            <div className="card__head">
               <Link
                  to="/pages/poll/create"
                  className="btn btn--green btn--no-border"
               >
                  Добавить
               </Link>
            </div>
            <TableList
               head={titlesTable}
               customHandleDelete={handleDelete}
               list={poll}
               uncialURL={"/pages/poll/update"}
            />
         </div>
      </>
   );
};
