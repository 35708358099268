import React, {useEffect, useState} from "react";
import { Field, reduxForm, submit } from "redux-form";
import { ButtonBack } from "../../Buttons/ButtonBack";
import { ButtonPublished } from "../../Buttons/ButtonPublised";
import { ButtonUnpublish } from "../../Buttons/ButtonUnpublish";
import { WYSIWYGEditor } from "../../Fields/WYSIWYGEditor";
import { RenderSelect } from "../../Fields/RenderSelect";
import { Gallery } from "../../Gallery/Gallery";
import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from "react-router-dom";
import {
   receiveDetail,
   receiveThemes,
   setDetail,
   updateQuestion
} from "../../../actions/questionsActions";
import {dateFormat} from "../../../utils/helpers";
import { useHistory } from "react-router-dom";

const Form = ({ handleSubmit, initialize }) => {

   const [select, setSelect] = useState([]);
   const dispatch = useDispatch();
   const { params: { id } } = useRouteMatch();
   const { themes, questionDetail } = useSelector(state => state.review);
   let history = useHistory();

   const unPublished = (e) => {
      dispatch(updateQuestion(id,{id: id,published: "0"}));
      history.goBack();
   }

   const handleDeleteImage = e => {
      let images = questionDetail.photos.filter(item => item.id !== e.id);
      dispatch(setDetail({...questionDetail, photos: images}));
   }

   useEffect(() => {
      if(id)
         dispatch(receiveDetail(id, initialize));
   },[]);

   useEffect(() => {
      if(themes)
         setSelect(themes.filter(item => item !== "null").map(item => ({label: item, value: item})));
      else
         dispatch(receiveThemes());
   },[]);

   return (
      <form className="form form-feedback" onSubmit={(e) => handleSubmit(e)}>
         <div className="form-feedback__head form__control form__border flex">
            <div className="form__item">
               <b>Имя:</b> {questionDetail.name}
            </div>
            <div className="form__item">
               <b>Email:</b> {questionDetail.email}
            </div>
         </div>
         <div className="form-feedback__body">
            <div
               className="form__control flex flex--ac"
               role="group"
               aria-labelledby="location-area"
            >
               <div className="form__title title-bold" id="location-area">
                  Тема:
               </div>
               <div className="form__item form__item--min">
                  <Field
                     id="theme"
                     name="theme"
                     placeholder="Выберите"
                     component={RenderSelect}
                     options={select}
                  />
               </div>
            </div>

            <div className="form-info">
               <span>{ dateFormat(questionDetail.date_create,"DD MMMM YYYY г., в h:mm")}</span>
            </div>
            <div className="form-content">
               <p className="mt-none">
                  <b>Вопрос:</b>
               </p>
               <p className="mb-none">
                  {questionDetail.quest}
               </p>
            </div>

            {
               (questionDetail.photos && questionDetail.photos.length > 0) &&
                  <Gallery handleDelete={handleDeleteImage} images={questionDetail.photos} />
            }

         </div>
         <div className="form-feedback__foot">
            <fieldset className="fieldset">
               <legend className="title-bold">Ваш ответ:</legend>

               <Field
                  component={WYSIWYGEditor}
                  id="answer"
                  name="answer"
                  type="text"
               />
            </fieldset>
         </div>

         <div className="form__foot button-group flex">
            <ButtonBack type="button" onClick={() => history.goBack()}  />
            <ButtonPublished type="submit" />
            <ButtonUnpublish type="button" onClick={unPublished}  />
         </div>
      </form>
   );
};

export default reduxForm({
   form: "answers-form",
   destroyOnUnmount: false,
   /*enableReinitialize: true,*/
})(Form);
