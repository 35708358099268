import React from "react";
import {Link} from "react-router-dom";
import {ButtonDelete} from "../Buttons/ButtonDelete";
import {ButtonVisibility} from "../Buttons/ButtonVisibility";
import {ButtonUnVisibility} from "../Buttons/ButtonUnVisibility";
import {HideModal} from "../Modal/HideModal";
import {Filter} from "../Filter/Filter";

export const TableList = ({
                             head,
                             list = false,
                             handleDelete,
                             isDelete = true,
                             isVisible = false,
                             isUnhide = false,
                             urlEdit = false,
                             customItem = false,
                             handleVisibility,
                             onChangeFilter = void 0,
                             filtersColumn = [],
                          }) => {

   const [modalProps, setModalProps] = React.useState({
      open: false,
      text: 'Заблокировать?'
   });
   const setOpen = (open) => {
      setModalProps(state =>{
         return {
            ...state,
            open,
         }
      })
   }

   const handleDeleteNews = (id) => {
      handleDelete(id);
   }

   const handleOpenModal = (text, onOk) => {
      setModalProps(state => {
         return {
            ...state,
            open: true,
            text,
            onOk,
         }
      })
   };

   return (
      <>
         <table className="table">
            <thead>
               <tr>
                  {head.map((th, i) => {
                     const filterOption = filtersColumn.find(filter => filter.columnName === th);
                     return <th key={i}>{th}{filterOption && <Filter {...filterOption} onSelect={onChangeFilter}/>}</th>
                  })}
               </tr>
            </thead>
            <tbody>
               {list && list.map((item, index) => (
                  <tr key={index}>
                     {Object.keys(item).map((key, i) => {
                        if (key === "links") {
                           return (
                              <td key={i}>
                                 <>
                                    {isDelete && (
                                       <ButtonDelete w={22} h={22} onClick={() => handleDeleteNews(!customItem ? item.id : index)} />
                                    )}
                                    {item.links.edit && (
                                       <Link
                                          to={`${item.links.edit}/${item.id}`}
                                          className="btn-option btn-reset"
                                          title="Редактировать"
                                          aria-label="Редактировать"
                                       >
                                          <svg
                                             xmlns="http://www.w3.org/2000/svg"
                                             width="22"
                                             height="22"
                                             viewBox="0 0 22 22"
                                             fill="none"
                                          >
                                             <path
                                                d="M16.6 1L1.51429 16.3936L1 21L5.91429 20.8834L21 5.4898L16.6 1Z"
                                                stroke="black"
                                             />
                                          </svg>
                                       </Link>
                                    )}
                                    {isVisible && (
                                       <ButtonVisibility
                                          onClick={() =>
                                             handleOpenModal('Заблокировать?', () => handleVisibility && handleVisibility(!customItem ? item.id : index, false))
                                          }
                                       />
                                    )}
                                    {isUnhide && (
                                       <ButtonUnVisibility
                                          onClick={() => handleOpenModal('Разблокировать?', () => handleVisibility && handleVisibility(!customItem ? item.id : index, true))}
                                       />
                                    )}
                                 </>
                              </td>
                           );
                        } else {
                           return <td key={i}>{urlEdit && key !== 'id' ?
                              <Link
                                 to={urlEdit + `/${item.id}`}
                                 title="Редактировать"
                                 aria-label="Редактировать"
                              >
                                 {item.name}
                              </Link>
                              : item[key]}</td>;
                        }
                     })}
                  </tr>
               ))}
            </tbody>
         </table>
         <HideModal {...modalProps} setOpen={setOpen} />
      </>
   );
};
