import React, {useEffect, useRef, useState} from 'react';
import {Stage, Layer, Line} from 'react-konva';
import {BaseImage} from "../General/BaseImage";
import Portal from "../General/Portal";
import {CirclePoint} from "./CirclePoint";
import {ContextMenu} from "./ContextMenu";
import {useCheckSize} from "../General/useCheckSize";
import {getRelativePointerPosition} from "../General/getRelativePointerPosition";
import {ChangeText} from "./ChangeText";
import {Title} from "./Title";
import {ZoomStage} from "../General/ZoomStage";
import {useDispatch, useSelector} from "react-redux";
import {setHouse} from "../../../actions/complexActions";
import {Redirect, useRouteMatch} from "react-router-dom";
import API from "../../../utils/API";

let circleId = 1;

export const CanvasEntrance = (floors) => {
   const [size, setSize] = useCheckSize(); // размеры
   const [scale, setScale] = useState(1); // маштаб

   const stageRef = useRef();
   const [points, setPoint] = useState([]);
   const [closed, setClosed] = useState(false);
   const [isDrawing, setDrawing] = useState(true);

   //closing drawing
   const handleClick = (id) => {
      if (id === 1) {
         circleId = 1;
         setClosed(true);
         setDrawing(false)
      }
   };

   const handleDragMove = (x, y, id) => {
      const newPoints = points.map(p => {
         if (p.id === id){
            return {
               id: p.id,
               x,
               y
            }
         }
         return p;
      });
      setPoint(newPoints);
   };

   /* context menu */
   const [contextMenu, setContextMenu] = useState(null);
   const handleContextMenu = (e) => {
      e.evt.preventDefault(true);
      const rect = e.target.getStage().container().getBoundingClientRect();
      const mousePosition = {
         x: rect.left+ window.scrollX + e.target.getStage().getPointerPosition().x + 'px',
         y: rect.top +  window.scrollY+ e.target.getStage().getPointerPosition().y + 'px'
      };
      setContextMenu(mousePosition)
   };

   const deleteShape = () => {
      setPoint([]);
      setContextMenu(null);
      setDrawing(true);
      setClosed(false);
      circleId = 1;
   };

   /* edit text and drag*/
   const [nameValue, setNameValue] = useState('Название');
   const [editVisible, setEditVisible] = useState(false);
   const [positionTextarea, setPositionTextarea] = useState({textX: 0, textY: 0});
   const [positionText, setPositionText] = useState({x: 50, y: 20});

   const [save, setSave] = useState(false);
   const dispatch = useDispatch();
   const house = useSelector(state => state.complex.house);
   const image = house.imageplan;

   let match  = useRouteMatch();
   const homeId = match.params.home_id;
   const complexId = match.params.id;
   const entranceId = match.params.entrance_id || false;

   //Добавим точки к дому на редактирование
   useEffect(() => {
         if(entranceId)
         {
            const home = house.entrances[entranceId];
            const homePlan = JSON.parse(home.plan);

            if(homePlan.popup)
               setPositionText(homePlan.popup.position);
               setNameValue(homePlan.popup.title);

            if(homePlan.points)
               setPoint(homePlan.points);
         }
   },[house,entranceId]);

   const saveShape = (e) => {
      setContextMenu(null);
      //Изменим дом
      /*let entrance = {
         name: nameValue,
         house_id: homeId,
         new: true,
         plan: JSON.stringify({
            points: points,
            popup: {
               title: nameValue,
               position: {
                  x: positionTextarea.textX,
                  y: positionTextarea.textY,
               }
            }
         })
      }*/


      let dataForm = new FormData();
      dataForm.append('name', nameValue);
      dataForm.append('house_id', parseInt(homeId));

      if(floors.floors){
         dataForm.append('floors', parseInt(floors.floors));
      }

      dataForm.append('plan', JSON.stringify({
         points: points,
         popup: {
            title: nameValue,
            position: {
               x: positionTextarea.textX,
               y: positionTextarea.textY,
            }
         }
      }));

      API.post(`entrance/create`, dataForm).then((res) =>{
               console.log("Save Entrance: ", res);
               setSave(true);
         }
      ).catch((e) => {
            console.log('Error Save:', e);
         });

   };

   const handleTextDblClick = (e) => {
      let textNode = e.target;
      let stage = e.target.getStage();

      // at first lets find position of text node relative to the stage:
      let textPosition = textNode.getAbsolutePosition();

      // then lets find position of stage container on the page:
      let stageBox = stage.container().getBoundingClientRect();

      // so position of textarea will be the sum of positions above:
      let areaPosition = {
         x: stageBox.left + textPosition.x,
         y: stageBox.top + textPosition.y,
      };

      // create textarea and style it
      let textarea = document.createElement('textarea');
      document.body.appendChild(textarea);

      textarea.value = textNode.text();
      textarea.style.position = 'absolute';
      textarea.style.top = areaPosition.y + 'px';
      textarea.style.left = areaPosition.x + 'px';
      textarea.style.width = textNode.width();
      textarea.classList.add('label_editor');

      textarea.focus();

      textarea.addEventListener('keydown', function (e) {
         // hide on enter
         if (e.keyCode === 13) {
            textNode.text(textarea.value);
            setNameValue(textarea.value);
            document.body.removeChild(textarea);
         }

         //escape
         if (e.keyCode === 27) {
            document.body.removeChild(textarea);
         }
      });
      /*end generate*/
   };

   useEffect(() => {
      return () => {
         const elem = document.getElementsByClassName('label_editor');
         while(elem.length) {
            elem[0].parentNode.removeChild(elem[0]);
         }
      }
   },[]);

   const handleDragMoveText = (e) => {
      setPositionTextarea({textX: e.target.x(), textY: e.target.y()});
      setPositionText({x: e.target.x(), y: e.target.y()})
   };

   const handleTextEdit = (e) => {
      setNameValue(e.target.value);
   };

   const handleTextareaKeyDown = (e) => {
      if (e.keyCode === 13 && !e.shiftKey) {
         setEditVisible(false);
         setPositionText({x: positionTextarea.textX, y: positionTextarea.textY});
      }

      //escape
      if (e.keyCode === 27) {
         setEditVisible(false);
         setPositionText({x: positionTextarea.textX, y: positionTextarea.textY});
      }
   };

   /* end */

   const zoomStage = (stage, scaleBy) => {
      ZoomStage(stage, scaleBy)
   };

   if(save)
      return <Redirect to={`/residential-complex/update/${complexId}/build/${homeId}/settings`} />;

   return (
      <>
         <div className="frame-canvas">
            <Stage
               ref={stageRef}
               width={size.width}
               height={size.height}
               scaleX={scale}
               scaleY={scale}
               className="canvas"
               draggable={true}
               onClick={e => {
                  if(isDrawing) {
                     const pointStart = getRelativePointerPosition(e.target.getStage());

                     const point = {
                        id: circleId++,
                        x: pointStart.x,
                        y: pointStart.y
                     };

                     setPoint([...points, point]);
                  }
               }}
            >
               <Layer>
                  <BaseImage photo={image} size={size} setScale={setScale} setSize={setSize} />
               </Layer>

               {points.length ?
                  <Layer>
                     <Line
                        points={points.flatMap(p => [p.x, p.y])}
                        stroke="red"
                        closed = {closed}
                        fill = '#e0f5c0'
                        opacity={0.5}
                        onContextMenu={handleContextMenu}
                     />

                     {points.map((point, index) => (
                        <CirclePoint
                                     key={index}
                                     point={point}
                                     handleClick={handleClick}
                                     handleDragMove={handleDragMove}
                                     isDrawing={isDrawing}
                        />
                     ))}

                     {(closed && editVisible === false) &&
                        <Title
                           position={positionText}
                           handleDragMoveText={handleDragMoveText}
                           handleTextDblClick={handleTextDblClick}
                           title={nameValue}
                        />
                     }

                     <Portal>
                        {contextMenu &&
                           <ContextMenu
                              {...contextMenu}
                              deleteShape={deleteShape}
                              saveShape={saveShape}
                           />
                        }

                        {closed &&
                           <ChangeText
                              value={nameValue}
                              handleTextEdit={handleTextEdit}
                              handleTextareaKeyDown={handleTextareaKeyDown}
                              position={positionTextarea}
                              editVisible={editVisible}
                           />
                        }
                     </Portal>
                  </Layer>
                  : <></>
               }
            </Stage>
            <div className="zoom-container">
               <button
                  onClick={() => {
                     zoomStage(stageRef.current, 1.2);
                  }}
               >
                  +
               </button>
               <button
                  onClick={() => {
                     zoomStage(stageRef.current, 0.8);
                  }}
               >
                  -
               </button>
            </div>
         </div>
      </>
   )
};
