import {resellerConstants} from "../../constants/resellerConstants";

const initialState = {
   data: [],
   hasError: false,
   isLoading: false,
   currentObject: null,
   filter: {}
}

export const resellerReducer = (state = initialState, action) => {
   switch (action.type) {
      case resellerConstants.SET_OBJECTS:
         return {
            ...state,
            isLoading: false,
            data: action.payload.data,
            hasError: false,
         }
      case resellerConstants.SET_FETCHING:
         return {
            ...state,
            isLoading: true,
            hasError: false,
         }
      case resellerConstants.SET_UNFETCHING:
         return {
            ...state,
            isLoading: false,
            hasError: false,
         }
      case resellerConstants.SET_ERROR:
         return {
            ...state,
            isLoading: false,
            hasError: true,
         }
      case resellerConstants.SET_RESELLER_DETAIL:
         return {
            ...state,
            isLoading: false,
            hasError: false,
            currentObject: action.payload.data,
         }
      case resellerConstants.SET_FILTER:
         return {
            ...state,
            filter: action.payload.data,
         }
      default:
         return state;
   }
}
