import { ResidentialComplexContainer } from "../components/ResidentialComplex/list/ResidentialComplexСontainer";
import { Settings } from "../components/ResidentialComplex/Settings/Settings";
import { CreateFlats } from "../components/ResidentialComplex/Settings/Flats/CreateOrUpdate/CreateFlats";
import { ResellersListContainer } from "../components/Resellers/list/ResellersListContainer";
import { ResellersCreateContainer } from "../components/Resellers/CreateOrUpdate/ResellersCreateContainer";
import { AboutContainer } from "../components/Pages/About/AboutContainer";
import { OfferListContainer } from "../components/Pages/Offer/OfferListContainer";
import { CreateOrUpdateOffer } from "../components/Pages/Offer/CreateOrUpdate/CreateOrUpdateOffer";
import { NewsListContainer } from "../components/Pages/News/NewsListContainer";
import { CreateOrUpdatePage } from "../components/Pages/News/CreateOrUpdate/CreateOrUpdatePage";
import { ServicesContainer } from "../components/Pages/Services/ServicesContainer";
import { TradeInContainer } from "../components/Pages/TradeIn/TradeInContainer";
import { UpdateSectionPageContainer } from "../components/Pages/Sections/UpdateSectionPageContainer";
import { UpdateCity } from "../components/Сontacts/CreateOrUpdateCity/UpdateCity";
import { SettingContainer } from "../components/Settings/SettingsContainer";
import { ApplicationRCContainer } from "../components/Applications/ApplicationsRCListContainer";
import { ResaleContainer } from "../components/Applications/ResaleListСontainer";
import { CallbacksListContainer } from "../components/Applications/CallbacksListContainer";
import { CreateEmployer } from "../components/Сontacts/Employer/CreateOrUpdate/CreateEmployer";
import { ParkingListContainer } from "../components/Parking/ParkingList/ParkingListContainer";
import { ParkingCreateOrUpdate } from "../components/Parking/CreateOrUpdate/ParkingCreateOrUpdate";
import { SuggestionContainer } from "../components/Applications/SuggestionContainer";
import { FeedbackListOntainer } from "../components/Feedback/FeedbackListСontainer";
import { FeedbackEdit } from "../components/Feedback/FeddbackEdit/FeedbackEdit";
import { ApplicationParkingListContainer } from "../components/Applications/ApplicationsParkingListContainer";
import { ParkingLevelCreateOrUpdate } from "../components/Parking/ParkingLevel/ParkingLevelCreateOrUpdate";
import { CreateOrUpdatePlace } from "../components/Parking/ParkingLevel/CreateOrUpdate/CreateOrUpdatePlace";
import { CommercialPropertyListContainer } from "../components/CommercialProperty/List/CommercialPropertyListContainer";
import { CreateCommercialProperty } from "../components/CommercialProperty/CreateOrUpdate/CreateCommercialProperty";
import { CreateBuild } from "../components/ResidentialComplex/CreateOrUpdate/CreateBuild";
import { UpdateBuild } from "../components/ResidentialComplex/CreateOrUpdate/UpdateBuild";
import { NewBuildCreate } from "../components/ResidentialComplex/NewBuild/NewBuild";
import { QuestionsListContainer } from "../components/AnswersOnQuestions/List/QuestionsListContainer";
import { AnswersContainer } from "../components/AnswersOnQuestions/Answers/AnswersContainer";
import { PagesListContainer } from "../components/Pages/PageListContainer";
import { CreateSectionPage } from "../components/Pages/Sections/CreateSectionPage";
import { CitiesListContainer } from "../components/Сontacts/CitiesList/CitiesListContainer";
import { CreateCity } from "../components/Сontacts/CreateOrUpdateCity/CreateCity";
import UpdateCommercialProperty from "../components/CommercialProperty/CreateOrUpdate/UpdateCommpercialProperty";
import { SettingsCity } from "../components/Сontacts/SettingsCity";
import { HomeUpdate } from "../components/ResidentialComplex/NewBuild/HomeUpdate";
import { CreatePorch } from "../components/ResidentialComplex/Settings/Porch/CreateOrUpdate/CreatePorch";
import { CreateOffice } from "../components/CommercialProperty/Office/CreateOffice";
import { UpdateOffice } from "../components/CommercialProperty/Office/UpdateOffice";
import { CanvasUpdateOffice } from "../components/CommercialProperty/CanvasUpdateOffice/CanvasUpdateOffice";
// import { UpdateOffice } from "../components/CommercialProperty/UpdateOffice/UpdateOffice";
import { FloorContainer } from "../components/Floor/FloorContainer";
import { PorchEdit } from "../components/Floor/Porch/PorchEdit";
import { TplApartContainer } from "../components/TplApart/TplApartContainer";
import { ApartContainer } from "../components/Apart/Container";
import { SocialBuild } from "../components/ResidentialComplex/NewBuild/SocialBuild";
import { Poll } from "../components/Poll";
import { CreateOrUpdatePoll } from "../components/Poll/CreateOrUpdate";
import {ExcursionHouse} from "../components/Applications/ExursionHouse";
import {OfficeCallback} from "../components/Applications/OfficeCallback";
import {ProfitBaseListContainer} from "../components/ProfitBase/ProfitBaseListСontainer";
import {ProfitBaseEdit} from "../components/ProfitBase/FeedbackEdit";
import {UsersContainer} from "../components/Users/UsersContainer";
import {CreateUser} from "../components/Users/CreateUser";

export const routes = [
   {
      path: ["/:home_id/apart-create", "/:home_id/apart/edit/:apart_id"],
      exact: true,
      component: ApartContainer,
   },
   {
      path: "/residential-complex",
      exact: true,
      component: ResidentialComplexContainer,
   },
   {
      path: "/residential-complex/create",
      exact: true,
      component: CreateBuild,
   },
   {
      path: "/residential-complex/update/:id",
      exact: true,
      component: UpdateBuild,
   },
   {
      path: "/residential-complex/update/:id/build/create",
      component: NewBuildCreate,
   },
   {
      path: "/residential-complex/update/:id/social-create",
      component: SocialBuild,
   },
   {
      path: "/residential-complex/update/:id/social-update/:social_id",
      component: SocialBuild,
   },
   /*Update Home*/
   {
      path: "/residential-complex/update/:id/update/:home_id",
      component: HomeUpdate,
   },
   /*End*/
   {
      path: "/residential-complex/update/:id/build/:home_id/settings",
      exact: true,
      component: Settings,
   },
   {
      path:
         "/residential-complex/:id/update/build/:home_id/settings/flats/create",
      component: CreateFlats,
   },
   {
      path: [
         "/residential-complex/update/:id/build/:home_id/porch/edit/:entrance_id/floor-edit/:floor_id/tplapart/create",
         "/residential-complex/update/:id/build/:home_id/porch/edit/:entrance_id/floor-edit/:floor_id/tplapart/update/:tpl_apart_id",
      ],
      component: TplApartContainer,
   },
   {
      path:
         "/residential-complex/update/:id/build/:home_id/porch/edit/:entrance_id/floor-edit/:floor_id",
      component: FloorContainer,
   },
   {
      path:
         "/residential-complex/update/:id/build/:home_id/porch/edit/:entrance_id/floor/create",
      component: FloorContainer,
   },
   {
      path: [
         "/residential-complex/update/:id/build/:home_id/settings/porch/create",
         "/residential-complex/update/:id/build/:home_id/porch/update/:entrance_id",
      ],
      component: CreatePorch,
   },
   {
      path:
         "/residential-complex/update/:id/build/:home_id/porch/edit/:entrance_id",
      component: PorchEdit,
   },
   {
      path: "/resellers",
      exact: true,
      component: ResellersListContainer,
   },
   {
      path: "/resellers/resellers-create",
      exact: true,
      component: ResellersCreateContainer,
   },
   {
      path: "/resellers/resellers/update/:id",
      exact: true,
      component: ResellersCreateContainer,
   },

   {
      path: "/commercial-property",
      exact: true,
      component: CommercialPropertyListContainer,
   },
   {
      path: "/commercial-property/create",
      exact: true,
      component: CreateCommercialProperty,
   },
   {
      path: "/commercial-property/update/:id/office/update",
      exact: true,
      component: CreateOffice,
   },
   {
      path: "/commercial-property/update/:id/office/create",
      exact: true,
      component: CreateOffice,
   },
   {
      path: "/commercial-property/create/office/create",
      exact: true,
      component: CreateOffice,
   },
   {
      path: "/commercial-property/update/:id",
      exact: true,
      component: UpdateCommercialProperty,
   },
   {
      path: "/commercial-property/create/office/update/:office_id",
      exact: true,
      component: UpdateOffice,
   },
   {
      path: "/commercial-property/update/:id/update/:office_id",
      component: UpdateOffice,
   },

   {
      path: "/parking",
      exact: true,
      component: ParkingListContainer,
   },
   {
      path: "/parking/create",
      exact: true,
      component: ParkingCreateOrUpdate,
   },
   {
      path: "/parking/update/:id",
      exact: true,
      component: ParkingCreateOrUpdate,
   },
   {
      path: "/parking/create/parking-level",
      exact: true,
      component: ParkingLevelCreateOrUpdate,
   },
   {
      path: "/parking/parking-level/update/:id",
      exact: true,
      component: ParkingLevelCreateOrUpdate,
   },
   {
      path: "/parking/parking-level/update/:id/create",
      exact: true,
      component: CreateOrUpdatePlace,
   },
   {
      path: "/parking/parking-level/update/:id/update/:placeId",
      exact: true,
      component: CreateOrUpdatePlace,
   },

   {
      path: "/applications-residential-complex",
      exact: true,
      component: ApplicationRCContainer,
   },
   {
      path: "/applications-resale",
      exact: true,
      component: ResaleContainer,
   },
   {
      path: "/applications-parking",
      exact: true,
      component: ApplicationParkingListContainer,
   },
   {
      path: "/callbacks",
      exact: true,
      component: CallbacksListContainer,
   },
   {
      path: "/house-callback",
      exact: true,
      component: ExcursionHouse,
   },
   {
      path: "/office-callback",
      exact: true,
      component: OfficeCallback,
   },
   {
      path: "/suggestion",
      exact: true,
      component: SuggestionContainer,
   },

   {
      path: "/feedback",
      exact: true,
      component: FeedbackListOntainer,
   },
   {
      path: "/feedback/update/:id",
      exact: true,
      component: FeedbackEdit,
   },

   {
      path: "/questions",
      exact: true,
      component: QuestionsListContainer,
   },
   {
      path: "/questions/update/:id",
      exact: true,
      component: AnswersContainer,
   },

   {
      path: "/pages",
      exact: true,
      component: PagesListContainer,
   },
   {
      path: "/pages/about",
      exact: true,
      component: AboutContainer,
   },
   {
      path: "/pages/offer",
      exact: true,
      component: OfferListContainer,
   },
   {
      path: "/pages/offer/create",
      exact: true,
      component: CreateOrUpdateOffer,
   },
   {
      path: "/pages/offer/update/:id",
      exact: true,
      component: CreateOrUpdateOffer,
   },
   {
      path: "/pages/news",
      exact: true,
      component: NewsListContainer,
   },
   {
      path: "/pages/news/create",
      exact: true,
      component: CreateOrUpdatePage,
   },
   {
      path: "/pages/news/update/:id",
      exact: true,
      component: CreateOrUpdatePage,
   },
   {
      path: "/pages/services",
      exact: true,
      component: ServicesContainer,
   },
   {
      path: "/pages/trade-in",
      exact: true,
      component: TradeInContainer,
   },
   {
      path: "/pages/create",
      exact: true,
      component: CreateSectionPage,
   },
   {
      path: "/pages/:id/update",
      exact: true,
      component: UpdateSectionPageContainer,
   },
   {
      path: "/pages/poll",
      exact: true,
      component: Poll,
   },
   {
      path: "/pages/poll/create",
      exact: true,
      component: CreateOrUpdatePoll,
   },
   {
      path: "/pages/poll/update/:id",
      exact: true,
      component: CreateOrUpdatePoll,
   },
   {
      path: "/pages/contacts",
      exact: true,
      component: CitiesListContainer,
   },
   {
      path: "/pages/contacts/create",
      exact: true,
      component: CreateCity,
   },
   {
      path: "/pages/contacts/:id/settings",
      exact: true,
      component: SettingsCity,
   },
   {
      path: "/pages/contacts/:id/settings/employer/create",
      exact: true,
      component: CreateEmployer,
   },
   {
      path: "/pages/contacts/:id/settings/employer/update/:employer_id",
      exact: true,
      component: CreateEmployer,
   },
   {
      path: "/settings",
      exact: true,
      component: SettingContainer,
   },

   {
      path: "/profit-base",
      exact: true,
      component: ProfitBaseListContainer,
   },
   {
      path: "/profit-base/update/:id",
      exact: true,
      component: ProfitBaseEdit,
   },
   {
      path: "/users",
      exact: true,
      component: UsersContainer,
   },
   {
      path: "/users/create",
      exact: true,
      component: CreateUser,
   },
];
