import React, { useRef, useState, useEffect } from "react";
import { Stage, Layer, Line } from "react-konva";

import { ContextMenu } from "./ContextMenu";
import Portal from "../../Canvas/General/Portal";
import { CirclePoint } from "../../Canvas/CanvasCreate/CirclePoint";
import { BaseImage } from "../../Canvas/General/BaseImage";
import { useCheckSize } from "../../Canvas/General/useCheckSize";
import { Popup } from "../../Canvas/CancasPreview/Popup";
import { Figure } from "./Figure";
import { ZoomStage } from "../../Canvas/General/ZoomStage";
import { getRelativePointerPosition } from "../../Canvas/General/getRelativePointerPosition";

import { isEmpty, isEqual } from "../../../utils/utils";

let CIRCLE_ID = 1;

export const OfficeCanvas = ({
   image,
   onPoints,
   currentPoints,
   onSave,
   offices,
   idUpdating,
}) => {
   const [size, setSize] = useCheckSize(); // размеры
   const [scale, setScale] = useState(1); // маштаб
   const stageRef = useRef();
   const [points, setPoint] = useState([]);
   const [data, setData] = useState([]);
   const [closed, setClosed] = useState(false);
   const [isDrawing, setDrawing] = useState(true);

   const normalizeData = (values) =>
      values.map((item) => ({
         ...item,
         points: !isEmpty(item.plan) ? item.plan.points : [],
      }));

   useEffect(() => {
      if (!isEmpty(offices) && isEmpty(data)) {
         setData(normalizeData(offices));
      }
   }, [offices, data]);

   useEffect(() => {
      if (idUpdating) {
         setClosed(true);
      }
   }, [idUpdating]);

   useEffect(() => {
      if (!isEmpty(currentPoints) && isEmpty(points)) {
         setPoint(currentPoints);
      }
   }, [points, currentPoints]);

   /* context menu */
   const [contextMenu, setContextMenu] = useState(null);

   //closing drawing
   const handleClick = (id) => {
      if (id === 1) {
         CIRCLE_ID = 1;
         setClosed(true);
         setDrawing(false);
      }
   };

   const handleDragMove = (x, y, id) => {
      const newPoints = points.map((p) => {
         if (p.id === id) {
            return {
               id: p.id,
               x,
               y,
            };
         }
         return p;
      });
      setPoint(newPoints);
      if (onPoints) {
         onPoints(newPoints);
      }
   };

   const handleContextMenu = (e) => {
      e.evt.preventDefault(true);
      const rect = e.target.getStage().container().getBoundingClientRect();
      const mousePosition = {
         x:
            rect.left +
            window.scrollX +
            e.target.getStage().getPointerPosition().x +
            "px",
         y:
            rect.top +
            window.scrollY +
            e.target.getStage().getPointerPosition().y +
            "px",
      };
      setContextMenu(mousePosition);
   };

   const deleteShape = () => {
      setPoint([]);
      setContextMenu(null);
      setDrawing(true);
      setClosed(false);
      if (onPoints) {
         onPoints([]);
      }
      CIRCLE_ID = 1;
   };

   const saveShape = (e) => {
      setContextMenu(null);

      if (onSave) {
         onSave();
      }
   };
   /* end */

   const zoomStage = (stage, scaleBy) => {
      ZoomStage(stage, scaleBy);
   };

   const handleClickCanvas = (event) => {
      if (isDrawing) {
         const pointStart = getRelativePointerPosition(event.target.getStage());
         const point = {
            id: CIRCLE_ID++,
            x: pointStart.x,
            y: pointStart.y,
         };
         setPoint([...points, point]);
         if (onPoints) {
            onPoints([...points, point]);
         }
      }
   };

   const renderZoomButtons = () => {
      return (
         <div className="zoom-container">
            <button
               onClick={() => {
                  zoomStage(stageRef.current, 1.2);
               }}
            >
               +
            </button>
            <button
               onClick={() => {
                  zoomStage(stageRef.current, 0.8);
               }}
            >
               -
            </button>
         </div>
      );
   };

   return (
      <div className="frame-canvas">
         <Stage
            ref={stageRef}
            width={size.width}
            height={size.height}
            scaleX={scale}
            scaleY={scale}
            className="canvas"
            draggable={true}
            onClick={handleClickCanvas}
         >
            <Layer>
               <BaseImage
                  photo={image}
                  size={size}
                  setScale={setScale}
                  setSize={setSize}
               />
               {data.map((polygon, index) => {
                  if (idUpdating && isEqual(idUpdating, polygon.id))
                     return null;
                  return <Figure key={index} polygon={polygon} />;
               })}
            </Layer>

            {!isEmpty(points) && (
               <Layer>
                  <Line
                     points={points.flatMap((p) => [p.x, p.y])}
                     stroke="red"
                     closed={closed}
                     fill="#e0f5c0"
                     opacity={0.5}
                     onContextMenu={handleContextMenu}
                  />
                  {points.map((point, index) => (
                     <CirclePoint
                        key={index}
                        point={point}
                        handleClick={handleClick}
                        handleDragMove={handleDragMove}
                        isDrawing={isDrawing}
                     />
                  ))}
                  {contextMenu && (
                     <Portal>
                        {contextMenu && (
                           <ContextMenu
                              {...contextMenu}
                              deleteShape={deleteShape}
                              saveShape={saveShape}
                           />
                        )}
                     </Portal>
                  )}
               </Layer>
            )}
         </Stage>
         {renderZoomButtons()}
      </div>
   );
};
