import React from "react";
import { Field, reduxForm } from "redux-form";
import { RenderInput } from "../Fields/RenderInput";

const FormSearch = ({ handleSubmit }) => {
   return (
      <form className="search" onSubmit={(e) => handleSubmit(e)}>
         <div className="form__item">
            <label className="visually-hidden" htmlFor="search">
               Поиск
            </label>
            <Field
               id="search"
               name="search"
               className="control control--custom"
               component={RenderInput}
               placeholder="Поиск"
            />
            <button
               className="btn btn--green btn--no-border search__btn"
               aria-label="Поиск"
               title="Поиск"
            >
               <svg
                  width="20"
                  height="20"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
               >
                  <path
                     d="M13 13L8.14286 8.14286M9.57143 5.28571C9.57143 7.65265 7.65265 9.57143 5.28571 9.57143C2.91878 9.57143 1 7.65265 1 5.28571C1 2.91878 2.91878 1 5.28571 1C7.65265 1 9.57143 2.91878 9.57143 5.28571Z"
                     stroke="white"
                  />
               </svg>
            </button>
         </div>
      </form>
   );
};

export default reduxForm({
   form: "search",
   enableReinitialize: true,
})(FormSearch);
