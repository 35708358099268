import React, { useEffect } from "react";
import { ButtonDelete } from "../../Buttons/ButtonDelete";
import { ButtonAdd } from "../../Buttons/ButtonAdd";

const defaultAnswer = {
   name: "",
};

const Answer = ({
   answer: { name = "", ...answerProps },
   voicesPercent,
   voicesTotal,
   canAdd,
   onAdd,
   onChange,
   onDelete,
}) => {
   return (
      <div className="poll__answers__answer">
         <div className="poll__answers__answer__field">
            <input
               type="text"
               value={name}
               className="control"
               onChange={(event) => onChange(event.target.value)}
               disabled={answerProps.id ? true : false}
            />
         </div>
         <div className="poll__answers__answer__action-button">
            <ButtonDelete type="button" onClick={onDelete} />
         </div>
         {canAdd && (
            <div className="poll__answers__answer__action-button">
               <ButtonAdd type="button" onClick={onAdd} />
            </div>
         )}
         {answerProps.voices !== null && answerProps.voices !== undefined && (
            <div className="poll__answers__answer__voices">
               Проголосовало: {answerProps.voices} из {voicesTotal} /{" "}
               {voicesPercent}
            </div>
         )}
      </div>
   );
};

export const Answers = ({ answers, mode, onChangeAnswer, onDeleteAnswer }) => {
   useEffect(() => {
      if (answers.length === 0) {
         onChangeAnswer([""]);
      }
   }, [answers, onChangeAnswer]);

   const handleChange = (index) => (value) => {
      onChangeAnswer([
         ...answers.slice(0, index),
         { ...answers[index], name: value },
         ...answers.slice(index + 1),
      ]);
   };

   const handleAdd = () => {
      onChangeAnswer([...answers, defaultAnswer]);
   };

   const handleDelete = (index) => {
      if (mode === "create") {
         onChangeAnswer([
            ...answers.slice(0, index),
            ...answers.slice(index + 1),
         ]);
      } else {
         onDeleteAnswer(answers[index].id);
      }
   };

   const totalVotesCalc = (answers) =>
      answers.reduce((acc, answer) => acc + answer.voices, 0);

   const percentAnswerCalc = (voices, totalVoices) =>
      totalVoices === 0
         ? 0 + "%"
         : ((voices / totalVoices) * 100).toFixed(1) + "%";

   return (
      <div className="poll__answers">
         {answers.map((answer, index) => (
            <Answer
               key={index}
               canAdd={answers.length === index + 1}
               onAdd={handleAdd}
               onChange={(value) => handleChange(index)(value)}
               onDelete={() => handleDelete(index)}
               answer={answer}
               voicesTotal={answers.length}
               voicesPercent={percentAnswerCalc(
                  answer.voices,
                  totalVotesCalc(answers)
               )}
            />
         ))}
      </div>
   );
};
