import React from "react";

export const IconDelete = ({ w = 16, h = 16, stroke = "black" }) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={h}
      viewBox="0 0 16 16"
      fill="none"
      aria-hidden="true"
   >
      <path d="M1 1L8 8M8 8L15 15M8 8L15 1M8 8L1 15" stroke={stroke} />
   </svg>
);
