import React, {useState} from 'react';
import { Circle } from 'react-konva';

export const CirclePoint = ({point, handleClick, handleDragMove}) => {
   const [firstCircleRef, setFirstCircleRef] = useState(null)

   const makeBig = (id) => {
      firstCircleRef.to({
         scaleX: 1.8,
         scaleY: 1.8,
         fill: '#37B6CE',
         duration: 0.2
      })
   };

   const makeSmall = (id) => {
      firstCircleRef.to({
         scaleX: 1,
         scaleY: 1,
         fill: '#FF7C00',
         duration: 0.2
      })
   };

   return (
      <Circle
         x={point.x}
         y={point.y}
         radius={4}
         fill="#da251d"
         draggable
         onClick={() => handleClick(point.id)}
         ref = {setFirstCircleRef}
         onDragMove = {(e) => handleDragMove(e.target.x(), e.target.y(), point.id)}
         onMouseOver = {() => makeBig(point.id)}
         onMouseOut = {() => makeSmall(point.id)}
      />
   )
};
