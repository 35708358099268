import API from "../utils/API";
import { managerConstants } from "../constants/managerConstants";

export const setManagers = (data) => ({type: managerConstants.FETCHING_MANAGERS, payload: {data}});

export const loadingManagers = (city_id) => (dispatch) => {
   API.get(`manager/${city_id}`)
      .then(({data}) => {
         if(data.items){
            dispatch(setManagers({
               manager: data.items.filter(item => item.category === "manager"),
               manager_resellers: data.items.filter(item => item.category === "manager_resellers"),
               manager_commercial: data.items.filter(item => item.category === "manager_commercial")
            }));
         }
      });
}

export const saveManager = (data, id) => (dispatch) => {
   API.post(`manager/create`,data)
      .then(({data}) => {
         if(data)
         {
            dispatch(loadingManagers(id));
         }
      });
}
export const getManagers = (id) => (dispatch) => {
   dispatch(loadingManagers(id));
}
export const updateManager = (data, id = false,city_id = false) => (dispatch) => {
   API.put(`manager/update/${id}`,data)
      .then(({data}) => {
         if(data)
            dispatch(loadingManagers(city_id));
      });
}
export const deleteManager = (id, city_id) => (dispatch) => {
   API.delete(`manager/delete/${id}`)
      .then(({data}) => {
         if(data)
            dispatch(loadingManagers(city_id));
      });
}
export function getManager(id, initialize) {
   return (dispatch) => {
      API.get(`manager/view/${id}`)
         .then(({data}) => {
            initialize(data);
         });
   };
}
