import { commercialPropertyConstants } from "../../constants/commercialPropertyConstants";

const initialState = {
   commercials: [],
   currentCommercial: {},
   offices: [],
   currentOffice: {},
};

function isJson(str) {
   try {
      JSON.parse(str);
   } catch (e) {
      return false;
   }
   return true;
}

export const commercialPropertyReducer = (state = initialState, action) => {
   switch (action.type) {
      case commercialPropertyConstants.SET_COMMERCIAL_PROPERTY:
         return {
            ...state,
            commercials: action.payload,
         };
      case commercialPropertyConstants.SET_CURRENT_COMMERCIAL_PROPERTY: {
         return {
            ...state,
            currentCommercial: action.payload,
         };
      }
      case commercialPropertyConstants.SET_OFFICES_COMMERCIAL_PROPERTY: {
         return {
            ...state,
            offices: action.payload.map((item) => {
               const plan =
                  item.plan && isJson(item.plan) ? JSON.parse(item.plan) : [];
               return {
                  ...item,
                  plan,
               };
            }),
         };
      }
      case commercialPropertyConstants.SET_OFFICE_COMMERCIAL_PROPERTY: {
         return {
            ...state,
            offices: [...state.offices, action.payload],
         };
      }
      case commercialPropertyConstants.SET_CURRENT_OFFICE_COMMERCIAL_PROPERTY: {
         return {
            ...state,
            currentOffice: {
               ...action.payload,
               plan: JSON.parse(action.payload.plan),
            },
         };
      }
      case commercialPropertyConstants.UPDATE_OFFICE_COMMERCIAL_PROPERTY: {
         const updated = state.offices.map((item) =>
            item.id === action.payload.id
               ? { ...action.payload, plan: JSON.parse(action.payload.plan) }
               : item
         );
         return {
            ...state,
            offices: updated,
         };
      }
      case commercialPropertyConstants.REMOVE_OFFICE_COMMERCIAL_PROPERTY: {
         const updated = state.offices.filter(
            (item) => item.id !== action.payload
         );
         return {
            ...state,
            offices: updated,
         };
      }
      case commercialPropertyConstants.RESET_COOMERCIAL_PROPERTY: {
         return initialState;
      }
      default:
         return state;
   }
};
