import { Header } from "../Header/Header";
import React from "react";
import ApartForm from "./ApartForm";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch} from "react-redux";
import {createApart, updateApart} from "../../actions/apartsAction";

export const ApartContainer = () => {
   let match  = useRouteMatch();
   const { apart_id } = match.params;
   const dispatch = useDispatch();
   const history = useHistory();

   const onSubmit = async (values) => {
      let formData = new FormData();
          await Object.keys(values).map(key => {
             if(values[key] && typeof values[key] === 'object')
                formData.append(key,values[key].value);
             else if(values[key])
                formData.append(key,values[key]);
          });

      if(apart_id)
         dispatch(updateApart(apart_id,formData));
      else
         dispatch(createApart(formData));

      history.goBack();
   }

   return (
      <>
         <Header title="Добавить / редактировать квартиру" />
         <ApartForm onSubmit={onSubmit} />
      </>
   );
};
