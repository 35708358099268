import { Header } from "../Header/Header";
import { CanvasContainer } from "./CanvasContainer";
import React, {useEffect, useState} from "react";
import DescriptionForm from "./DescriptionForm";
import {FooterFloor} from "./Footer";
import {useHistory, useRouteMatch} from "react-router-dom";
import {addTplApart, updateTplApart} from "../../actions/floorActions";
import {useSelector} from "react-redux";

export const TplApartContainer = () => {
   const history = useHistory();
   let match  = useRouteMatch();
   const { floor_id, tpl_apart_id } = match.params;
   const [image2D, setImage2D] = useState(false);
   const [image3D, setImage3D] = useState(false);
   const [canvas, setCanvas] = useState(false);
   const [image, setImage] = useState(false);

   const floor  = useSelector(state => state.floor.data);

   useEffect(() => {
      if(floor.image)
         setImage(floor.image);
   },[floor]);

   const handleSaveCanvas = (data) => {
      setCanvas(data);
   }

   const handleSetImage = (data) => {
      if(data.name === "image2d")
         setImage2D(data.image);
      else
         setImage3D(data.image);
   }

   const handleDelete = ( { name } ) => {
      if(name === "image2d")
         setImage2D(false);
      else
         setImage3D(false);
   }

   const handleSave = async (values) => {
      const formData = new FormData();
      await Object.keys(values).map(key => {
         formData.append(key,values[key]);
      });
      formData.append("tplfloor_id",floor_id);
      formData.append("plan",JSON.stringify(canvas));

      formData.delete("image3d");
      formData.delete("image2d");

      if(image2D)
         formData.append("image2d", image2D);

      if(image3D)
         formData.append("image3d", image3D);

      if(tpl_apart_id)
         updateTplApart(tpl_apart_id, formData).then(() => {
            history.goBack();
         });
      else
         addTplApart(formData).then(() => {
            history.goBack();
         });
   }

   return (
      <>
         <Header title="Добавить / редактировать схему квартиры" />
         <DescriptionForm
            handleSetImage={handleSetImage}
            handleDelete={handleDelete}
            onSubmit={handleSave} />
         <CanvasContainer
            handleSave={handleSaveCanvas}
            file={image}
         />
         <FooterFloor />
      </>
   );
};
