import React, { useRef, useState, useEffect } from 'react';
import { Stage, Layer, Line } from 'react-konva';
import { BaseImage } from "../General/BaseImage";
import { useCheckSize } from "../General/useCheckSize";
import { getRelativePointerPosition } from "../General/getRelativePointerPosition";
import { ZoomStage } from "../General/ZoomStage";
import {CirclePoint} from "./CirclePoint";

let circleId = 1;

export const CanvasAddParkingPlace = ({ image, setNewPoint = () => {}, customPoints }) => {
   const [size, setSize] = useCheckSize(); // размеры
   const [scale, setScale] = useState(1); // масштаб
   const [points, setPoints] = useState([]);
   const [isDrawing, setDrawing] = useState(true);

   //closing drawing
   const handleClick = (id) => {
      if (id === 1) {
         circleId = 1;
         setDrawing(false)
      }
   };

   const handleDragMove = (x, y, id) => {
      const newPoints = points.map(p => {
         if (p.id === id) {
            return {
               id: p.id,
               x,
               y
            }
         }
         return p;
      });
      setPoints(newPoints);
      setNewPoint(newPoints);
   };

   useEffect(() => {
      if (customPoints?.length) {
         setPoints(customPoints);
         setNewPoint(customPoints);
      }
   }, [customPoints]);

   const stageRef = useRef();

   const zoomStage = (stage, scaleBy) => {
      ZoomStage(stage, scaleBy)
   };

   const addNewPoint = event => {
      const pointStart = getRelativePointerPosition(event.target.getStage());

      const point = {
         id: circleId++,
         x: pointStart.x,
         y: pointStart.y
      };

      setPoints([...points, point]);
      setNewPoint([...points, point]);
   };

   return (
      <>
         <div className="frame-canvas">
            <Stage
               ref={stageRef}
               width={size.width}
               height={size.height}
               scaleX={scale}
               scaleY={scale}
               className="canvas"
               draggable={true}
               onClick={addNewPoint}
            >
               <Layer>
                  <BaseImage photo={image} size={size} setScale={setScale} setSize={setSize} />
               </Layer>

               {points.length ?
                  <Layer>
                     <Line
                        points={points.flatMap(p => [p.x, p.y])}
                        stroke="red"
                        closed
                        fill="#e0f5c0"
                        opacity={0.5}
                        strokeWidth={3}
                     />

                     {points.map((point, index) => (
                        <CirclePoint
                           key={index}
                           point={point}
                           handleClick={handleClick}
                           handleDragMove={handleDragMove}
                           isDrawing={isDrawing}
                        />
                     ))}
                  </Layer>
                  : <></>
               }
            </Stage>

            <div className="zoom-container">
               <button type="button" onClick={() => zoomStage(stageRef.current, 1.2)}>
                  +
               </button>
               <button type="button" onClick={() => zoomStage(stageRef.current, 0.8)}>
                  -
               </button>
               <button type="button" onClick={() => { setPoints([]); setNewPoint([]) }}>
                  ⟲
               </button>
            </div>
         </div>
      </>
   )
};
