import API from "../utils/API";
import {requestConstants} from "../constants/requestConstants";

export const setQuestions = (data) => ({type: requestConstants.SET_ITEMS, payload: {data}});
export const receiveQuestions = (page = 0) => (dispatch) => {
   API.get(`request`, {
      params: {
         page: page,
      }
   })
      .then(async ({data}) => {
         dispatch(setQuestions({
            items: data.items,
            meta: data._meta
         }));
      });
}
export const updateRequest = (id, status) => {
   let form = new FormData();
   form.append("yesno", status);
   API.put(`request/update/${id}`,form);
}
export const deleteRequest = (id) => (dispatch) => {
   API.delete(`request/delete/${id}`)
      .then(({data}) => {
         if(data)
            dispatch(receiveQuestions())
      });
}
