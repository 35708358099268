import {newsConstants} from "../constants/newsConstants";
import API from "../utils/API";
import {dateFormat} from "../utils/helpers";
import {history} from "../utils/history";

export const setNews = (data) => ({type: newsConstants.SET_NEWS, payload: {data}});

export const handleError = () => ({type: newsConstants.SET_ERROR, payload: {errorMessage: 'Ошибка из сервера'}});
export const receiveNews = (params = {}) => (dispatch) => {
   API.get('news', {params})
      .then(({data}) => {
         const metaData = data._meta;
         data.items = data.items.map(item => {
            const newItem = {};
            newItem.id = item.id;
            item.date_create = dateFormat(item.date_create);
            newItem.title = item.name;
            newItem.order = 1;
            newItem.data = item.date_create;
            newItem.links = {delete: true, hide: true, edit: "/",};
            return newItem;
         });
         dispatch(
            setNews({
               items: data.items,
               metaData,
            })
         );
      }).catch(() => {
      dispatch(handleError());
   });
}
export const addNews = (formData) => (dispatch) => {
   const realFormData = new FormData();
   for (let key in formData) {
      if (key === 'images') {
         formData.images.map(val => {
            realFormData.append('images[]', val);
         })
      } else {
         realFormData.append(key, formData[key]);
      }
   }

   API.post(`news/create`, realFormData)
      .then(() => {
         history.push('/pages/news');
      });
}

export const deleteNews = (id) => (dispatch) => {
   API.delete(`news/delete/${id}`)
      .then(() => {
         dispatch(receiveNews());
      })
      .catch(() => {
         dispatch(handleError());
      });
}

const getElements = (formData, mediaCreate, realFormData) => {
   for (let key in formData) {
      if (key === 'images') {
         formData.images.filter(val => {
            if (!val.id) {
               mediaCreate.append('images[]', val);
            }
         })
      } else {
         realFormData.append(key, formData[key]);
      }
   }
}

export const updateNews = (id, formData, deleteIDs) => (dispatch) => {
   const realFormData = new FormData();
   const mediaCreate = new FormData();
   getElements(formData, mediaCreate, realFormData);
   API.post(`news/updateimage/${id}`, realFormData);
   mediaCreate.append('parent', 'news');
   mediaCreate.append('id', id);
   deleteIDs.forEach(id => {
      API.delete(`media/delete/${id}`);
   })

   if(formData.images && formData.images.length > 0)
   {
      formData.images.map(async (image, index) => {
         if(!image.id)
         {
            let imagesData = new FormData();
            imagesData.append('parent', 'news');
            imagesData.append('id_parent', id);
            imagesData.append('order', index.toString());
            imagesData.append('published', "1");
            imagesData.append('image', image);
            API.post(`media/create`, imagesData);
            await new Promise((res) => setTimeout(res, 500));
         }
      });
   }

   API.put(`news/update/${id}`, realFormData)
      .then(() => {
         history.push('/pages/news');
      })
      .catch(() => {
         dispatch(handleError());
      });
}

export const getCurrentNews = (id, initialize, setFiles, setMediaData) => (dispatch) => {
   API.get(`news/view/${id}`)
      .then(async ({data}) => {
         dispatch(setCurrentNews(data));
         let images = null;
         try {
            images = await (getImages(data.id));
         } catch (e) {

         }
         const initData = {
            date_create: +data.date_create,
            name: data.name,
            text: data.text,
            image: data.image ? `http://dc.websalamat.ru/img/${data.image}` : null,
         }
         images && setFiles(images.data.items);
         data.image && setMediaData(data.image);
         initialize(initData);
      });
}

export const getImages = (id) => {
   return API.get(`media?parent=news&id_parent=${id}`);
}

export const setCurrentNews = (currentNews) => ({type: newsConstants.SET_CURRENT_NEWS, payload: {currentNews}});
