import React from "react";
import Icon from "../../assets/img/logo.svg";

export const Preloader = () => {
   return (
      <div className="preloader">
         <div className="preloader__content">
            <div className="preloader__title">Загрузка...</div>
            <img src={Icon} alt="Домкор" />
         </div>
      </div>
   );
};
