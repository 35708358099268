import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Locked from "../../assets/img/icon/Locked.png";

function getModalStyle() {
   const top = 50;
   const left = 50;

   return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
   };
}

const useStyles = makeStyles((theme) => ({
   paper: {
      position: "absolute",
      width: 300,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: 20,
   },
}));

export const HideModal = ({ open, setOpen, text = 'Заблокировать?', onOk = void 0,}) => {

   const classes = useStyles();
   const [modalStyle] = React.useState(getModalStyle);

   const handleClose = () => {
      setOpen(false);
   };
   const onOkClose = () => {
      onOk && onOk();
      handleClose();
   }
   return (
      <div>
         <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            className="modal"
            onClose={handleClose}
         >
            <div style={modalStyle} className={classes.paper}>
               <img src={Locked} alt="" loading="lazy" aria-hidden={true} />
               <div className="modal__title" id="simple-modal-title">
                  {text}
               </div>
               <div className="flex">
                  <button
                     className="btn btn--radius btn--no-border item-indent"
                     onClick={handleClose}
                  >
                     Отмена
                  </button>
                  <button className="btn btn--green btn--no-border btn--radius" onClick={onOkClose}>
                     Да
                  </button>
               </div>
            </div>
         </Modal>
      </div>
   );
};
