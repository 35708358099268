import API from "./API";

export function FetchPut(url,params = null,method = 'PUT'){
   return new Promise(async (resolve, reject) => {
      await fetch(`http://dc.websalamat.ru/v1${url}`,
         {
            method: method,
            referrerPolicy: 'unsafe-url',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Bearer HA_6yYLXbCggRsUnMTWzEyLKO2LKBpfi',
            },
            body: params
         })
         .then((response) => {
            if (response.status >= 200 && response.status < 300) {
               return response;
            }
            throw new Error(response.statusText);
         })
         .then(response => response.json())
         .then(data => {
            resolve(data);
         })
         .catch(error => console.error(error));
   });
}

export async function addMedia(data){
   return new Promise(async (resolve, reject) => {
      let imageData = new FormData();
      imageData.append("parent", data.parent);
      imageData.append("id_parent", data.id);
      imageData.append("type", data.type);
      imageData.append("published", data.published);
      imageData.append("image", data.image);
      imageData.append("order", data.order);
      await API.post(`media/create`, imageData).then(res => resolve(res))
         .catch(err => reject(err));
   });
}
