import React from "react";
import { IconDelete } from "../Icons/IconDelete";

export const ButtonDelete = ({ onClick, ...rest }) => (
   <button
      className="btn-option btn-reset btn-del"
      title="Удалить"
      aria-label="Удалить"
      onClick={onClick}
      type="button"
   >
      <IconDelete {...rest} />
   </button>
);
