import React, {useEffect, useRef, useState} from 'react';
import {Stage, Layer} from 'react-konva';
import {BaseImage} from "../General/BaseImage";
import {useCheckSize} from "../General/useCheckSize";
import {getRelativePointerPosition} from "../General/getRelativePointerPosition";
import { Image} from "react-konva";

export const CanvasSVGPlaceMark = ({image, icon = false, savePoints, points }) => {
   const [size, setSize] = useCheckSize();
   const [scale, setScale] = useState(1);
   return (
      <>
         <div className="frame-canvas">
            <Stage
               width={size.width}
               height={size.height}
               scaleX={scale}
               scaleY={scale}
               className="canvas"
               draggable={false}
               onClick={e => {
                  const pointsCoors = getRelativePointerPosition(e.target.getStage());
                  savePoints(pointsCoors);
               }}
            >
               <Layer>
                  <BaseImage photo={image} size={size} setScale={setScale} setSize={setSize} />
                  {
                     (icon && points)
                     &&
                     <Image
                        draggable
                        onDragEnd={(e) => savePoints({x: e.target.attrs.x, y: e.target.attrs.y})}
                        x={points.x} y={points.y} image={icon} />
                  }
               </Layer>
            </Stage>
         </div>
      </>
   )
};
