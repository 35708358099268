import React, {useEffect, useState} from "react";
import { Field, reduxForm } from "redux-form";

import API from "../../utils/API";
import {LayoutFormBasis} from "../Fields/LayoutForm";
import {RenderInput} from "../Fields/RenderInput";
import {ButtonBack} from "../Buttons/ButtonBack";
import {ButtonSave} from "../Buttons/ButtonSave";

const Form = ({ handleSubmit, id, initialize}) => {

   const [item, setItem] = useState({
      title: ''
   });

   useEffect(() => {
      if(id)
         API.get(`settings/view/${id}`)
            .then(({data}) => {
               initialize({
                  value: data.value
               });
               setItem(data);
            })
   }, [id]);

   return (
      <form className="form form-feedback" onSubmit={handleSubmit}>
         <div className="form-feedback__head form__control form__border">
            <div className="form__item">
               <b>Название:</b> {item.title}, можно получить в личном кабинете
            </div>
         </div>
         <LayoutFormBasis title="Значение:" id="value">
            <div className="form__item form__item--min">
               <Field
                  id="value"
                  name="value"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>
         <div className="form__foot button-group flex">
            <ButtonBack to={`/profit-base`} />
            <ButtonSave />
         </div>
      </form>
   );
};

export default reduxForm({
   form: "profit-base-form",
})(Form);
