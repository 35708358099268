import React from "react";
import { Header } from "../../Header/Header";
import Form from "./Form";
import { createPage } from "../../../actions/pagesAction";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import translitRusEng from "translit-rus-eng";

export const CreateSectionPage = () => {
   let dispatch = useDispatch();
   const info = useSelector((state) => state.pageInfo);

   const handleSubmit = (values) => {
      values.short = translitRusEng(values.name, { slug: true });
      values.published = values.published.value;

      dispatch(createPage(values));
   };

   if (info.inProgress) {
      return <Redirect to={{ pathname: "/pages" }} />;
   }

   return (
      <>
         <Header title="Создание раздела" />

         <div className="card">
            <Form
               onSubmit={(values) => {
                  handleSubmit(values);
               }}
            />
         </div>
      </>
   );
};
