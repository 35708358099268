import React from "react";
import { Field, reduxForm } from "redux-form";
import { LayoutFormBasis } from "../../../../Fields/LayoutForm";
import { RenderInput } from "../../../../Fields/RenderInput";
import { RenderSelect } from "../../../../Fields/RenderSelect";
import { ButtonBack } from "../../../../Buttons/ButtonBack";
import { ButtonSave } from "../../../../Buttons/ButtonSave";

const FlatForm = ({ handleSubmit }) => {
   return (
      <form className="form" onSubmit={(e) => handleSubmit(e)}>
         <LayoutFormBasis title="id Квартиры" id="id">
            <div className="form__item form__item--min">
               <Field
                  id="id"
                  placeholder="id"
                  className="control"
                  name="id"
                  component={RenderInput}
                  type="number"
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="Квартира №" id="apartment-number">
            <div className="form__item form__item--min">
               <Field
                  id="apartment-number"
                  placeholder="Квартира №"
                  className="control"
                  name="apartment-number"
                  type="number"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="Подъезд" id="porch-group" label={false}>
            <div className="form__item form__item--min">
               <Field
                  id="porch"
                  placeholder="Выберите"
                  name="porch"
                  component={RenderSelect}
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis
            title="Шаблон этажа"
            id="floor-pattern-group"
            label={false}
         >
            <div className="form__item form__item--min">
               <Field
                  id="floor-pattern"
                  placeholder="Выберите"
                  name="floor-pattern"
                  component={RenderSelect}
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="Этаж" id="level-group" label={false}>
            <div className="form__item form__item--min">
               <Field
                  id="level"
                  placeholder="Выберите"
                  name="level"
                  component={RenderSelect}
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis
            id="room-group"
            title="Комната(квартира)"
            label={false}
         >
            <div className="form__item form__item--min">
               <Field
                  id="room"
                  placeholder="Выберите"
                  name="room"
                  component={RenderSelect}
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="Площадь, м²" id="square">
            <div className="form__item form__item--min">
               <Field
                  id="square"
                  placeholder="Площадь, м²"
                  className="control"
                  name="square"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="Цены, руб" id="price">
            <div className="form__item form__item--min">
               <Field
                  id="price"
                  placeholder="Цена"
                  className="control"
                  name="price"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="Статус" id="status">
            <div className="form__item form__item--min">
               <Field
                  id="status"
                  placeholder="статус"
                  className="control"
                  name="status"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>
         <div className="form__foot button-group flex">
            <ButtonBack />
            <ButtonSave />
         </div>
      </form>
   );
};

export default reduxForm({
   form: "flat-form",
   enableReinitialize: true,
})(FlatForm);
