import moment from "moment-timezone";
import "moment/locale/ru";

export const dateFormat = (UNIX_TIME, format = "YYYY-MM-DD") => {
   return moment.unix(UNIX_TIME).format(format);
}

const convertDate = d => d === 0 ? '01' : d < 10 ? `0${d}` : d;

export function toDateTime(UNIX_timestamp) {
   const date = new Date(UNIX_timestamp * 1000);
   return date.getFullYear() + '-' + convertDate(date.getMonth()) + '-' + convertDate(date.getDay());
}

export const fromDateTime = (date) => {
   return new Date(date).getTime() / 1000;
}

export const getStatus = (value) => {
   switch(value){
      case('1'):
         return 'Продан';
         break;
      case('2'):
         return 'В продаже';
         break;
      case('3'):
         return 'Забронирован';
         break;
      default:
         return 'Статус не корректен!';
         break;
   }
}

export const priceFormat = (input) => {
   if (!input) return;
   return input
      .replace(/ /g , '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
