import API from "../utils/API";
import { commercialPropertyConstants } from "../constants/commercialPropertyConstants";
import { history } from "../utils/history";

export const addCommercialProperty = (data) => (dispatch) => {
   API.post(`comrealty/create`, data).then(() => {
      history.push(`/commercial-property`);
      dispatch(receiveCommercialProperty());
   });
};

export const updateCommercialProperty = (formData, id) => (dispatch) => {
   API.put(`comrealty/update/${id}`, formData).then(async () => {
      dispatch(receiveCommercialProperty());
   });
};
export const setCommercialProperty = (commercialProps) => ({
   type: commercialPropertyConstants.SET_COMMERCIAL_PROPERTY,
   payload: commercialProps,
});

export const resetCommercialProperty = () => ({
   type: commercialPropertyConstants.RESET_COOMERCIAL_PROPERTY,
});

export const setCurrentCommercialProperty = (commercialProps) => ({
   type: commercialPropertyConstants.SET_CURRENT_COMMERCIAL_PROPERTY,
   payload: commercialProps,
});

export const receiveCommercialProperty = () => (dispatch) => {
   API.get(`comrealty`).then(async ({ data }) => {
      const transformedData = await data.items.map(async (val) => {
         let city = "Нету city";
         if (val["city_id"]) {
            await API.get(`city/${val["city_id"]}`).then(({ data }) => {
               city = data.name;
            });
         }
         return {
            id: val.id,
            name: val.name,
            address: val.address,
            city,
            links: {
               delete: true,
               hide: false,
               edit: "/edit-link",
            },
         };
      });
      Promise.allSettled(transformedData).then((res) => {
         const data = res.map((val) => {
            return val.value;
         });
         dispatch(setCommercialProperty(data));
      });
   });
};

export const handleCommercialPropertyDelete = (id) => (dispatch) => {
   API.delete(`comrealty/delete/${id}`).then(() => {
      dispatch(receiveCommercialProperty());
   });
};

export const receiveCurrentCommercialProperty = (id) => (dispatch) => {
   API.get(`comrealty/view/${id}`).then(({ data }) => {
      dispatch(setCurrentCommercialProperty(data));
   });
};

export const uploadGenPlanCommercialProperty = (formData) => {
   return API.post(`media/create`, formData).then(({ data }) => {
      return data.id;
   });
};

export const uploadImagePlanCommercialProperty = (formData, id) => {
   return API.post(`comrealty/updateimage/${id}`, formData).then(({ data }) => {
      return data.id;
   });
};

export const receiveGenPlanCommercialProperty = (id) => {
   return API.get(`media?parent=comrealty&id_parent=${id}&type=imageplan`).then(
      ({ data }) => {
         return `http://dc.websalamat.ru/img/${
            data.items[data.items.length - 1].image
         }`;
      }
   );
};

export const receiveMediaById = (id) => {
   return API.get(`media/${id}`).then(({ data }) => {
      return `http://dc.websalamat.ru/img/${data.image}`;
   });
};

// OFFICE
export const setOffice = (data) => ({
   type: commercialPropertyConstants.SET_OFFICE_COMMERCIAL_PROPERTY,
   payload: data,
});

export const setOffices = (data) => ({
   type: commercialPropertyConstants.SET_OFFICES_COMMERCIAL_PROPERTY,
   payload: data,
});

export const updateOffice = (data) => ({
   type: commercialPropertyConstants.UPDATE_OFFICE_COMMERCIAL_PROPERTY,
   payload: data,
});

export const setCurrentOffice = (data) => ({
   type: commercialPropertyConstants.SET_CURRENT_OFFICE_COMMERCIAL_PROPERTY,
   payload: data,
});

export const removeOffice = (id) => ({
   type: commercialPropertyConstants.REMOVE_OFFICE_COMMERCIAL_PROPERTY,
   payload: id,
});

export const receiveCreateOffice = (formData) => (dispatch) => {
   return API.post(`office/create`, formData).then(({ data }) =>
      dispatch(setOffice(data))
   );
};

export const receiveUpdateOffice = (id, formData) => (dispatch) => {
   return API.put(`office/update/${id}`, formData).then(({ data }) =>
      dispatch(updateOffice(data))
   );
};

export const receiveOfficeById = (id) => (dispatch) => {
   return API.get(`office/view/${id}`).then(({ data }) => {
      dispatch(setCurrentOffice(data));
   });
};

export const receiveOfficesByCommrealty = (id) => (dispatch) => {
   return API.get(`office/comrealty/${id}`).then(({ data }) => {
      dispatch(setOffices(data.items));
   });
};

export const receiveDeleteOfficeById = (id) => (dispatch) => {
   return API.delete(`office/delete/${id}`).then(() => {
      dispatch(removeOffice(id));
   });
};
