import React, { useState } from "react";

import { IconPlus } from "../Icons/IconPlus";
import { IconDelete } from "../Icons/IconDelete";

import { isEmpty } from "../../utils/utils";

export const RenderImage = ({
   input: { value, ...inputProps },
   setMediaData,
   handleDelete = false,
   ...props
}) => {
   const [file, setFile] = useState({ file: null });

   const handleChange = (e) => {
      const firstFile = e.target.files[0];
      const file = URL.createObjectURL(firstFile);
      setMediaData(firstFile);
      setFile({ file });
   };

   const actionDelete = () => {
      handleDelete(inputProps);
   };

   return (
      <div
         className={
            props.dop
               ? "preview preview--min flex flex--ac flex--jc-center"
               : "preview flex flex--ac flex--jc-center"
         }
      >
         {handleDelete && (
            <div className="delete--btn" onClick={() => actionDelete()}>
               <IconDelete />
            </div>
         )}
         {file.file === null &&
         ((!isEmpty(value) && value.includes("null")) || value === "") ? (
            <div className="preview__txt">
               <IconPlus />
            </div>
         ) : (
            <img src={file.file || value} alt="" className="preview__view" />
         )}
         <input
            {...inputProps}
            onChange={(e) => handleChange(e)}
            type="file"
            accept="image/*"
            {...props}
         />
      </div>
   );
};
