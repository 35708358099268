import React from "react";

export const IconPlus = ({ fill, stroke, opacity = 0.5 }) => (
   <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
   >
      <path
         opacity={opacity}
         d="M1 17H16.9497M16.9497 17H32.8995M16.9497 17V1.05026M16.9497 17V32.9497"
         stroke={stroke || "black"}
      />
   </svg>
);
