import React, {useState} from "react";
import {Header} from "../../Header/Header";
import Form from "../BasisInformation/Form";
import {useDispatch, useSelector} from "react-redux";
import { Redirect } from "react-router-dom";
import {createCity} from "../../../actions/cityAction";

export const CreateCity = () => {
   const dispatch = useDispatch();

   const [mediaData, setMediaData] = useState(null);
   const [redirect, setRedirect] = useState(false);

   const handleSubmit = (values) => {
      let formData = new FormData();

      Object.keys(values).map(key => {
         formData.append(key,values[key]);
      });

      formData.delete('qr');

      if(mediaData){
         formData.append('qr',mediaData);
      }

      dispatch(createCity(formData))
      setRedirect(true);
   };

   if(redirect)
      return <Redirect to="/pages/contacts" />;

   return (
      <>
         <Header title="Добавление города" />

         <Form onSubmit={(values) => {
            handleSubmit(values);
         }}
          setMediaData={setMediaData}
         />
      </>
   )
};
