import React from 'react';
import {Link} from 'react-router-dom';

export const Pagination = ({pageCount, currentPage, onSetPage, itemCount, totalCount}) => {
   if (pageCount <= 1) {
      return null;
   }

   const handleClick = (e, page) => {
      e.preventDefault();
      onSetPage(page)
   };

   const allowPrevious = currentPage !== 1;
   const allowNext = currentPage !== pageCount;
   const previousPage = currentPage - 1;
   const nextPage = currentPage + 1;

   let pages = [];

   let lowerLimit = currentPage;
   let upperLimit = currentPage;
   for (let i = 1; i < 3 && i < pageCount;) {
      if (lowerLimit > 1) {
         lowerLimit--;
         i++;
      }

      if (i < 3 && upperLimit < pageCount) {
         upperLimit++;
         i++;
      }
   }

   if (lowerLimit > 1) {
      pages.push(1);
      pages.push('...');
   }

   for (let i = lowerLimit; i <= upperLimit; i++) {
      pages.push(i);
   }

   if (currentPage < pageCount - 2) {
      pages.push('...');
      pages.push(pageCount);
   }

   return (
      <nav aria-label="Пагинация" className="pagination__wrap">
         <span className="pagination__count">{`Показано ${itemCount} из ${totalCount}`}</span>
         <ul className="pagination list-none">
            {allowPrevious &&
               <li className="pagination__item pagination__item--option pagination__item--prev">
                  <Link to="#" className="pagination__link"
                        onClick={(e) => handleClick(e, previousPage)}>
                           <svg width="17" viewBox="0 0 477.175 477.175">
                              <g>
                                 <path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225   c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"/>
                              </g>
                           </svg>
                  </Link>
               </li>
            }

            {pages.map((page, index) => {
               if (page === '...') {
                  return (
                     <li key={index} className='pagination__item'>
                        <span>...</span>
                     </li>
                  )
               } else {
                  return (
                     <li key={index}
                         className={currentPage === page ? 'pagination__item pagination__item--active' : 'pagination__item'}>
                        <Link to="#" className="pagination__link" onClick={(e) => handleClick(e, page)}>{page}</Link>
                     </li>
                  )
               }
            })}

            {allowNext &&
               <li className="pagination__item pagination__item--option pagination__item--next">
                  <Link to="#" className="pagination__link"
                        onClick={(e) => handleClick(e, nextPage)}>
                     <svg width="17" viewBox="0 0 477.175 477.175">
                        <g>
                           <path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5   c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z   "/>
                        </g>
                     </svg>
                  </Link>
               </li>
            }
         </ul>
      </nav>
   )
};
