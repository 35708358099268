import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { pagesReducer, pageInfo } from "./pages/pagesReducer";
import { authentication } from "./auth/authReducer";
import { cityReducer, cityInfoReducer } from "./city/cityReducer";
import {newsReducer} from "./news/newsReducer";
import promoReducer from "./promo/promoReducer";
import {complexReducer} from "./complex/complexReducer";
import feedbackReducer from "./feedback/feedbackReducer";
import {commercialPropertyReducer} from "./commercialProperty/commercialPropertyReducer";
import { redirectReducer } from "../actions/redirect";
import {workerReducer} from "./worker/workerReducer";
import {resellerReducer} from "./reseller/resellerReducer";
import {parkingReducer} from "./parking/parkingReducer";
import {floorReducer} from "./floor/floorReducer";
import { apartReducer } from './floor/apartReducer';
import {reviewReducer} from "./review/reviewReducer";
import {managerReducer} from "./managers/managerReducer";
import {noticeReducer} from "./notice/noticeReduxer";
import {requestReducer} from "./request/requestReducer";

export default combineReducers({
   form: formReducer,
   auth: authentication,
   city: cityReducer,
   cityInfo: cityInfoReducer,
   pages: pagesReducer,
   news: newsReducer,
   promo: promoReducer,
   complex: complexReducer,
   feedback: feedbackReducer,
   pageInfo,
   commercialProperty: commercialPropertyReducer,
   redirect: redirectReducer,
   worker: workerReducer,
   reseller: resellerReducer,
   parking: parkingReducer,
   floor: floorReducer,
   apart: apartReducer,
   review: reviewReducer,
   manager: managerReducer,
   notice: noticeReducer,
   request: requestReducer,
});
