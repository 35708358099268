import React, {useEffect} from "react";
import { Field, reduxForm } from "redux-form";
import { RenderInput } from "../../../Fields/RenderInput";
import { LayoutFormBasis } from "../../../Fields/LayoutForm";
import { ButtonBack } from "../../../Buttons/ButtonBack";
import { ButtonSave } from "../../../Buttons/ButtonSave";
import { RenderImage } from "../../../Fields/RenderImage";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch} from "react-redux";
import {receiveWorkerDetails} from "../../../../actions/workerActions";

const Form = ({ error, handleSubmit, initialize, setMediaData }) => {

   let { params } = useRouteMatch();
   let { employer_id } = params;

   let history = useHistory();
   const dispatch = useDispatch();

   useEffect(() => {
      if(employer_id)
         dispatch(receiveWorkerDetails(employer_id,initialize));
   },[employer_id]);

   return (
      <form className="form form--basis" onSubmit={(e) => handleSubmit(e)}>
         <LayoutFormBasis title="Фото сотрудника" id="photo">
            <div className="form__item form__item--min">
               <Field
                  name="photo"
                  id="photo"
                  dop="true"
                  component={RenderImage}
                  setMediaData={setMediaData}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="Должность:" id="position">
            <div className="form__item form__item--min">
               <Field
                  id="position"
                  name="position"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="ФИО:" id="name">
            <div className="form__item form__item--min">
               <Field
                  id="name"
                  name="name"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="Телефон:" id="phone">
            <div className="form__item form__item--min">
               <Field
                  id="phone"
                  name="phone"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="Email:" id="email">
            <div className="form__item form__item--min">
               <Field
                  id="email"
                  name="email"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>
         <div className="form__foot button-group flex">
            <ButtonBack onClick={() => history.goBack()} />
            <ButtonSave />
         </div>
         {error && <div className="block red-txt">>{error}</div>}
      </form>
   );
};

export const validate = values => {
   const errors = {};
   if(!values.name){
      errors.name = 'Поле обязательно для заполнения!';
   }
   return errors
};

export default reduxForm({
   form: "setting-employer",
   validate
})(Form);
