import React from 'react';
import {Label, Tag, Text} from "react-konva";

export const Title = ({position, handleDragMoveText, title, handleTextDblClick}) => {
   return (
      <Label
         x={position.x}
         y={position.y}
         draggable
         onDragMove={(e) => handleDragMoveText(e)}
      >
         <Tag
            fill='#f6b800'
            pointerWidth={5}
            pointerHeight={5}

         />
         <Text
            text={title}
            onDblClick={(e) => handleTextDblClick(e)}
            fill='#fff'
            fontSize={11}
            padding={7}
            fontStyle="bold"
         />
      </Label>
   )
};
