import React, {useEffect, useState} from "react";
import { Field, reduxForm } from "redux-form";
import { validate } from "../Fields/validate";
import { RenderInput } from "../Fields/RenderInput";
import { LayoutFormBasis } from "../Fields/LayoutForm";
import {useDispatch, useSelector} from "react-redux";
import {getEntrances, receiveApart, getTplFloors, getTplApartByFloorId} from "../../actions/apartsAction";
import {useHistory, useRouteMatch} from "react-router-dom";
import {RenderSelect} from "../Fields/RenderSelect";
import {ButtonBack} from "../Buttons/ButtonBack";
import {ButtonSave} from "../Buttons/ButtonSave";
import { statuses } from '../../constants/formConstants'
import {priceFormat, priceNormalize} from "../../utils/helpers";

const floors = []
for (let i = 0; i < 25; i++) {
   floors[i] = {
      value: i + 1,
      label: i + 1
   }
}

const ApartForm = ({ initialize, handleSubmit }) => {
   const history = useHistory();
   const dispatch = useDispatch();
   let match  = useRouteMatch();

   const [activeEntranceId, setEntrance] = useState(false);
   const [activeFloorId, setFloor] = useState(false);

   const { home_id, apart_id } = match.params;
   const { apartInfo, entrances, tplFloors, tplAparts } = useSelector(state => state.apart);

   useEffect(() => {
      if(apart_id)
         dispatch(receiveApart(apart_id,initialize));
   },[apart_id]);

   useEffect(() => {
      if(home_id)
         dispatch(getEntrances(home_id));
   },[home_id]);

   useEffect(() => {
      if(entrances.length > 0 && !activeEntranceId)
         setEntrance(entrances[0].value);

      if(entrances.length > 0 && activeEntranceId)
         dispatch(getTplFloors(activeEntranceId));
   },[activeEntranceId,entrances]);

   useEffect(() => {
      if(tplFloors.length > 0 && !activeFloorId)
         setFloor(tplFloors[0].value);

      if(activeFloorId)
         dispatch(getTplApartByFloorId(activeFloorId));
   },[activeFloorId,tplFloors]);

   return (
      <form className="form form--description formFlat" onSubmit={handleSubmit}>
         <LayoutFormBasis title="Id квартиры" id="floor">
            <div className="form__item">
               <label>
                  <Field
                     className="control"
                     name="id_1s"
                     placeholder="1234567"
                     component={RenderInput}
                     required
                  />
               </label>
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="Квартира №" id="floor">
            <div className="form__item">
               <label>
                  <Field
                     className="control"
                     name="name"
                     placeholder="13"
                     component={RenderInput}
                     required
                  />
               </label>
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="Подъезд" id="entrance" label={false}>
               <div className="form__group-inline form--basis-flex form__group-bottom flex">
                  <div className="form__item">
                     <Field
                        id="entrance"
                        name="entrance"
                        placeholder="Выберите"
                        component={RenderSelect}
                        options={entrances}
                        onChange={(e) => setEntrance(e.value || false)}
                     />
                  </div>
                  <div className="form__item">
                     <div className="top-text">
                        Шаблон этажа
                     </div>
                     <Field
                        id="tpl_floor"
                        name="tpl_floor"
                        placeholder="Выберите"
                        component={RenderSelect}
                        options={tplFloors}
                        onChange={(e) => setFloor(e.value || false)}
                     />
                  </div>
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="№ строительной" id="tplapart_id">
            <div className="form--basis-flex">
               <div className="form__item">
                  <Field
                     id="tplapart_id"
                     name="tplapart_id"
                     placeholder="Выберите"
                     component={RenderSelect}
                     options={tplAparts}
                  />
               </div>
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="Этаж" id="floor">
            <div className="form--basis-flex">
               <div className="form__item">
                  <Field
                     id="floor"
                     name="floor"
                     placeholder="Выберите"
                     options={floors}
                     component={RenderSelect}
                  />
               </div>
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="Кол-во комнат" id="rooms">
            <div className="form__item">
               <label>
                  <Field
                     className="control"
                     name="rooms"
                     placeholder="5"
                     component={RenderInput}
                  />
               </label>
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="Площадь кв.м" id="square" >
            <div className="form__item form__item-mr">
               <Field
                  className="control"
                  id="square"
                  name="square"
                  placeholder="10"
                  component="input"
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="Цена руб." id="price">
            <div className="form__item form__item-mr">
               <Field
                  className="control"
                  id="price"
                  name="price"
                  placeholder="1 000 000"
                  component="input"
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="Статус" id="status">
            <div className="form--basis-flex">
               <div className="form__item">
                  <Field
                     id="status"
                     name="status"
                     placeholder="Выберите"
                     component={RenderSelect}
                     options={statuses || []}
                  />
               </div>
            </div>
         </LayoutFormBasis>
         <div className="form__foot button-group flex">
            <ButtonBack onClick={() => history.goBack()}  />
            <ButtonSave />
         </div>
      </form>
   );
};

export default reduxForm({
   form: "apart-form",
   validate,
})(ApartForm);
