import React, {useState} from 'react';
import Form from "./Form";
import {publishedOptions} from "../../Fields/options";
import { Redirect } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {updateCity} from "../../../actions/cityAction";

export const BasisInformation = ({ info, id }) => {

   const [mediaData, setMediaData] = useState(null);
   const [redirect, setRedirect] = useState(false);

   const dispatch = useDispatch();

   const handleSubmit =  (values) => {
      let formData = new FormData();
       Object.keys(values).map(key => {
         if(key === 'published')
         {
            formData.append(key,values[key][0].value);
         } else {
            formData.append(key,values[key]);
         }
      });

      formData.delete('qr');

      if(mediaData){
         formData.append('qr',mediaData);
      }
      dispatch(updateCity(id,formData));
      setRedirect(true);
   };

   if(redirect)
      return <Redirect to="/pages/contacts" />;

   console.log(info.data);

   return (
      <Form
         initialValues={{
            ...info.data,
            qr: info.data.qr && `http://dc.websalamat.ru/img/${info.data.qr}`,
            published: publishedOptions.filter((item) => {
               return item.value === info.data.published
            })
         }}
         onSubmit={(values) => {
            handleSubmit(values);
         }}
         setMediaData={setMediaData}
      />
   )
};
