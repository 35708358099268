import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useRouteMatch} from "react-router-dom";
import {Header} from "../../Header/Header";
import { Preloader } from "../../Preloader/Preloader";
import {deleteCity, receiveCities} from "../../../actions/cityAction";
import { CitesList } from "./CitiesList";

export const CitiesListContainer = () => {
   let { url } = useRouteMatch();
   let dispatch = useDispatch();

   const city = useSelector(state => state.city);

   useEffect(() => {
      dispatch(receiveCities())
   }, [dispatch]);

   const handleDelete = (id) => {
      dispatch(deleteCity(id))
   };

   return (
      city.isFetching || city.data > 0 ? (
         <Preloader />
         ) : (
            <>
               <Header title="Контакты" />

               <div className="card card--width">
                  <ul className="list-none list-sub">
                     <CitesList data={city.data} url={url} handleDelete={handleDelete} />
                  </ul>
               </div>
            </>
         )
   )
};
