import React, {useEffect, useState} from "react";
import DesignForm from "./DesignForm";
import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from "react-router-dom";
import {updateDesign} from "../../../../actions/complexActions";

export const Design = ({ setActiveTab }) => {
   const [files, setFiles] = useState([]);
   const [deletedIds, setDeletedIds] = useState([]);
   const dispatch = useDispatch();
   const match  = useRouteMatch();
   const homeId = match.params.home_id;
   const house = useSelector(state => state.complex.house);

   const setDeleteIDs = async (deleteId) => {
      setDeletedIds([...deletedIds, deleteId]);
   }

   const handleSubmit = async (values) => {
      values.deletedIds = deletedIds;
      const result = await dispatch(updateDesign(homeId, files, values));
      if (result.new_images) {
         setFiles(files => {
            let newFiles = [...files];
            newFiles.forEach((file, key) => {
               if (result.new_images[key]) {
                  file.id = result.new_images[key];
               }
            });
            return newFiles;
         })
      }
      setDeletedIds([]);
      setActiveTab(0);
      await new Promise((res) => setTimeout(res, 1000));
      window.location.reload();
   };

   /*useEffect(
      () => () => {
         files.forEach((file) => URL.revokeObjectURL(file.preview));
      },
      [files]
   );*/

   useEffect(() => {
      if(house.imagesDesign)
         setFiles(house.imagesDesign);
   },[house]);

   return (
      <DesignForm
         setDeleteIDs={setDeleteIDs}
         handleSetFiles={setFiles}
         files={files}
         onSubmit={(values) => {
            handleSubmit(values);
         }}
      />
   );
};
