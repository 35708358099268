import API from '../utils/API';
import { parkingConstants } from "../constants/parkingConstants";

export const resetParkingLevelsList = () => ({ type: parkingConstants.RESET_PARKING_LEVELS });
export const resetParkingLevelObject = () => ({ type: parkingConstants.RESET_PARKING_LEVEL });
export const resetParkingPlacesList = () => ({ type: parkingConstants.RESET_PARKING_PLACES });
export const resetParkingPlaceObject = () => ({ type: parkingConstants.RESET_PARKING_PLACE });

export const resetParkingLevels = () => (dispatch) => {
  dispatch(resetParkingLevelsList())
};
export const resetParkingLevel = () => (dispatch) => {
  dispatch(resetParkingLevelObject())
};
export const resetParkingPlaces = () => (dispatch) => {
  dispatch(resetParkingPlacesList())
};
export const resetParkingPlace = () => (dispatch) => {
  dispatch(resetParkingPlaceObject())
};

export const setParkingList = (data) => ({type: parkingConstants.SET_PARKING_LIST, payload: {data}});
export const setParking = (data) => ({type: parkingConstants.GET_PARKING_BY_ID, payload: {data}});
export const deleteParkingObject = (data) => ({type: parkingConstants.DELETE_PARKING, payload: {data}});

export const setParkingLevelList = (data) => ({type: parkingConstants.SET_PARKING_LEVELS_LIST, payload: {data}});
export const crateParkingLevel = (data) => ({type: parkingConstants.CREATE_PARKING_LEVEL, payload: {data}});
export const setParkingLevel = (data) => ({type: parkingConstants.GET_PARKING_LEVEL_BY_ID, payload: {data}});
export const updateParkingLevel = (data) => ({type: parkingConstants.UPDATE_PARKING_LEVEL, payload: {data}});
export const deleteParkingLevelObject = (data) => ({type: parkingConstants.DELETE_PARKING_LEVEL, payload: {data}});

export const setParkingPlacesList = (data) => ({type: parkingConstants.SET_PARKING_PLACES_LIST, payload: {data}});
export const setParkingPlace = (data) => ({type: parkingConstants.GET_PARKING_PLACE_BY_ID, payload: {data}});
export const deleteParkingPlaceObject = (data) => ({type: parkingConstants.DELETE_PARKING_PLACE, payload: {data}});

export const receiveParking = () => (dispatch) => {
   API.get('parking')
      .then(data => {
         const { data: itemData } = data;

         dispatch(setParkingList(itemData.items));
      })
      .catch((error) => {
         console.warn(error);
      });
};

export const createParkingObject = (data, callBack) => (dispatch) => {
   API.post('parking/create', data)
      .then(response => {
         const { data: result } = response;

         if (result !== false) {
            callBack(result);
         } else {
            throw result;
         }
      })
      .catch((error) => {
         console.warn(error);
      });
};

export const getParkingById = (id) => (dispatch) => {
   API.get(`/parking/view/${id}`)
      .then(data => {
         const { data: itemData } = data;

         dispatch(setParking(itemData));
      })
      .catch((error) => {
         console.warn(error);
      });
};

export const updateParkingImageObject = (data, callBack, id) => (dispatch) => {
   API.post(`parking/updateimage/${id}`, data)
      .then(response => {
         const { data: result } = response;
         if (result !== false) {
            callBack();
         } else {
            throw result;
         }
      })
      .catch((error) => {
         console.warn(error);
      });
};

export const updateParkingObject = (data, callBack, id, newImage) => (dispatch) => {
   API.put(`parking/update/${id}`, data)
      .then(response => {
         const { data: result } = response;

         if (result !== false) {
            if (newImage) {
               dispatch(updateParkingImageObject(newImage, callBack, id))
            } else {
               callBack();
            }
         } else {
            throw result;
         }
      })
      .catch((error) => {
         console.warn(error);
      });
};

export const deleteParking = (id) => (dispatch) => {
   API.delete(`/parking/delete/${id}`)
      .then(data => {
         const { data: result } = data;

         dispatch(deleteParkingObject(id));
      })
      .catch((error) => {
         console.warn(error);
      });
};

export const receiveParkingLevels = id => (dispatch) => {
   API.get(`parkinglvl/parking/${id}`)
      .then(data => {
         const { data: itemData } = data;

         dispatch(setParkingLevelList(itemData.items));
      })
      .catch((error) => {
         console.warn(error);
         dispatch(resetParkingLevels());
      });
};

export const createParkingLevelObject = (data, callBack, name) => (dispatch) => {
   API.post('parkinglvl/create', data)
      .then(response => {
         const { data: result } = response;

         if (result !== false) {
            dispatch(crateParkingLevel({...result, name}));
            callBack();
         } else {
            throw result;
         }
      })
      .catch((error) => {
         console.warn(error);
      });
};

export const getParkingLevelById = (id) => (dispatch) => {
   API.get(`/parkinglvl/view/${id}`)
      .then(data => {
         const { data: itemData } = data;

         dispatch(setParkingLevel(itemData));
      })
      .catch((error) => {
         console.warn(error);
      });
};

export const updateParkingLevelImageObject = (data, callBack, id) => (dispatch) => {
   API.post(`parkinglvl/updateimage/${id}`, data)
      .then(response => {
         const { data: result } = response;

         if (result !== false) {
            callBack();
         } else {
            throw result;
         }
      })
      .catch((error) => {
         console.warn(error);
      });
};

export const updateParkingLevelObject = (data, callBack, name, id, newImage) => (dispatch) => {
   API.put(`parkinglvl/update/${id}`, data)
      .then(response => {
         const { data: result } = response;

         if (result !== false) {
            dispatch(updateParkingLevel({id, name}));
            if (newImage) {
               dispatch(updateParkingLevelImageObject(newImage, callBack, id))
            } else {
               callBack();
            }
         } else {
            throw result;
         }
      })
      .catch((error) => {
         console.warn(error);
      });
};

export const deleteParkingLevel = (id) => (dispatch) => {
   API.delete(`/parkinglvl/delete/${id}`)
      .then(data => {
         const { data: result } = data;

         dispatch(deleteParkingLevelObject(id));
      })
      .catch((error) => {
         console.warn(error);
      });
};

export const receiveParkingPlaces = levelId => (dispatch) => {
   API.get(`parkingplace/parkinglvl/${levelId}`)
      .then(data => {
         const { data: itemData } = data;

         dispatch(setParkingPlacesList(itemData.items));
      })
      .catch((error) => {
         console.warn(error);
         dispatch(resetParkingPlaces());
      });
};

export const createParkingPlaceObject = (data, callBack) => (dispatch) => {
   API.post('parkingplace/create', data)
      .then(response => {
         const { data: result } = response;

         if (result !== false) {
            callBack();
         } else {
            throw result;
         }
      })
      .catch((error) => {
         console.warn(error);
      });
};

export const getParkingPlaceById = (id) => (dispatch) => {
   API.get(`/parkingplace/view/${id}`)
      .then(data => {
         const { data: itemData } = data;

         dispatch(setParkingPlace(itemData));
      })
      .catch((error) => {
         console.warn(error);
      });
};

export const updateParkingPlaceObject = (data, callBack, id) => (dispatch) => {
   API.put(`parkingplace/update/${id}`, data)
      .then(response => {
         const { data: result } = response;

         if (result !== false) {
            callBack();
         } else {
            throw result;
         }
      })
      .catch((error) => {
         console.warn(error);
      });
};

export const deleteParkingPlace = (id) => (dispatch) => {
   API.delete(`/parkingplace/delete/${id}`)
      .then(data => {
         dispatch(deleteParkingPlaceObject(id));
      })
      .catch((error) => {
         console.warn(error);
      });
};
