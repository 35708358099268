import React, {useEffect, useState} from "react";
import { CanvasEntrance } from "../../../../Canvas/CanvasCreate/CanvasEntrance";
import { CanvasUpdate } from "../../../../Canvas/CanvasCreate/CanvasUpdate";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setHouse} from "../../../../../actions/complexActions";
import {LayoutFormBasis} from "../../../../Fields/LayoutForm";

export const CreatePorch = () => {
   let match  = useRouteMatch();
   const history = useHistory();
   const dispatch = useDispatch();
   const { entrance_id } = match.params;
   const { house } = useSelector(state => state.complex);

   const [entrance, setEntrance] = useState(false);
   const [floors, setFloors] = useState("");

   const handleSave = (data) => {
      dispatch(setHouse({
         ...house,
         entrances: house.entrances.map((item, index) =>
            item.id == entrance_id
               ?
                  {
                     ...item,
                     floors: floors,
                     plan: JSON.stringify(data)
                  }
               :
               {
                  ...item,
                  floors: floors,
               }
         )
      }));
      history.goBack();
   }

   useEffect(() => {
      if(entrance_id)
         setEntrance(
            house.entrances.filter(item => item.id == entrance_id)[0]
         );
   },[entrance_id]);

   useEffect(() => {
      if(entrance.floors)
         setFloors(entrance.floors);
   },[entrance]);

   const toggleFloors = (e) => {
      setFloors(e.currentTarget.value);
   }

   return (
      <div className="card">

         <LayoutFormBasis title="" id="floors">
            <div className="form__border flex">
               <label htmlFor="name"
                      style={{
                         marginRight: 15
                      }}
                      className="form__title form__s-title">Этажность</label>
               <div className="form__item form__item--min">
                  <input type="text" id="floors"
                         onChange={toggleFloors}
                         className="control control--w"
                         placeholder="19" required="" name="floors"
                         value={floors} />
                  </div>
            </div>
         </LayoutFormBasis>

         {
            entrance_id
            ?
               <CanvasUpdate
                  handleSave={handleSave}
                  image={house.imageplan || false}
                  pointerObject={entrance.plan ? JSON.parse(entrance.plan) : false}
               />
               :
               <CanvasEntrance floors={floors} />
         }
      </div>
   );
};
