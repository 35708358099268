import {newsConstants} from "../../constants/newsConstants";

const initialState = {
   data: [],
   hasError: false,
   currentNews: null,
}

export const newsReducer = (state = initialState, action) => {
   switch (action.type) {
      case newsConstants.SET_NEWS: {
         const {data} = action.payload;
         return {
            ...state,
            data,
         }
      }
      case newsConstants.SET_ERROR: {
         return {
            ...state,
            hasError: action.payload.errorMessage,
         }
      }
      case newsConstants.SET_CURRENT_NEWS: {
         return {
            ...state,
            currentNews: action.payload.currentNews
         }
      }
      default:
         return state;
   }
}
