import React, {useState} from "react";
import Form from "./Form";
import {Redirect} from "react-router-dom";
import API from "../../utils/API";

export const CreateUser = () => {
   const [redirect, setRedirect] = useState(null);

   const handleSubmit = (formData) => {
      formData.type = 'hirer';
       API.post(`user/create`, formData).then(res => {
          if(res)
             setRedirect(true);
       }).catch(err => console.log(err));
   }

   if(redirect)
      return <Redirect to="/users" />;

   return (
      <div className="card">
         <Form
            onSubmit={handleSubmit}
         />
      </div>
   );
};
