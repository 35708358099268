import React, {useEffect} from "react";
import { Header } from "../Header/Header";
import {useDispatch, useSelector} from "react-redux";
import {deleteRequest, receiveQuestions} from "../../actions/requestActions";
import {Pagination} from "../Pagination/Pagination";
import {RequestCard} from "./RequestOneCard";

export const SuggestionContainer = () => {

   const { items, meta } = useSelector(state => state.request);
   const dispatch = useDispatch();

   const getRequest = (page) => {
      dispatch(receiveQuestions(page));
   }

   const deleteItem = (id) => {
      dispatch(deleteRequest(id))
   }

   useEffect(() => {
      dispatch(receiveQuestions());
   },[]);

   return (
      <>
         <Header title="Предложения" />
         {
            items.length > 0 &&
               items.map((item, i) => <RequestCard deleteItem={deleteItem} key={i} props={item} />)
         }
         {
            meta &&
            <Pagination
               onSetPage={getRequest}
               pageCount={meta.pageCount}
               currentPage={meta.currentPage}
               itemCount={items.length}
               totalCount={meta.totalCount}
            />
         }
      </>
   );
};
