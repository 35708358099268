import React, {useEffect} from "react";
import { NavLink } from "react-router-dom";
import {useSelector} from "react-redux";

export const MainMenu = ({ handleLogOut }) => {
   const { questions, request, reviews } = useSelector(state => state.notice);

   return (
      <nav className="main-nav" aria-label="Основное меню">
         <ul className="list-none menu">
            <li className="menu__item">
               <NavLink
                  to="/residential-complex"
                  className="menu__link menu__link--green"
                  activeClassName="active"
               >
                  Новостройки
               </NavLink>
            </li>
            <li className="menu__item">
               <NavLink
                  to="/resellers"
                  className="menu__link menu__link--green"
                  activeClassName="active"
               >
                  Вторичное жилье
               </NavLink>
            </li>
            <li className="menu__item">
               <NavLink
                  to="/commercial-property"
                  className="menu__link menu__link--green"
                  activeClassName="active"
               >
                  Коммерческая недвижимость
               </NavLink>
            </li>
            <li className="menu__item">
               <NavLink
                  to="/parking"
                  className="menu__link menu__link--green"
                  activeClassName="active"
               >
                  Паркинги
               </NavLink>
            </li>
            <li className="menu__item">
               <NavLink
                  to="/applications-residential-complex"
                  className="menu__link menu__link--orange"
                  activeClassName="active"
               >
                  Новостройки: бронь, запрос цены
               </NavLink>
            </li>
            <li className="menu__item">
               <NavLink
                  to="/house-callback"
                  className="menu__link menu__link--orange"
                  activeClassName="active"
               >
                  Новостройки Экскурсия
               </NavLink>
            </li>
            <li className="menu__item">
               <NavLink
                  to="/office-callback"
                  className="menu__link menu__link--orange"
                  activeClassName="active"
               >
                  Офисы бронь
               </NavLink>
            </li>
            <li className="menu__item">
               <NavLink
                  to="/applications-resale"
                  className="menu__link menu__link--orange"
                  activeClassName="active"
               >
                  Вторичка: обратный звонок, заявка на просмотр
               </NavLink>
            </li>
            <li className="menu__item">
               <NavLink
                  to="/applications-parking"
                  className="menu__link menu__link--orange"
                  activeClassName="active"
               >
                  Паркинги бронь
               </NavLink>
            </li>
            <li className="menu__item">
               <NavLink
                  to="/callbacks"
                  className="menu__link menu__link--orange"
                  activeClassName="active"
               >
                  Запрос обратного звонка
               </NavLink>
            </li>
            <li className="menu__item">
               <NavLink
                  to="/suggestion"
                  className="menu__link menu__link--orange"
                  activeClassName="active"
               >
                  Предложения
                  {
                     request > 0 &&
                     <span className="count">+{request}</span>
                  }
               </NavLink>
            </li>
            <li className="menu__item">
               <NavLink
                  to="/feedback"
                  className="menu__link menu__link--orange"
                  activeClassName="active"
               >
                  Отзывы
                  {
                     reviews > 0 &&
                     <span className="count">+{reviews}</span>
                  }
               </NavLink>
            </li>
            <li className="menu__item">
               <NavLink
                  to="/questions"
                  className="menu__link menu__link--orange"
                  activeClassName="active"
               >
                  Вопрос - ответ
                  {
                     questions > 0 &&
                        <span className="count">+{questions}</span>
                  }
               </NavLink>
            </li>
            <li className="menu__item">
               <NavLink
                  to="/pages"
                  className="menu__link"
                  activeClassName="active"
               >
                  Страницы
               </NavLink>
            </li>
            <li className="menu__item">
               <NavLink
                  to="/settings"
                  className="menu__link"
                  activeClassName="active"
               >
                  Настройки
               </NavLink>
            </li>
            <li className="menu__item">
               <NavLink
                  to="/users"
                  className="menu__link"
                  activeClassName="active"
               >
                  Пользователи
               </NavLink>
            </li>
            <li className="menu__item">
               <NavLink
                  to="/profit-base"
                  className="menu__link"
                  activeClassName="active"
               >
                  ProfitBase
               </NavLink>
            </li>
            <li className="menu__item">
               <button className="menu__link menu__link--btn" onClick={() => handleLogOut()}>
                  Выйти
               </button>
            </li>
         </ul>
      </nav>
   );
};
