import React, {useEffect, useState} from "react";
import { Header } from "../Header/Header";
import { TableList } from "../TableList/TableList";
import API from "../../utils/API";
import {dateFormat} from "../../utils/helpers";
import {Pagination} from "../Pagination/Pagination";

export const ApplicationRCContainer = () => {
   const [list, setList] = useState({
      items: []
   });

   const getList = (page = 1) => {
      API.get(`requestapart`, {params: {page}})
         .then(({data}) => {
            let dataList = data.items.map(item => ({
               id: item.id,
               type: item.type,
               name: item.name + ',' + item.phone,
               city: item.city,
               complex: item.complex,
               house: item.house,
               entrance: item.entrance,
               floor: item.floor,
               apart: item.apart_num,
               date: dateFormat(item.date_create),
               links: {
                  delete: true,
               },
            }));

            setList({
               meta: data._meta,
               items: dataList
            });
         });
   }

   const handleDelete = (e) => (dispatch) => {
      API.delete(`requestapart/delete/${e}`)
         .then((r) => {
            if(r)
               getList();
         });
   }

   useEffect(() => {
      getList();
   },[]);

   let head = [
      "id",
      "Тип заявки",
      "Имя, Тел",
      "Город",
      "ЖК",
      "Дом №",
      "Подь-зд",
      "Этаж",
      "Кв",
      "Дата заявки",
      "Ссылки",
   ];

   return (
      <>
         <Header title="Новостройки: бронь, экскурсия, запрос цены" />
         <div className="card">
            <TableList handleDelete={handleDelete} head={head} list={list.items} />
            {
               list.meta &&
               <Pagination
                  onSetPage={getList}
                  pageCount={list.meta.pageCount}
                  currentPage={list.meta.currentPage}
                  itemCount={list.items.length}
                  totalCount={list.meta.totalCount}
               />
            }
         </div>
      </>
   );
};
