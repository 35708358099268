import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Login} from "../../actions/adminAction";
import { validate } from "../Fields/validate";

import Logo from "../../assets/img/logo-login.jpg";
import { RenderInput } from "../Fields/RenderInput";


const Form = ({ handleSubmit, error }) => {
   return (
      <form className="form form-auth" onSubmit={handleSubmit}>
         <div className="form__head">
            <img src={Logo} alt="Домкор" />
         </div>
         <div className="form__body">
            <div className="form__item">
               <label htmlFor="email" className="visually-hidden">
                  Введите email
               </label>
               <Field
                  className="control control--s"
                  name="email"
                  type="text"
                  component={RenderInput}
                  placeholder="Введите email"
               />
            </div>
            <div className="form__item">
               <label htmlFor="password" className="visually-hidden">
                  Введите пароль
               </label>
               <Field
                  className="control control--s"
                  name="password"
                  type="password"
                  component={RenderInput}
                  placeholder="Введите Пароль"
               />
            </div>


            {error && <span className="block red-txt">{error}</span>}

            <button type="submit" className="btn btn--green btn--no-border btn--max">
               ВОЙТИ
            </button>
         </div>
      </form>
   );
};

export default connect(null, {
   onSubmit: values => {
      return Login(values)
   }
})(reduxForm({
   form: 'auth',
   validate,
})(Form))
