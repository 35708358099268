import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import HomeIcon from "../../../assets/img/icon/home.png";
import UploadPlan from "../../UploadPlan/UploadPlan";
import { CanvasPreview } from "../../Canvas/CancasPreview/CancasPreview";
import { RightList } from "../RightList/RightList";
import {useSelector} from "react-redux";

export const GeneralPlan = ({ url, handleSubmit, genPlanMedia }) => {

   const complexForm = useSelector(state => state.form['info-building']);
   const [imageGenPlan,setImageGenPlan] = useState(false);

   const complex = useSelector(state => state.complex.complexDetail);

   useEffect(() => {
      if(genPlanMedia)
         setImageGenPlan(URL.createObjectURL(genPlanMedia))
   },[complexForm,genPlanMedia]);

   return (
      <>
         <div className="ling-group ling-group--mb">
            <Link
               to={`${url}/build/create`}
               className="flex flex--ac btn btn--mw btn--green item-indent"
            >
               <img
                  src={HomeIcon}
                  alt=""
                  aria-hidden="true"
                  className="item-indent"
               />
               Добавить дом
            </Link>
            <Link
               to={`${url}/social-create`}
               className="flex flex--ac btn btn--mw btn--green item-indent"
            >
               <img
                  src={HomeIcon}
                  alt=""
                  aria-hidden="true"
                  className="item-indent"
               />
               Добавить соц объекты
            </Link>
            <UploadPlan
               onSubmit={(values) => {
                  handleSubmit(values);
               }}
            />
         </div>
         <div className="flex flex--sb" style={{ width: "100%"}}>
            {
               (imageGenPlan || complex.photoGenPlan)
                  &&
               <CanvasPreview
                  updateByIndexItem={true}
                  customUrl={`${url}/update`}
                  complexInfo={ complex }
                  image={ imageGenPlan || complex.photoGenPlan }
               />
            }
            <RightList urlForName={{start: `${url}/build/`, end: '/settings'}} url={`${url}`} />
         </div>
      </>
   );
};
