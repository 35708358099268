import React, {useEffect, useState} from "react";
import { Field, reduxForm, getFormValues } from "redux-form";
import {useSelector} from "react-redux";
import { LayoutFormBasis } from "../../../Fields/LayoutForm";
import { RenderInput } from "../../../Fields/RenderInput";
import { RenderDropzoneInput } from "../../../Fields/RenderDropzoneInput";
import { WYSIWYGEditor } from "../../../Fields/WYSIWYGEditor";
import { RenderSelect } from "../../../Fields/RenderSelect";
import { ButtonBack } from "../../../Buttons/ButtonBack";
import { ButtonSave } from "../../../Buttons/ButtonSave";
import { Land } from "./Land";
import { Apartment } from "./Apartment";
import { Cottage } from "./Cottage";
import districsToCities from '../../../../constants/districts';

export const options = [
   { value: "apartment", label: "Квартиры", endPoint: 'resale', category_id: 1, },
   { value: "cottage", label: "Коттедж", endPoint: 'resalek', category_id: 2 },
   { value: "land", label: "Земельные участки", endPoint: 'resaleu', category_id: 3, },
];

const ResellersForm = (props) => {

   const { handleSubmit, form, change, isLoading, initialValues, mode, cities } = props;
   const { category } = initialValues || {category: {value: 'apartment'}};
   const [filter, setFilter] = useState(category.value);

   const handlerFilterChange = (params) => {
      setFilter(params.value);
   };

   // данные формы
   const values = useSelector(state => getFormValues(form)(state));
   const { city_id, area } = values;
   const {value: areaValue } = area || {};
   const {value: cityValue, label: cityLabel } = city_id || {};
   const areaOptions = (cityLabel && districsToCities[cityLabel]) ? districsToCities[cityLabel] : [];

   // сбрасываем район если изменили город
   useEffect(() => {
      if (areaValue && !areaOptions.find(area => area.value === areaValue)) {
         change('area', null);
      }
   }, [cityValue])

   return (
      <form className={`form form--basis${isLoading ? ' form--loading' : ''}`} onSubmit={(e) => handleSubmit(e)}>
         <LayoutFormBasis label={false} id="category-group" title="Категория">
            <div className="form__item form__item--min">
               <Field
                  isDisabled={mode === 'edit'}
                  name="category"
                  options={options}
                  placeholder="Выберите"
                  component={RenderSelect}
                  onChange={handlerFilterChange}
               />
            </div>
         </LayoutFormBasis>

         {filter === "apartment" ? (
            <LayoutFormBasis
               label={false}
               id="district-group"
               title="Район расположения"
            >
               <div className="form__group flex">
                  <div className="form__item">
                     <Field
                        id="city_id"
                        name="city_id"
                        options={cities}
                        placeholder="Выберите город"
                        component={RenderSelect}
                     />
                  </div>
                  <div className="form__item">
                     <Field
                        id="area"
                        name="area"
                        options={areaOptions}
                        placeholder="Выберите раойн"
                        component={RenderSelect}
                     />
                  </div>
               </div>
            </LayoutFormBasis>
         ) : (
            <LayoutFormBasis lable={false} title="Город">
               <div className="form__item form__item--min">
                  <Field
                     id="city_id"
                     name="city_id"
                     options={cities}
                     placeholder="Выберите"
                     component={RenderSelect}
                  />
               </div>
            </LayoutFormBasis>
         )}

         <LayoutFormBasis label={false} id="photo-group" title="Изображение">
            <Field
               id="images"
               name="images"
               className="form__group"
               component={RenderDropzoneInput}
            />
         </LayoutFormBasis>

         <LayoutFormBasis title="Дом на карте" id="coord">
            <div className="form__item form__item--min">
               <Field
                  id="coord"
                  name="coord"
                  className="control"
                  component={RenderInput}
                  placeholder="Указать на карте"
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="Улица, дом" id="address">
            <div className="form__item form__item--min">
               <Field
                  id="address"
                  name="address"
                  className="control"
                  component={RenderInput}
                  placeholder="Введите адрес в формате Улица, дом"
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="Цена, руб:" id="price">
            <div className="form__item form__item--min">
               <Field
                  id="price"
                  name="price"
                  className="control"
                  component={RenderInput}
                  placeholder="Введите цену"
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis
            label={false}
            id="description-group"
            title="Описание:"
         >
            <div className="form__item form__item--all">
               <Field
                  component={WYSIWYGEditor}
                  id="description"
                  name="description"
                  type="text"
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis
            label={false}
            id="characteristics-group"
            title="Характеристики:"
         >
            <div className="form__item form__item--all">
               <div className="feature">
                  <div className="feature__head">
                     <div className="feature__item flex">
                        <div className="feature__sub feature-mr feature__sub--dbg">
                           Наименование
                        </div>
                        <div className="feature__sub feature__sub--dbg">
                           Значение
                        </div>
                     </div>
                  </div>
                  <div className="feature__body">
                     {filter === "apartment" ? (
                        <Apartment />
                     ) : filter === "cottage" ? (
                        <Cottage />
                     ) : (
                        <Land />
                     )}
                  </div>
               </div>
            </div>
         </LayoutFormBasis>
         <div className="form__foot button-group flex">
            <ButtonBack to={'/resellers'} />
            <ButtonSave type={'submit'}/>
         </div>
      </form>
   );
};

export const validate = values => {
   const errors = {};
   const errorRequired = 'Поле обязательно для заполнения!';
   if(!values.city_id || !values.city_id.value){
      errors.city_id = errorRequired;
   }
   return errors
};


export default reduxForm({
   form: "resellers-form",
   enableReinitialize: true,
   validate,
})(ResellersForm);
