import React, {useEffect, useState} from "react";
import { Header } from "../../../Header/Header";
import Form from "./Form";
import {postPromo, updatePromo} from "../../../../actions/promoAction";

export const CreateOrUpdateOffer = ({match}) => {

   const [mediaData, setMediaData] = useState(null);
   const [isEditMode, setIsEditMode] = useState(match.path.includes('update'));

   const onSubmit = (formData) => {
      formData.published = '1';
      formData.image = mediaData;
      if (isEditMode) {
         updatePromo(match.params.id, formData);
      } else {
         postPromo(formData)
      }
   }

   useEffect(() => {
      setIsEditMode(match.path.includes('update'));
   }, [match.path]);

   return (
      <>
         <Header title={`${isEditMode ? 'Изменить' : 'Добавить'} акцию`} />
         <div className="card">
            <Form
               setMediaData={setMediaData}
               onSubmit={onSubmit}
               id={match.params.id}
               isEditMode={isEditMode}
            />
         </div>
      </>
   );
};
