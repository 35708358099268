export const isEmpty = (value) => {
   if (value === null || value === undefined || value === "") return true;
   if (typeof value === "object" && !Array.isArray(value)) {
      return Object.keys(value).length === 0;
   }
   if (Array.isArray(value)) {
      return value.length === 0;
   }
   return false;
};

export function isEqual(first, second) {
   const firstType = first.constructor.name;
   const secondType = second.constructor.name;

   if (firstType.localeCompare(secondType) === 0) {
      if (typeof first === "string") {
         return first.localeCompare(second) === 0;
      }

      if (Array.isArray(first) && first.length === second.length) {
         const result = first.filter((item, index) => {
            return !isEqual(item, second[index]);
         });
         return result.length === 0;
      }

      if (typeof first === "number") {
         const oneArg = first.toString();
         const twoArg = second.toString();
         return oneArg.localeCompare(twoArg) === 0;
      }

      if (typeof first === "object") {
         return JSON.stringify(first) === JSON.stringify(second);
      }

      if (typeof first === "boolean") {
         return first === second;
      }
   } else if (!isNaN(first) && !isNaN(second)) {
      return Number(first) === Number(second);
   }
   return false;
}
