import React from "react";

export const ButtonPublished = (props) => {
   return (
      <button
         className="btn btn--blue btn--no-border flex flex--ac"
         title="Опубликовать"
         onClick={props.onClick}
         type={props.type}
         {...props}
      >
         <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            aria-hidden="true"
            className="item-indent"
         >
            <path
               d="M12 0C5.38338 0 0 5.38292 0 12C0 18.6171 5.38338 24 12 24C18.6166 24 24 18.6171 24 12C24 5.38292 18.6166 0 12 0ZM12 23.0769C5.89246 23.0769 0.923077 18.1075 0.923077 12C0.923077 5.89246 5.89246 0.923077 12 0.923077C18.1075 0.923077 23.0769 5.89246 23.0769 12C23.0769 18.1075 18.1075 23.0769 12 23.0769Z"
               fill="white"
            />
            <path
               d="M17.6545 7.07717L10.5611 15.0572L6.28769 11.6386C6.08923 11.4793 5.798 11.5121 5.63923 11.7106C5.48 11.9099 5.51231 12.2002 5.71123 12.3595L10.3266 16.0518C10.4115 16.1196 10.5135 16.1529 10.6151 16.1529C10.7425 16.1529 10.8694 16.1003 10.9603 15.9978L18.3449 7.69009C18.5143 7.49994 18.4972 7.20779 18.3066 7.03886C18.1155 6.86902 17.8243 6.88609 17.6545 7.07717Z"
               fill="white"
            />
         </svg>
         Опубликовать
      </button>
   );
};
