import React, {useEffect} from "react";
import { Field, reduxForm } from "redux-form";
import { RenderDropzone } from "../../../Fields/RenderDropzone";
import { ButtonBack } from "../../../Buttons/ButtonBack";
import { ButtonSave } from "../../../Buttons/ButtonSave";
import { LayoutFormBasis } from "../../../Fields/LayoutForm";

const DesignForm = ({ handleSubmit, files, handleSetFiles, initialize, setDeleteIDs }) => {
   useEffect(() => {
      if(files.length > 0)
      {
         initialize({
            published: parseInt(files[0].published) === 1
         });
      }
   },[files]);

   return (
      <form className="form" onSubmit={handleSubmit}>
         <LayoutFormBasis label={false} id="design-gallery-group" title="Галерея">
            <div className="form__group">
               <RenderDropzone setDeleteIDs={(id) => setDeleteIDs(id)} files={files} setFiles={handleSetFiles} id="design-gallery"/>
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="Опубликовано" id="public-design">
            <div className="form__item">
               <Field
                  name="published"
                  id="public-design"
                  component="input"
                  type="checkbox"
               />
            </div>
         </LayoutFormBasis>

         <div className="form__foot button-group flex">
            <ButtonBack/>
            <ButtonSave/>
         </div>
      </form>
   );
}

export default reduxForm({
   form: "design-form",
})(DesignForm);
