import React, {useEffect, useState} from "react";
import { Field, reduxForm } from "redux-form";
import { validate } from "../../../Fields/validate";
import { RenderInput } from "../../../Fields/RenderInput";
import { ButtonBack } from "../../../Buttons/ButtonBack";
import { ButtonSave } from "../../../Buttons/ButtonSave";
import { RenderDropzone } from "../../../Fields/RenderDropzone";
import { LayoutFormBasis } from "../../../Fields/LayoutForm";
import {useDispatch, useSelector} from "react-redux";
import {loadHomeById} from "../../../../actions/complexActions";
import {useRouteMatch} from "react-router-dom";
import {RenderImage} from "../../../Fields/RenderImage";
import {RenderMultiField} from "../../../Fields/RenderMultiField";
import {WYSIWYGEditor} from "../../../Fields/WYSIWYGEditor";

const DescriptionForm = ({ initialize, handleSubmit, setImagePlanPhoto, setGallery, setViews, setDeleteIDs }) => {
   const [txt, setTxt] = useState("");
   const dispatch = useDispatch();
   let match  = useRouteMatch();
   const homeId = match.params.home_id;

   const formData = useSelector(state => state.form.description);
   const house = useSelector(state => state.complex.house);

   const handleChange = (e) => {
      setTxt(e.target.value);
   };

   const [files,setFiles] = useState([]);
   const [views, setViewsForm] = useState([]);

   useEffect(() => {
      if(house.imagesGallery)
         setFiles(house.imagesGallery);

      if(house.imagesViews)
         setViewsForm(house.imagesViews);
   },[house]);

   useEffect(() => {
      if(txt === "" && formData.values && formData.values.metka)
         setTxt(formData.values.metka);
   },[formData]);


   const handleSetFiles = (e) => {
      setFiles(e);
   }

   const handleViews = (e) => {
      setViewsForm(e);
   }

   useEffect(() => {
         setGallery(files);
      },
      [files]
   );

   useEffect(() => {
         setViews(views);
      },
      [views]
   );

   useEffect(() => {
      if(homeId)
         dispatch(loadHomeById(homeId, initialize));
   }, [homeId, dispatch]);

   return (
      <form className="form form--description" onSubmit={handleSubmit}>
         <LayoutFormBasis id="mark" title="Метка на генплане после наведения">
            <div className="form__item form__item--min item-indent">
               <Field
                  id="metka"
                  name="metka"
                  className="control"
                  component={RenderInput}
                  placeholder="Ввод II кв. 2019 г.”"
                  onChange={handleChange}
               />
            </div>

            <Toolbar txt={txt} />
         </LayoutFormBasis>

         <LayoutFormBasis id="theme_quest" title="Тема вопроса">
            <div className="form__item form__item--min item-indent">
               <Field
                  id="theme_quest"
                  name="theme_quest"
                  className="control"
                  component={RenderInput}
                  placeholder="Текст темы вопросы"
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis label={false} id="status-group" title="Статус">
            <div className="form__group">
               <div className="form__item form__item-mb">
                  <Field
                     name="status"
                     id="r1"
                     component="input"
                     type="radio"
                     value="0"
                  />
                  <label htmlFor="r1">Скоро в продаже</label>
               </div>
               <div className="form__item form__item-mb">
                  <Field
                     name="status"
                     id="r2"
                     component="input"
                     type="radio"
                     value="1"
                  />
                  <label htmlFor="r2">Количество свободных квартир из 1С</label>
               </div>
               <div className="form__item">
                  <Field
                     name="status"
                     id="r3"
                     component="input"
                     type="radio"
                     value="2"
                  />
                  <label htmlFor="r3">Все квартиры проданы</label>
               </div>
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="Акции" id="offer">
            <div className="form__item">
               <Field
                  name="promo"
                  id="offer"
                  component="input"
                  type="checkbox"
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis
            label={false}
            id="counter-group"
            title="Счетчик готовности дома"
         >
            <div className="form__group">
               <div className="form__group-inline form__group-top flex">
                  <div className="form__item form__item-mr">
                     <label htmlFor="counter" className="visually-hidden">
                        Включить счетчик готовности
                     </label>
                     <Field
                        name="counter"
                        id="counter"
                        component="input"
                        type="checkbox"
                     />
                  </div>
                  <div className="form__item">
                     <label>
                        <Field
                           className="control"
                           name="counter_text"
                           placeholder="Ввод II кв. 2019 г."
                           component={RenderInput}
                        />
                     </label>
                  </div>
               </div>

               <div className="form__group-inline form__group-bottom flex">
                  <div className="form__item form__item-mr">
                     <label htmlFor="percentage" className="form__label">
                        Начальный процент
                     </label>
                     <Field
                        className="control"
                        name="counter_proc0"
                        id="percentage"
                        component={RenderInput}
                     />
                  </div>
                  <div className="form__item form__item-mr">
                     <label htmlFor="date-start" className="form__label">
                        Дата начала
                     </label>
                     <Field
                        className="control"
                        name="counter_date_begin"
                        id="date-start"
                        type="date"
                        component={RenderInput}
                     />
                  </div>
                  <div className="form__item">
                     <label htmlFor="date-end" className="form__label">
                        Дата окончания
                     </label>
                     <Field
                        className="control"
                        name="counter_date_end"
                        id="date-end"
                        type="date"
                        component={RenderInput}
                     />
                  </div>
               </div>
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="Показывать цены" id="show-prices">
            <div className="form__item">
               <Field
                  name="showprice"
                  id="show-prices"
                  component="input"
                  type="checkbox"
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="Включить трейд-ин" id="trade-in">
            <div className="form__item">
               <Field
                  name="tradein"
                  id="trade-in"
                  component="input"
                  type="checkbox"
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="О проекте" id="about">
            <div className="form__item form__item--all">
               <Field
                  name="description"
                  id="about"
                  component={WYSIWYGEditor}
                  className="control"
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis label={false} id="finish" title="Отделка">
            <div className="form__item form__item--all">
               <Field
                  component={WYSIWYGEditor}
                  id="finish"
                  name="finish"
                  type="text"
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="Улица, дом" id="street">
            <div className="form__item form__item--min">
               <Field
                  id="street"
                  name="street"
                  className="control"
                  component={RenderInput}
                  placeholder="Ввести вручную"
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="Координаты на карте" id="geoposition">
            <div className="form__item form__item--min">
               <Field
                  id="geoposition"
                  name="geoposition"
                  className="control"
                  component={RenderInput}
                  placeholder="55.5555,777.8888"
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="Видеотрансляция" id="video-broadcast">
            <div className="form__item form__item--min">
               <Field
                  id="video-broadcast"
                  name="video"
                  className="control"
                  component={RenderInput}
                  placeholder="Укажите ссылку"
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="Виртуальный тур" id="virtual-tour">
            <div className="form__group">
               <Field
                  id="virtual-tour"
                  name="virtualtour"
                  className="control"
                  component={RenderMultiField}
                  placeholder="Введите значение"
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis title="Инструкция" id="instruction">
            <div className="form__group flex flex--ac">
               <Field
                  id="instruction"
                  name="instruction"
                  className="control"
                  placeholder="Укажите ссылку"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis id="imagePlanPhoto" title="Схема подъездов">
            <div className="form__item form__item--min">
               <Field name="imageplan"
                      id="imageplan"
                      setMediaData={setImagePlanPhoto}
                      component={RenderImage}
               />
            </div>
         </LayoutFormBasis>


         <LayoutFormBasis label={false} id="gallery-group" title="Галерея">
            <div className="form__group">
               <RenderDropzone setDeleteIDs={(id) => setDeleteIDs(id)} files={files} setFiles={handleSetFiles} id="gallery-description" />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis
            label={false}
            id="gallery-group-2"
            title="Виды из окна"
         >
            <div className="form__group">
               <RenderDropzone setDeleteIDs={(id) => setDeleteIDs(id)} files={views} id="gallery-2-description" setFiles={handleViews} />
            </div>
         </LayoutFormBasis>

         <div className="form__foot button-group flex">
            <ButtonBack />
            <ButtonSave />
         </div>
      </form>
   );
};

export default reduxForm({
   form: "description",
   validate,
})(DescriptionForm);

const Toolbar = ({ txt }) => (
   <div className="toolbar">
      <span className="dark title-medium">.......</span>
      <span className="dark title-medium">...........</span>
      <span className="x-medium">{txt}</span>
   </div>
);
