import React from "react";
import IconCar from "../../../assets/img/icon/car.png";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { ParkingPlacesList } from "./ParkingPlacesList";
import { CanvasPreview } from "../../Canvas/CancasPreview/CancasPreview";
import { ButtonBack } from "../../Buttons/ButtonBack";
import { ButtonSave } from "../../Buttons/ButtonSave";
import { Field, reduxForm } from "redux-form";
import { RenderInput } from "../../Fields/RenderInput";
import { LayoutFormBasis } from "../../Fields/LayoutForm";
import { RenderImage } from "../../Fields/RenderImage";
import { deleteParkingPlace } from "../../../actions/parkingActions";

const ParkingLevelForm = ({
   handleSubmit,
   image,
   setMediaData,
   isUpdating,
   placesList,
   placesInfo,
}) => {
   let { url } = useRouteMatch();
   let history = useHistory();
   return (
      <form className="form form--description" onSubmit={handleSubmit}>
         <div className="ling-group ling-group--mb flex flex--sb">
            <LayoutFormBasis id="name" title="Номер уровня:">
               <div className="form__item form__item--min">
                  <Field
                     id="name"
                     name="name"
                     className="control"
                     component={RenderInput}
                  />
               </div>
            </LayoutFormBasis>

            {isUpdating && (
               <button
                  className="btn btn--green btn--no-border flex flex--ac"
                  title="Обновить изображение"
                  onClick={() => setMediaData(null)}
                  type="button"
               >
                  ⟲ Изображение
               </button>
            )}

            {isUpdating ? (
               <Link
                  to={`${url}/create`}
                  className="flex flex--ac btn btn--mw btn--green item-indent"
               >
                  <img
                     src={IconCar}
                     alt=""
                     aria-hidden="true"
                     className="item-indent"
                  />
                  Добавить место
               </Link>
            ) : (
               <div>
                  Чтобы добавить парковочное место, <br />
                  сохраните уровень паркинга
               </div>
            )}
         </div>
         <div className="flex flex--sb">
            {!image ? (
               <LayoutFormBasis title="Загрузите план уровня:" id="image">
                  <div className="form__item">
                     <Field
                        name="image"
                        id="image"
                        component={RenderImage}
                        setMediaData={setMediaData}
                     />
                  </div>
               </LayoutFormBasis>
            ) : (
               <CanvasPreview
                  customUrl={`${url}/update`}
                  complexInfo={placesInfo}
                  image={image}
               />
            )}
            <ParkingPlacesList
               handleDelete={deleteParkingPlace}
               url={url}
               places={placesList}
            />
         </div>

         <div className="form__foot button-group flex">
            <ButtonBack onClick={() => history.goBack()} />
            <ButtonSave type="submit" />
         </div>
      </form>
   );
};

export default reduxForm({
   form: "form-parking-level",
   enableReinitialize: true,
})(ParkingLevelForm);
