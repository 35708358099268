import React, {useEffect, useState} from "react";
import { Header } from "../Header/Header";
import { TableList } from "../TableList/TableList";
import {Pagination} from "../Pagination/Pagination";
import API from "../../utils/API";
import {Link} from "react-router-dom";

export const UsersContainer = () => {

   const [list, setList] = useState({
      items: []
   });
   let head = ["id", "Email", "Имя", "Фамилия", "Телефон", "Ссылки"];

   const getList = (page = 1) => {
      API.get(`user`, {params: {page}})
         .then(({data}) => {

            let dataList = data.items.map(item => ({
               id: item.id,
               email: item.email,
               name: item.username,
               surname: item.surname,
               phone: item.phone,
               links: {
                  delete: true,
               },
            }));

            setList({
               meta: data._meta,
               items: dataList
            });
         });
   }

   const handleDelete = (e) => () => {
      API.delete(`user/delete/${e}`)
         .then((r) => {
            if(r)
               getList();
         });
   }

   useEffect(() => {
      getList();
   },[]);

   return (
      <>
         <Header title="Пользователи" />
         <div className="card">
            <div className="card__head">
               <Link
                  to={`/users/create`}
                  className="btn btn--green btn--no-border"
               >
                  Добавить
               </Link>
            </div>
            <TableList handleDelete={handleDelete} head={head} list={list.items} />
            {
               list.meta &&
               <Pagination
                  onSetPage={getList}
                  pageCount={list.meta.pageCount}
                  currentPage={list.meta.currentPage}
                  itemCount={list.items.length}
                  totalCount={list.meta.totalCount}
               />
            }
         </div>
      </>
   );
};
