import React from "react";
import { ButtonBack } from "../Buttons/ButtonBack";
import { ButtonSave } from "../Buttons/ButtonSave";
import { submit } from 'redux-form';
import {useDispatch} from "react-redux";
import { useHistory } from "react-router-dom";

export const FooterFloor = () => {
   const history = useHistory();
   const dispatch = useDispatch();
   return (
      <div className="form__foot button-group flex">
         <ButtonBack onClick={() => history.goBack()} />
         <ButtonSave onClick={() => dispatch(submit("floor-description"))} />
      </div>
   );
};
