import API from "../utils/API";
import { history } from "../utils/history";
import { adminConstants } from "../constants/adminConstants";
import { SubmissionError } from "redux-form";

const requestHandler = (request) => {
   const token = localStorage.getItem('token');
   if (token) {
      request.headers['Authorization'] = `Bearer ${token}`;
   }

   return request;
};

API.interceptors.request.use((request) => requestHandler(request));

export const Login = (value) => {
   return (dispatch) => {
      return API.post("auth", value)
         .then((res) => {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem('loggedIn', 'true');
            dispatch(loginSuccess(res.data.status));
            history.push("/");
         })
         .catch((error) => {
            throw new SubmissionError({
               _error: error.response.data.message.password,
            });
         });
   };
};

export function loginSuccess(status) {
   return {
      type: adminConstants.LOGIN_SUCCESS,
      status,
   };
}

export const Logout = () => {
   return (dispatch) => {
      try {
         localStorage.removeItem("token");
         localStorage.removeItem("loggedIn");

         dispatch({ type: adminConstants.LOGOUT });
         history.push("/");
      } catch (err) {
         console.log(err);
      }
   };
};
