import {noticeConstants} from "../constants/noticeConstants";
import API from "../utils/API";

export const setQuestions = (data) => ({type: noticeConstants.SET_QUESTIONS, payload: data});
export const setRequests = (data) => ({type: noticeConstants.SET_REQUEST, payload: data});
export const setReviews = (data) => ({type: noticeConstants.SET_REVIEWS, payload: data});

export const receiveQuestionsTotal = (params = {published: 0}) => (dispatch) => {
   API.get('quest',{params})
      .then(({data}) => {
         dispatch(setQuestions(data._meta.totalCount));
      }).catch(console.log);
}
export const receiveRequestsTotal = (params = {yesno: 0}) => (dispatch) => {
   API.get('request',{params})
      .then(({data}) => {
         dispatch(setRequests(data._meta.totalCount));
      }).catch(console.log);
}
export const receiveReviewsTotal = (params = {published: 0}) => (dispatch) => {
   API.get('review',{params})
      .then(({data}) => {
         dispatch(setReviews(data._meta.totalCount));
      }).catch(console.log);
}
