import React from "react";

export const RenderRadioImage = ({
   input,
   label,
   image,
   meta: { touched, error },
   ...custom
}) => {

   return (
      <>
         <div className="radio-wrapper">
            <input {...custom} {...input} />
            <label htmlFor={custom.id} style={{ background: `url(${image}) center center/ cover no-repeat` }} />
            {touched && error && <span className="block red-txt">{error}</span>}
         </div>
      </>
   );
};
