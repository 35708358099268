import {promoConstants} from "../../constants/promoConstants";

const initialState = {
   data: {},
   hasError: false,
   currentPromo: null,
}

export default (state = initialState, action) => {
   switch (action.type) {
      case promoConstants.SET_PROMO:
         return {
            ...state,
            data: action.payload.data,
         }
      case promoConstants.SET_ERROR:
         return {
            ...state,
            hasError: true,
         }
      default:
         return state;
   }
}
