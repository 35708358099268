import React, {useEffect, useState} from "react";
import { TableList } from "../TableListCustom";
import { Link, useRouteMatch } from "react-router-dom";
import {deleteFloor, getFloors} from "../../../actions/floorActions";

export const PorchEdit = () => {
   const [list, setList] = useState(false);
   let { params , url }  = useRouteMatch();
   const { entrance_id, home_id, id } = params;
   let head = ["ID", "Название", "Действия"];
   useEffect(() => {
      getFloors(entrance_id)
         .then(result => setList(result.map(item =>  (
               {
                  id: item.id,
                  name: item.name,
                  links: {
                     delete: true,
                     hide: true,
                     edit: `${url}/floor-edit`,
                  },
               }
            )
            )));
   },[]);

   const handleDelete = (id) => {
      if(id){
         deleteFloor(id)
            .then(r => setList(list.filter(item => item.id !== id)));
      }
   }

   return (
      <>
         <div className="card__head card__head--border card__head--mb flex flex--sb flex--ac">
            <h2 className="dark mb-none">Этажи</h2>
            <Link
               to={`${url}/floor/create`}
               className="btn btn--green btn--no-border"
            >
               + Добавить
            </Link>
         </div>
         <TableList
            head={head}
            handleDelete={handleDelete}
            list={list} />
      </>
   );
};
