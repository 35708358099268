import React, {useState} from "react";
import Form from "./Form";
import { Header } from "../../../Header/Header";
import {Redirect, useRouteMatch} from "react-router-dom";
import {useDispatch} from "react-redux";
import {saveWorker, updateWorker} from "../../../../actions/workerActions";

export const CreateEmployer = () => {
   const [save,setSave] = useState(false);

   let { params } = useRouteMatch();
   let { id, employer_id } = params;
   let dispatch = useDispatch();

   const [media , setMediaData] = useState(false);

   const handleSubmit = (values) => {
      let formData = new FormData();

      Object.keys(values).map(key => {
         formData.append(key,values[key]);
      });
      formData.append('city_id', id);

      formData.delete('photo');
      if(media)
      {
         formData.append('photo', media);
      }

      if(employer_id){
         dispatch(updateWorker(formData,employer_id));
      } else {
         dispatch(saveWorker(formData, id));
      }

      setSave(true);
   };

   if(save)
      return <Redirect to={{ pathname: `/pages/contacts/${id}/settings` }} />;

   return (
      <>
         <Header title="Добавить сотрудника" />

         <div className="card">
            <Form
               onSubmit={(values) => {
                  handleSubmit(values);
               }}
               setMediaData={setMediaData}
            />
         </div>
      </>
   );
};
