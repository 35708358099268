export default {
   "Набережные Челны": [
      {
         label: "Новый город",
         value: "Новый город",
      },
      {
         label: "ГЭС",
         value: "ГЭС",
      },
      {
         label: "ЗЯБ",
         value: "ЗЯБ",
      },
      {
         label: "Замелекесье",
         value: "Замелекесье",
      },
      {
         label: "Сидоровка",
         value: "Сидоровка",
      },
   ],
   Елабуга: [
      {
         label: "мкрн. 4-5",
         value: "мкрн. 4-5",
      },
   ],
   Альметьевск: [
      {
         label: 'мкрн. "Алсу"',
         value: 'мкрн. "Алсу"',
      },
   ],
};
