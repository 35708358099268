import React, { useEffect, useState, useRef } from "react";
import Konva from "konva";
import { Stage, Layer, Group } from "react-konva";
import { useRouteMatch, Redirect } from "react-router-dom";

import { BaseImage } from "../../Canvas/General/BaseImage";
import { useCheckSize } from "../../Canvas/General/useCheckSize";
import { Popup } from "../../Canvas/CancasPreview/Popup";
import { Figure } from "../../Canvas/CancasPreview/Figure";
import { ZoomStage } from "../../Canvas/General/ZoomStage";

import { isEmpty } from "../../../utils/utils";

Konva.hitOnDragEnabled = true;

export const CanvasOfficePreview = ({
   image,
   offices,
   customUrl,
   updateByIndexItem = false,
}) => {
   const { url } = useRouteMatch();
   const ref = useRef();
   const [size, setSize] = useCheckSize();
   const [scale, setScale] = useState(1);
   const [data, setData] = useState([]);

   const [redirect, setRedirect] = useState(false);

   const normalizeData = (values) =>
      values.map((item) => ({
         ...item,
         points: !isEmpty(item.plan) ? item.plan.points : [],
         popup: !isEmpty(item.plan) ? item.plan.popup : null,
      }));

   useEffect(() => {
      if (!isEmpty(offices)) {
         setData(normalizeData(offices));
      } else {
         setData([]);
      }
   }, [offices]);

   const zoomStage = (stage, scaleBy) => {
      ZoomStage(stage, scaleBy);
   };

   const handleRedirect = (e) => {
      setRedirect(e);
   };

   return (
      <div className="frame flex flex--ac flex--jc-center">
         {redirect !== false && (
            <Redirect
               push
               to={{
                  pathname: customUrl
                     ? `${customUrl}/${redirect}`
                     : `${url}/build/update/${redirect}`,
               }}
            />
         )}
         <div className="frame-canvas">
            <Stage
               ref={ref}
               width={size.width}
               height={size.height}
               scaleX={scale}
               scaleY={scale}
               className="canvas"
               draggable={true}
            >
               <Layer>
                  <Group>
                     <BaseImage
                        photo={image}
                        size={size}
                        setScale={setScale}
                        setSize={setSize}
                     />
                     {data &&
                        data.map((polygon, index) => (
                           <Figure
                              key={index}
                              polygon={polygon}
                              handleRedirect={() =>
                                 handleRedirect(
                                    updateByIndexItem ? index : polygon.id
                                 )
                              }
                           />
                        ))}
                     {data &&
                        data.map((title, index) => {
                           if (!title.popup) return null;
                           const pos = {
                              x: title.popup.position.x + 17,
                              y: title.popup.position.y + 27,
                           };
                           return (
                              <Popup
                                 key={index}
                                 title={title.popup.title}
                                 position={{ x: pos.x, y: pos.y }}
                              />
                           );
                        })}
                  </Group>
               </Layer>
            </Stage>
            <div className="zoom-container">
               <button
                  type="button"
                  onClick={() => {
                     zoomStage(ref.current, 1.2);
                  }}
               >
                  +
               </button>
               <button
                  type="button"
                  onClick={() => {
                     zoomStage(ref.current, 0.8);
                  }}
               >
                  -
               </button>
            </div>
         </div>
      </div>
   );
};
