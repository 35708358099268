import { cityConstants } from "../../constants/cityConstants";

const initialState = {
   isFetching: false,
   data: []
};

export function cityReducer(state = initialState, action) {
   switch (action.type) {
      case cityConstants.FETCH_CITY_SUCCESS:
         return {
            ...state,
            data: action.city
         };

      case cityConstants.FETCH_CITY_FAILURE:
         return {
            ...state,
            data: [],
            isFetching: false
         };

      case cityConstants.IS_FETCHING_CITY:
         return {
            ...state,
            isFetching: action.status
         };

      case cityConstants.DELETE_CITY:
         return {
            ...state,
            data: state.data.filter((element) => element.id !== action.id)
         };

      default:
         return state;

   }
}

const initialStateInfo = {
   data: null,
   inProgress: false,
   isFetching: false
};

export function cityInfoReducer(state = initialStateInfo, action) {
   switch (action.type) {
      case cityConstants.IS_FETCHING_CITY:
         return {
            ...state,
            isFetching: action.status
         };

      case cityConstants.FETCH_CITY_DETAILS_SUCCESS:
         return {
            ...state,
            data: action.data
         };

      default:
         return state;
   }
}
