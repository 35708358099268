import API from "../utils/API";

export const createMedia = (data, callback = () => void 0) => (dispatch) => {
   API.post("media/create", data)
      .then(() => {
         callback();
      })
      .catch((error) => console.log(error));
};

export const deleteMedia = (id, callback = () => void 0) => (dispatch) => {
   API.delete(`media/delete/${id}`)
      .then(() => {
         callback();
      })
      .catch((error) => console.log(error));
};
