import React from "react";

export const RenderInput = ({
   input,
   label,
   meta: { touched, error },
   ...custom
}) => {
   return (
      <>
         <input type="text" {...custom} {...input} />
         {touched && error && <span className="block red-txt">{error}</span>}
      </>
   );
};
