import React, { useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Header } from "../../Header/Header";
import { TableList } from "../../TableList/TableList";
import { useDispatch, useSelector } from "react-redux";
import { getParkingListsSelect } from "../../../selectors/parking-selectors";
import { deleteParking, receiveParking } from "../../../actions/parkingActions";
import { getCitiesForFormSelector } from "../../../selectors/city-selectors";
import { getHouseListSelect } from "../../../selectors/complex-selectors";
import { receiveCities } from "../../../actions/cityAction";
import { receiveHouse } from "../../../actions/complexActions";

export const ParkingListContainer = () => {
   let { url } = useRouteMatch();

   const cities = useSelector(getCitiesForFormSelector);
   const houses = useSelector(getHouseListSelect);

   let head = ["ID", "Название", "Адрес", "Город", "Ссылки"];
   const parkingList = useSelector(getParkingListsSelect);
   const parkingTable = parkingList.map(parking => ({
      id: parking.id,
      name: parking.name,
      address: houses.length ? houses.find(house => house.id === parking.house_id)?.name : '',
      city: cities.length ? cities.find(city => city.value === parking.city_id)?.label : '',
      links: {
         edit: true,
         delete: true,
      }
   }));

   const dispatch = useDispatch();

   const updateList = () => {
      dispatch(receiveParking());
   };

   useEffect(() => {
      updateList();
      if (!cities.length) {
         dispatch(receiveCities());
      }
      if (!houses.length) {
         dispatch(receiveHouse());
      }
   }, [dispatch]);

   return (
      <>
         <Header title="Паркинги" />

         <div className="card">
            <div className="card__head">
               <Link
                  to={`${url}/create`}
                  className="btn btn--green btn--no-border"
               >
                  Добавить
               </Link>
            </div>
            <TableList
               handleDelete={deleteParking}
               withPage={false}
               pageName="parking"
               head={head}
               list={parkingTable}
            />
         </div>
      </>
   );
};
