import React from "react";
import { Field, reduxForm } from "redux-form";
import { LayoutFormBasis } from "../../Fields/LayoutForm";
import { RenderInput } from "../../Fields/RenderInput";
import { RenderImage } from "../../Fields/RenderImage";
import { RenderSelect } from "../../Fields/RenderSelect";
import { WYSIWYGEditor } from "../../Fields/WYSIWYGEditor";
import { ButtonSave } from "../../Buttons/ButtonSave";
import { ButtonBack } from "../../Buttons/ButtonBack";
import { RenderDropzoneInput } from "../../Fields/RenderDropzoneInput";
import { useHistory } from "react-router-dom";

const BasisForm = ({ setMediaData, handleSubmit, cities, houseGroups }) => {
   let history = useHistory();
   return (
      <form className="form form--description" onSubmit={handleSubmit}>
         <LayoutFormBasis id="name" title="Название:">
            <div className="form__item form__item--min">
               <Field
                  id="name"
                  name="name"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis id="brief" title="Краткое описание:">
            <div className="form__item form__item--all">
               <Field
                  id="brief"
                  name="brief"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis id="preview" title="Превью">
            <div className="form__item form__item--min">
               <Field
                  name="preview"
                  id="preview"
                  dop="true"
                  component={RenderImage}
                  setMediaData={setMediaData}
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis label={false} id="gallery_group" title="Галерея">
            <Field
               id="gallery_group"
               name="gallery_group"
               className="form__group"
               component={RenderDropzoneInput}
            />
         </LayoutFormBasis>

         <LayoutFormBasis label={false} id="city-group" title="Город">
            <div className="form__item form__item--min">
               <Field
                  name="city"
                  placeholder="Выберите город"
                  component={RenderSelect}
                  options={cities}
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis id="coord" title="На карте">
            <div className="form__item form__item--min">
               <Field
                  name="coord"
                  id="coord"
                  placeholder="Укажите ссылку / координаты / указать на карте"
                  component={RenderInput}
                  className="control"
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis
            label={false}
            id="address-home"
            title="Привязать к дому"
         >
            <div className="form__item form__item--min">
               <Field
                  name="house"
                  placeholder="Выберите дом"
                  component={RenderSelect}
                  options={houseGroups}
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis label={false} id="description-group" title="Описание">
            <div className="form__item form__item--all">
               <Field
                  component={WYSIWYGEditor}
                  id="description"
                  name="description"
                  type="text"
               />
            </div>
         </LayoutFormBasis>

         <div className="form__foot button-group flex">
            <ButtonBack onClick={() => history.goBack()} />
            <ButtonSave type="submit" />
         </div>
      </form>
   );
};

const validate = (values) => {
   const errors = {};
   if (!values.city) {
      errors.city = "Поле обязательно для заполнения";
   }
   return errors;
};

export default reduxForm({
   form: "form-parking",
   enableReinitialize: true,
   validate,
})(BasisForm);
