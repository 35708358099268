import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch, Link } from "react-router-dom";
import { Header } from "../Header/Header";
import { deletePage, receivePages } from "../../actions/pagesAction";
import { PagesList } from "./PagesList";

export const PagesListContainer = () => {
   let { url } = useRouteMatch();

   let dispatch = useDispatch();
   const pages = useSelector((state) => state.pages);

   useEffect(() => {
      dispatch(receivePages());
   }, [dispatch]);

   const handleDelete = (short) => {
      dispatch(deletePage(short));
   };

   return (
      <>
         <Header title="Страницы" />

         {pages.data.length > 0 && (
            <div className="card card--width">
               <div className="card__head">
                  <Link
                     to={`${url}/create`}
                     className="btn btn--green btn--no-border"
                  >
                     Добавить страницу
                  </Link>
               </div>
               <PagesList
                  handleDelete={handleDelete}
                  url={url}
                  data={pages.data}
               />
            </div>
         )}
      </>
   );
};
