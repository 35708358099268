import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";

import { Header } from "../../Header/Header";
import { Tabs } from "./Tabs";
import { BasisForm } from "./BasisForm";

import { receiveCities } from "../../../actions/cityAction";
import { receiveComplex, receiveHouse } from "../../../actions/complexActions";
import { createMedia } from "../../../actions/media";
import { addCommercialProperty } from "../../../actions/commercialPropertyAction";
import {
   getComplexSelect,
   getHouseListSelect,
} from "../../../selectors/complex-selectors";
import { getCitiesForFormSelector } from "../../../selectors/city-selectors";

const FORM_NAME = "create-form-commercial-property";

const validate = (values) => {
   const errors = {};
   if (!values.area) {
      errors.area = "Поле обязательно для заполнения";
   }
   if (!values.city) {
      errors.city = "Поле обязательно для заполнения";
   }
   return errors;
};

const Form = reduxForm({
   form: FORM_NAME,
   enableReinitialize: true,
   validate,
})(BasisForm);

const selector = formValueSelector(FORM_NAME);

const CommercialForm = connect((state) => {
   const targetCity = selector(state, "city");
   return {
      targetCity,
   };
})(Form);

export const CreateCommercialProperty = () => {
   const [mediaData, setMediaData] = useState(null);
   const dispatch = useDispatch();

   const complexes = useSelector(getComplexSelect);
   const cities = useSelector(getCitiesForFormSelector);
   const houses = useSelector(getHouseListSelect);

   const complexGroups = complexes.map((complex) => ({
      label: complex?.name,
      options: [],
      id: complex?.id,
   }));
   houses.forEach((house) => {
      const complexId = house.complex_id;
      const currentComplex = complexGroups.find(
         (complex) => complex?.id === complexId
      );
      if (currentComplex) {
         currentComplex.options.push({ value: house.id, label: house.name });
      }
   });
   const houseGroups = complexGroups.filter((group) => group.options.length);

   useEffect(() => {
      if (!cities.length) {
         dispatch(receiveCities());
      }
      if (!complexes.length) {
         dispatch(receiveComplex());
      }
      if (!houses.length) {
         dispatch(receiveHouse());
      }
   }, [cities.length, complexes.length, dispatch, houses.length]);

   const onSubmit = (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      values.gallery_group.forEach((image) => {
         formData.append("images[]", image); //
      });

      formData.append("city_id", values.city.value);
      formData.append("name_search", values.name_search);
      formData.append("address", values.address);
      formData.append("description", values.description);
      formData.append("area", values.area.label);
      formData.append("coord", values.coord);

      if(values.house_id && values.house_id.value)
         formData.append("house_id", values.house_id.value);

      if (mediaData) {
         formData.append("image", mediaData);
      }
      dispatch(addCommercialProperty(formData));
   };

   return (
      <>
         <Header title="Добавить коммерческую недвижимость" />
         <Tabs
            content={
               <CommercialForm
                  onSubmit={onSubmit}
                  cities={cities}
                  mediaData={mediaData}
                  setMediaData={setMediaData}
                  houseGroups={houseGroups}
               />
            }
         />
      </>
   );
};
