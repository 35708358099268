import React from "react";
import { Link } from "react-router-dom";

export const ButtonBack = (props) => {
   return (
      <Link
         className="btn btn--border btn--border flex flex--ac"
         title="Отменить"
         onClick={props.onClick}
         type={props.type}
         {...props}
      >
         <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className="item-indent trnswhite"
         >
            <path
               d="M13.9688 1.41241L12.572 0.015625L6.98438 5.60322L1.39678 0.015625L0 1.41241L5.58759 7L0 12.5876L1.39678 13.9844L6.98438 8.39678L12.572 13.9844L13.9688 12.5876L8.38116 7L13.9688 1.41241Z"
               fill="#6C7177"
            />
         </svg>
         Отмена
      </Link>
   );
};
