import React, { useEffect, useCallback } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ButtonEdit } from "../../Buttons/ButtonEdit";
import { ButtonDelete } from "../../Buttons/ButtonDelete";

import {
   receiveDeleteOfficeById,
   receiveOfficesByCommrealty,
} from "../../../actions/commercialPropertyAction";

export const RightListOffices = ({ url }) => {
   const match = useRouteMatch();
   const dispatch = useDispatch();

   const offices = useSelector((state) => state.commercialProperty.offices);

   const getOffices = useCallback(async () => {
      try {
         await dispatch(receiveOfficesByCommrealty(match.params.id));
      } catch (error) {
         console.error(error.message);
      }
   }, [match.params.id, dispatch]);

   const handleDelete = useCallback(
      async (id) => {
         try {
            await dispatch(receiveDeleteOfficeById(id));
         } catch (error) {
            console.error(error.message);
         }
      },
      [dispatch]
   );

   useEffect(() => {
      getOffices();
   }, [getOffices]);

   return (
      <div className="right-list">
         <div className="right-list__head">Название</div>
         <div className="right-list__body">
            {offices.map((item, index) => (
               <div key={index} className="list flex flex--sb flex--ac">
                  {item.id ? (
                     <Link
                        to={`${url}/update/${item.id}`}
                        className="list__name"
                     >
                        {item.name}
                     </Link>
                  ) : (
                     <div>{item.name}</div>
                  )}
                  <div className="list__button">
                     <ButtonEdit
                        url={`${url}/update/${item.id}`}
                        house={item}
                     />
                     <ButtonDelete
                        onClick={() => handleDelete(item.id)}
                        w={24}
                        h={24}
                     />
                  </div>
               </div>
            ))}
         </div>
      </div>
   );
};
