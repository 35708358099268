import React from "react";
import { Header } from "../../../../Header/Header";
import FlatForm from "./Flat-Form";

export const CreateFlats = () => {
   return (
      <>
         <Header title="Добавить квартиру" />
         <div className="card">
            <FlatForm />
         </div>
      </>
   );
};
