import {promoConstants} from "../constants/promoConstants";
import API from "../utils/API";
import {handleError} from "./newsAction";
import {fromDateTime, toDateTime} from "../utils/helpers";
import {history} from "../utils/history";

export const setPromo = (data) => ({type: promoConstants.SET_PROMO, payload: {data}});

export const receivePromo = (params = {}) => (dispatch) => {
   API.get('promo', {
      params,
   })
      .then(({data}) => {
         const metaData = data._meta;
         const items = data.items.map(val => {
            const newObj = {
               id: val.id,
               name: val.name,
               order: val.order,
               date_begin: toDateTime(val.date_begin),
               date_end: toDateTime(val.date_end),
               links: {
                  delete: true,
                  // hide: true,
                  edit: "/",
               }
            }
            return newObj;
         })
         dispatch(
            setPromo({
               items,
               metaData,
            })
         );
      })
      .catch(() => {
         dispatch(handleError());
      });
};

export const postPromo = (data) => {
   const formData = new FormData();
   formData.append('name', data.name);
   formData.append('image', data.image);
   formData.append('text', data.text);
   formData.append('order', parseInt(data.order, 10));
   formData.append('date_begin', parseInt(data.date_begin, 10));
   formData.append('date_end', parseInt(data.date_end, 10));
   API.post(`promo/create`, formData)
      .then(() => {
         history.push('/pages/offer');
      });
}

export const deletePromo = (id) => (dispatch) => {
   API.delete(`promo/delete/${id}`)
      .then(() => {
         dispatch(receivePromo())
      });
}

export const updatePromo = (id, data) => {

   API.put(`promo/update/${id}`, data)
      .then(() => {
         if (data.image) {
            const formData = new FormData();
            formData.append('image', data.image);
            API.post(`promo/updateimage/${id}`, formData)
               .then(() => {
                  history.push(`/pages/offer`);
               });
         } else {
            history.push(`/pages/offer`);
         }
      });
}

export const getCurrentPromo = (id, initialize) => {
   API.get(`promo/view/${id}`)
      .then(({data}) => {
         const initData = {...data};
         initData.image = `http://dc.websalamat.ru/img/${data.image}`;
         initialize({...initData});
      })
}
