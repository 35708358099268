import React, {useEffect, useState} from "react";
import DescriptionForm from "./DescriptionForm";
import {updateHome} from "../../../../actions/complexActions";
import {useRouteMatch} from "react-router-dom";
import {useDispatch} from "react-redux";

export const DescriptionContainer = ({handleSave}) => {
   let match  = useRouteMatch();
   const homeId = match.params.home_id;
   const dispatch = useDispatch();

   const [imagePlan , setImagePlanPhoto] = useState(null);
   const [gallery , setGallery] = useState([]);
   const [views , setViews] = useState([]);
   const [deletedIds, setDeletedIds] = useState([]);

   useEffect(
      () => () => {
         gallery.forEach((file) => URL.revokeObjectURL(file.preview));
      },
      [gallery]
   );

   useEffect(
      () => () => {
         views.forEach((file) => URL.revokeObjectURL(file.preview));
      },
      [views]
   );

   const setDeleteIDs = async (deleteId) => {
      setDeletedIds([...deletedIds, deleteId]);
   }

   const handleSubmit = async (values) => {
      const formData = new FormData();
      await Object.keys(values).map(key => {
         if(key === 'virtualtour')
         {
            formData.append(key, JSON.stringify(values[key]));
         } else {
            formData.append(key, typeof values[key] === 'boolean' ? values[key] ? 1 : 0 : values[key]);
         }
      });

      formData.delete('imageplan');

      if(imagePlan)
      {
         formData.append('imageplan',imagePlan);
      }

      for (const deleteId of deletedIds) {
         formData.append('deletedIds[]', deleteId);
      }

      const result = await dispatch(updateHome(homeId, formData, gallery, views));

      if (result.new_gallery_images) {
         setGallery(files => {
            let newFiles = [...files];
            newFiles.forEach((file, key) => {
               if (result.new_gallery_images[key]) {
                  file.id = result.new_gallery_images[key];
               }
            });
            return newFiles;
         })
      }

      if (result.new_views_images) {
         setViews(files => {
            let newFiles = [...files];
            newFiles.forEach((file, key) => {
               if (result.new_views_images[key]) {
                  file.id = result.new_views_images[key];
               }
            });
            return newFiles;
         })
      }

      setDeletedIds([]);
      handleSave();
   };

   return <DescriptionForm
      setDeleteIDs={setDeleteIDs}
      onSubmit={handleSubmit}
      setGallery={setGallery}
      setViews={setViews}
      setImagePlanPhoto={setImagePlanPhoto}
   />;

};
