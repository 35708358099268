import React from "react";
import Select from "react-select";

const customStyles = {
   control: (provided, state) => ({
      ...provided,
      border: "none",
      borderRadius: 0,
      padding: "2px 5px",
      background: "#f4f4f4",
      fontSize: "14px",
      boxShadow: "none",
      cursor: "pointer",
      // '&:hover': {
      //    border: state.isFocused && '1px solid #7f7a7a',
      // },
      // '&:focus': {
      //    border: state.isFocused && '1px solid #7f7a7a',
      //    boxShadow: state.isFocused && 'none'
      // },
   }),
   indicatorSeparator: () => ({}),
   placeholder: (provided) => ({
      ...provided,
      color: "#3e3d3d",
   }),
   dropdownIndicator: (provided) => ({
      ...provided,
      color: "#3e3d3d",
      padding: "3px",
      fontSize: "13px",
   }),
   clearIndicator: (provided) => ({
      ...provided,
      color: "#3e3d3d",
      padding: "3px",
   }),
   menuList: (provided) => ({
      ...provided,
      fontSize: "14px",
   }),
   groupHeading: (provided) => ({
      ...provided,
      fontWeight: "bold",
      fontSize: "14px",
      color: "#3e3d3d",
   }),
   option: (provided) => ({
      ...provided,
      cursor: "pointer",
   }),
   // option: (provided, state) => ({
   //    ...provided,
   //    color: state.isSelected ? '#fff' : '#3f4448',
   //    // backgroundColor: state.isSelected ? "red" : null,
   //    fontSize: '13px',
   // }),
};

export const RenderSelect = ({
   input,
   options,
   values,
   meta: { touched, error },
   ...custom
}) => {
   return (
      <>
         <Select
            styles={customStyles}
            {...input}
            {...custom}
            value={input.value}
            isClearable
            onChange={(value) => {
               input.onChange(value);
            }}
            onBlur={(value) => input.onBlur()}
            onBlurResetsInput={false}
            options={options}
            // inputId={id}
            noOptionsMessage={() => "Ничего не найдено"}
         />

         {touched && error && <span className="hasError">{error}</span>}
      </>
   );
};
