export const validate = (values) => {
   const errors = {};
   if (!values.name) {
      errors.name = "Обязательное поле";
   }

   if (!values.email) {
      errors.email = 'Обязательное поле'
   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Неверный e-mail'
   }

   if (!values.password) {
      errors.password = "Обязательное поле";
   }
   return errors;
};
