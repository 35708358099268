import React from "react";
import Icon from "../../assets/img/icon/Save.png";

export const ButtonSave = (props) => (
   <button
      className="btn btn--green btn--no-border flex flex--ac"
      title="Сохранить"
      onClick={props.onClick}
      type={props.type}
      {...props}
   >
      <img src={Icon} alt="" className="item-indent" aria-hidden="true" />
      Сохранить
   </button>
);
