import React from "react";
import { Field } from "redux-form";
import { RenderInput } from "../../../Fields/RenderInput";
import { RenderSelect } from "../../../Fields/RenderSelect";

export const balcony = [
   { value: "Застеклен", label: "Застеклен" },
   { value: "Не застеклен", label: "Не застеклен" },
];

export const Apartment = () => {
   return (
      <>
         <div className="feature__item flex">
            <div className="feature__sub feature-mr feature__sub--bg">
               <label className="form__s-title x-medium" htmlFor="total-area">
                  Общая площадь, м²
               </label>
            </div>
            <div className="feature__sub">
               <Field
                  id="total_area"
                  name="total_area"
                  className="control control--custom"
                  component={RenderInput}
               />
            </div>
         </div>

         <div className="feature__item flex">
            <div className="feature__sub feature-mr feature__sub--bg">
               <label className="form__s-title x-medium" htmlFor="houseroom">
                  Жилая площадь, м²
               </label>
            </div>
            <div className="feature__sub">
               <Field
                  id="living_area"
                  name="living_area"
                  className="control control--custom"
                  component={RenderInput}
               />
            </div>
         </div>

         <div className="feature__item flex">
            <div className="feature__sub feature-mr feature__sub--bg">
               <label className="form__s-title x-medium" htmlFor="kitchen-area">
                  Площадь кухни, м²
               </label>
            </div>
            <div className="feature__sub">
               <Field
                  id="kitchen_area"
                  name="kitchen_area"
                  className="control control--custom"
                  component={RenderInput}
               />
            </div>
         </div>

         <div className="feature__item flex">
            <div className="feature__sub feature-mr feature__sub--bg">
               <label className="form__s-title x-medium" htmlFor="room">
                  Комнат
               </label>
            </div>
            <div className="feature__sub">
               <Field
                  id="rooms"
                  name="rooms"
                  className="control control--custom"
                  component={RenderInput}
               />
            </div>
         </div>

         <div className="feature__item flex">
            <div className="feature__sub feature-mr feature__sub--bg">
               <span className="form__s-title x-medium">Этаж</span>
            </div>
            <div className="feature__sub flex flex--ac">
               <div className="feature__sub-item feature-mr">
                  <label className="visually-hidden" htmlFor="level">
                     Этаж
                  </label>
                  <Field
                     id="floor"
                     name="floor"
                     className="control control--custom"
                     component={RenderInput}
                  />
               </div>
               <span className="feature-mr">из</span>
               <div className="feature__sub-item">
                  <label className="visually-hidden" htmlFor="level-end">
                     Количество этажей
                  </label>
                  <Field
                     id="floors"
                     name="floors"
                     className="control control--custom"
                     component={RenderInput}
                  />
               </div>
            </div>
         </div>

         <div className="feature__item flex">
            <div className="feature__sub feature-mr feature__sub--bg">
               <label className="form__s-title x-medium" htmlFor="porch">
                  Подъезд
               </label>
            </div>
            <div className="feature__sub">
               <Field
                  id="entrance"
                  name="entrance"
                  className="control control--custom"
                  component={RenderInput}
               />
            </div>
         </div>
         <div className="feature__item flex">
            <div className="feature__sub feature-mr feature__sub--bg">
               <label className="form__s-title x-medium" htmlFor="balcony">
                  Балкон, м2
               </label>
            </div>
            <div className="feature__sub-item feature-mr">
               <label className="visually-hidden" htmlFor="level">
                  Тип балкона
               </label>
               <Field
                  id="balcony"
                  name="balcony"
                  options={balcony}
                  placeholder="Выберите"
                  component={RenderSelect}
               />
            </div>
            <div className="feature__sub-item">
               <label className="visually-hidden" htmlFor="level-end">
                  Площадь балкона
               </label>
               <Field
                  id="balcony_area"
                  name="balcony_area"
                  className="control control--custom"
                  component={RenderInput}
               />
            </div>
         </div>
      </>
   );
};
