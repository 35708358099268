import React, {useEffect, useState} from "react";
import { Header } from "../Header/Header";
import { BasisInformation } from "./BasisInformation/BasisInformation";
import { EmployerListContainer } from "./Employer/EmployerListContainer";
import { ManagersContainer } from "./Managers/ManagersContainer";
import { TabsContent } from "../Tabs/TabsContent";
import API from "../../utils/API";
import { useRouteMatch } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getAllWorkers} from "../../actions/workerActions";
import {getManagers} from "../../actions/managersActions";

export const SettingsCity = () => {
   let { params } = useRouteMatch();
   let id = params.id;
   const dispatch = useDispatch();

   const [contact,setContact] = useState(false);
   const [activeTab, setActiveTab] = useState(0);

   const workers = useSelector(state => state.worker.data);
   const managers = useSelector(state => state.manager.data);

   useEffect(() => {
      if(id)
      {
         dispatch(getAllWorkers(id));
         dispatch(getManagers(id));
      }

      API.get("city/"+id)
               .then((res) => {
                  if(res.data)
                     setContact(res);
               })
               .catch((err) => {
                  console.log(err);
               });

   },[id]);

   console.log(id,contact);

   const tabs = [
      {
         label: "Общая",
         components: contact && <BasisInformation info={contact} id={id} />,
      },
      {
         label: "Сотрудники",
         components: <EmployerListContainer workers={workers} />,
      },
      {
         label: "Менеджеры",
         components: <ManagersContainer setTab={() => setActiveTab(0)} managers={managers} />,
      },
   ];

   return (
      <>
         {
            (contact && contact.data) &&
               <Header title={`Редактировать ${contact.data.name}`} />
         }

         <TabsContent handleSave={setActiveTab} activeTab={activeTab} tabs={tabs} />
      </>
   );
};
