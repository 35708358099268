export const statuses = [
      {
         label: "Продан",
         value: "1",
      },
      {
         label: "В продаже",
         value: "2",
      }, {
         label: "Забронирован",
         value: "3",
      },
   ]
