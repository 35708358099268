import React, {useEffect, useState} from "react";
import {CanvasUpdateWithoutBlank} from "../Canvas/CanvasCreate/CanvasUpdateWithoutBlank";
import {useSelector} from "react-redux";

export const CanvasContainer = ({ file = false, handleSave }) => {
   const tplFloor  = useSelector(state => state.floor.tplFloor);
   const [points, setPoints] = useState(false);

   useEffect(() => {
      if(tplFloor.plan)
         setPoints(JSON.parse(tplFloor.plan));
   },[tplFloor]);

   return (
      <>
         <div className="flex">
            <CanvasUpdateWithoutBlank
               pointerObject={points}
               handleSave={handleSave}
               image={file ? typeof file === 'object' ? URL.createObjectURL(file) : file : false}
            />
         </div>
      </>
   );
};
