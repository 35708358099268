import React, {useEffect} from "react";
import { Header } from "../Header/Header";
import { TableList } from "../TableList/TableList";
import {useDispatch, useSelector} from "react-redux";
import {deleteFeedback, receiveFeedbacks} from "../../actions/feedbackAction";
import {getFeedbacks} from "../../selectors/feedback-selectors";
import {Pagination} from "../Pagination/Pagination";

export const FeedbackListOntainer = () => {
   const dispatch = useDispatch();

   let head = ["ID", "Имя", "Email", "Тест отзыва", "Опубликован", "Ссылки"];
   let list = useSelector(getFeedbacks);

   useEffect(() => {
      dispatch(receiveFeedbacks());
   }, []);

   const getList = (page) => {
      dispatch(receiveFeedbacks({page}));
   }

   return (
      <>
         <Header title="Отзывы" />
         <div className="card">
            <TableList
               head={head}
               list={list.data}
               handleDelete={deleteFeedback}
               uncialURL={'feedback/update'}
            />
            {
               list.meta &&
               <Pagination
                  onSetPage={getList}
                  pageCount={list.meta.pageCount}
                  currentPage={list.meta.currentPage}
                  itemCount={list.data.length}
                  totalCount={list.meta.totalCount}
               />
            }
         </div>
      </>
   );
};
