import React from "react";
import { Link } from "react-router-dom";

export const ButtonEdit = ({ url }) => (
   <Link
      to={url || '#'}
      className="btn-option btn-reset"
      title="Редактировать"
      aria-label="Редактировать"
   >
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="22"
         height="22"
         viewBox="0 0 22 22"
         fill="none"
      >
         <path
            d="M16.6 1L1.51429 16.3936L1 21L5.91429 20.8834L21 5.4898L16.6 1Z"
            stroke="black"
         />
      </svg>
   </Link>
);
