import React, {useEffect, useState} from "react";
import { Header } from "../../Header/Header";
import { useRouteMatch } from "react-router-dom";
import { TableList } from "../../TableList/TableList";
import Filter from "./Filter";
import {useDispatch, useSelector} from "react-redux";
import {deleteQuestion, receiveQuestions, receiveThemes} from "../../../actions/questionsActions";
import {Pagination} from "../../Pagination/Pagination";

export const QuestionsListContainer = () => {
   const { questions } = useSelector(state => state.review);

   const [filter, setFilter] = useState(null);

   const dispatch = useDispatch();

   let { url } = useRouteMatch();

   const getQuestions = (page) => {
      dispatch(receiveQuestions(url,filter,page));
   }

   useEffect(() => {
         dispatch(receiveQuestions(url,filter));
         dispatch(receiveThemes());
   },[filter]);

   let head = [
      "ID",
      "Имя",
      "Email",
      "Тема вопроса",
      "Вопрос",
      "Опубликован",
      "Ссылки",
   ];

   return (
      <>
         <Header title="Вопрос-ответ" />

         <div className="card">
            <Filter setFilter={setFilter} />
            <TableList handleDelete={deleteQuestion} uncialURL={`${url}/update`} head={head} list={questions.items || []} />


            {
               questions.meta &&
               <Pagination
                  onSetPage={getQuestions}
                  pageCount={questions.meta.pageCount}
                  currentPage={questions.meta.currentPage}
                  itemCount={questions.items.length}
                  totalCount={questions.meta.totalCount}
               />
            }

         </div>
      </>
   );
};
