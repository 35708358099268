import React from "react";
import {Link, useRouteMatch} from "react-router-dom";
import UploadPlan from "../UploadPlan/UploadPlan";
import {IconPlus} from "../Icons/IconPlus";
import {RightList} from "./RightList";
import {CanvasPreviewWithoutBlank} from "../Canvas/CancasPreview/CancasPreviewWithoutBlank";
import {deleteTplApart, setFloor} from "../../actions/floorActions";
import {useDispatch, useSelector} from "react-redux";

export const CanvasContainer = ({ file = false, setImage, tplaparts = false }) => {
   const { url }  = useRouteMatch();
   const dispatch  = useDispatch();
   const floor = useSelector(state => state.floor.data);

   const handleDelete = (data) => {
      deleteTplApart(data.id)
         .then(r => {
            dispatch(setFloor({
               ...floor,
               tplaparts: floor.tplaparts.filter((item,index) => index !== data.index)
            }));
         });
   }

   return (
      <>
         <div className="ling-group ling-group--mb">
            <Link
               to={`${url}/tplapart/create`}
               className="flex float--right flex--ac icon--btn btn btn--mw btn--green item-indent"
            >
               <IconPlus opacity={1} fill="#ffffff" stroke="#ffffff" />

               Добавить квартиру
            </Link>
            <UploadPlan
               onSubmit={(value) => {
                  setImage(value);
               }}
            />
         </div>
         <div className="flex">
            <CanvasPreviewWithoutBlank
               pointsObjects={tplaparts}
               image={file ? typeof file === 'object' ? URL.createObjectURL(file) : file : false}
            />
            <RightList
               handleDelete={handleDelete}
               list={tplaparts || []}
               links={
                  {
                     edit: `${url}/tplapart/update`,
                  }
               }
            />
         </div>
      </>
   );
};
