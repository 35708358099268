import React, {useEffect, useState} from "react";
import Form from "./Form";
import {useDispatch} from "react-redux";
import {updateFeedback} from "../../../actions/feedbackAction";

export const FeedbackEdit = ({match}) => {
   const [published, setPublished] = useState({});

   const onSubmit = (formData) => {
      updateFeedback(match.params.id, {...formData, published});
   }

   const handlePublish = () => setPublished(1);

   const handleUnpublish = () => setPublished( 0);

   return (
      <div className="card">
         <Form
            handleUnpublish={handleUnpublish}
            id={match.params.id}
            onSubmit={onSubmit}
            handlePublish={handlePublish}
         />
      </div>
   );
};
