import React from "react";
import {Link} from "react-router-dom";
import { ButtonDelete } from "../Buttons/ButtonDelete";
import { ButtonEdit } from "../Buttons/ButtonEdit";

export const RightList = ({ links, list = [], handleDelete }) => {
   return (
      <div className="right-list">
         <div className="right-list__head">Название</div>
         <div className="right-list__body">
                  {
                    list.map((item,index) =>
                        <div key={index} className="list flex flex--sb flex--ac">
                           {
                              item.id && links.update
                              ?
                                 (
                                    <Link to={`${links.update || ''}/${item.id}`} className="list__name">
                                       {item.name}
                                    </Link>
                                 )
                              :(
                                    <div>
                                       {item.name}
                                    </div>
                               )
                           }
                           <div className="list__button">
                              <ButtonEdit
                                 url={`${links.edit || ''}/${item.id}`}
                                 house={item}
                              />
                              <ButtonDelete
                                 onClick={() => handleDelete({index,id:item.id || false})}
                                 w={24}
                                 h={24}
                              />
                           </div>
                        </div>
                     )
                  }

         </div>
      </div>
   );
};
