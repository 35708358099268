import React, {useEffect, useState} from "react";
import {LayoutFormBasis} from "../../../../Fields/LayoutForm";
import {Field, reduxForm} from "redux-form";
import {RenderInput} from "../../../../Fields/RenderInput";
import {RenderImage} from "../../../../Fields/RenderImage";
import {WYSIWYGEditor} from "../../../../Fields/WYSIWYGEditor";
import {ButtonBack} from "../../../../Buttons/ButtonBack";
import {ButtonSave} from "../../../../Buttons/ButtonSave";
import {RenderDropzone} from "../../../../Fields/RenderDropzone";
import {useDispatch} from "react-redux";
import {getCurrentNews} from "../../../../../actions/newsAction";

const Form = ({handleSubmit, setMediaData, files, setFiles, id, initialize, setDeleteIDs}) => {

   const [lengthText, setLengthText] = useState(0);
   const dispatch = useDispatch();

   const handleLengthChange = (e) => {
      const count = e.target.value.length;
      setLengthText(count);
   };


   useEffect(() => {
      dispatch(getCurrentNews(id, initialize, setFiles, setMediaData));
   }, []);

   const dateNormalize = (value) => {
      return Date.parse(value) / 1000;
   };

   const dateFormatter = (number) => {
      const date = number ? number : Date.now() / 1000;
      return ("" + (new Date(date * 1000)).toISOString()).replace(/T(.+)$/,'')
   };

   return (
      <form className="form" onSubmit={(e) => handleSubmit(e)}>
         <LayoutFormBasis id="title" title="Заголовок:">
            <div className="form__item form__item--all">
               <Field
                  id="title"
                  name="name"
                  className="control"
                  component={RenderInput}
                  placeholder="Название новости"
                  maxLength="90"
                  onChange={handleLengthChange}
                  required
               />
               <p className="mb-none min red-txt form__txt">{lengthText}/90</p>
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis id="preview" title="Превью">
            <div className="form__item form__item--min">
               <Field name="image"
                      id="image"
                      component={RenderImage}
                      setMediaData={setMediaData}
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis label={false} id="group-galley" title="Галерея">
            <div className="form__group">
               <RenderDropzone files={files} setFiles={setFiles} setDeleteIDs={setDeleteIDs}/>
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis id="date_create" title="Дата публикации:">
            <div className="form__item">
               <Field
                  id="date_create"
                  name="date_create"
                  type="date"
                  className="control"
                  component={RenderInput}
                  format={dateFormatter}
                  normalize={dateNormalize}
                  required
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis label={false} id="description-group" title="Описание">
            <div className="form__item form__item--all">
               <Field
                  component={WYSIWYGEditor}
                  id="description"
                  name="text"
                  type="text"
                  required
               />
            </div>
            <Field
               id="published"
               name="published"
               type="hidden"
               className="control"
               component={RenderInput}
               required
            />
         </LayoutFormBasis>
         <div className="form__foot button-group flex">
            <ButtonBack to={'/pages/news'} />
            <ButtonSave/>
         </div>
      </form>
   );
};

export default reduxForm({
   form: "news-form",
})(Form);
