import {floorConstants} from "../../constants/floorConstants";

const initialState = {
   data: {},
   hasError: false,
   tplFloor: false,
}

export const floorReducer = (state = initialState, action) => {
   switch (action.type) {
      case floorConstants.SET_FLOOR:
         return {
            ...state,
            data: action.payload.data,
         }
      case floorConstants.CLEAR_FLOOR:
         return {
            ...state,
            data: {},
         }
      case floorConstants.SET_TPL_APART:
         return {
            ...state,
            tplFloor: action.payload.data,
         }
      case floorConstants.CLEAR_TPL_APART:
         return {
            ...state,
            tplFloor: false,
         }
      default:
         return state;
   }
}
