import React from "react";

import { OfficesListContainer } from "../Offices/List/OfficesListContainer";
import { TabsContent } from "../../Tabs/TabsContent";

export const Tabs = (props) => {
   const tabs = [
      {
         label: "Общая",
         components: props.content,
      },
      {
         label: "Офисы",
         components: <OfficesListContainer />,
      },
   ];

   return (
      <div className="card">
         <TabsContent activeTab={1} tabs={tabs} />
      </div>
   );
};
