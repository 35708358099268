import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

export const AntTabs = withStyles({
   indicator: {
      backgroundColor: "transparent",
   },
})(Tabs);

export const AntTab = withStyles((theme) => ({
   root: {
      fontSize: 18,
      minWidth: 160,
      border: "1px solid #d2cdcd",
      textTransform: "none",
      opacity: 1,
      transition: "all .3s ease",
      "&:hover": {
         color: "#fff",
         background: "#3f3d3d",
      },
      "&$selected": {
         color: "#fff",
         background: "#3f3d3d",
      },
      "&:focus": {
         color: "#fff",
         background: "#252732",
      },
   },
   selected: {},
}))((props) => <Tab disableRipple {...props} />);

/* panel */

export const TabPanel = ({ children, value, index, ...other }) => (
   <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
   >
      <Box className="tab-content">{children}</Box>
   </div>
);

export const a11yProps = (index) => {
   return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
   };
};
