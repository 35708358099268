import { managerConstants } from "../../constants/managerConstants";

const initialState = {
   data: {},
};

export function managerReducer(state = initialState, action) {
   switch (action.type) {
      case managerConstants.FETCHING_MANAGERS:
         return {
            ...state,
            data: action.payload.data
         };
      default:
         return state;

   }
}
