import React, {useEffect, useState} from "react";
import { RenderSelect } from "../../Fields/RenderSelect";
import { Field, reduxForm } from "redux-form";
import {useSelector} from "react-redux";

export const Filter = ({setFilter}) => {

   const [select, setSelect] = useState(false);
   const { themes } = useSelector(state => state.review);

   useEffect(() => {
      if(themes)
         setSelect(themes.filter(item => item !== "null").map(item => ({label: item, value: item})));
   },[themes]);

   return (
      <form className="form">
         <div className="form__item form__item-mb form__item--min">
            <Field
               id="district"
               name="district"
               placeholder="Выберите"
               component={RenderSelect}
               options={select}
               onChange={setFilter}
            />
         </div>
      </form>
   );
};

export default reduxForm({
   form: "filter-form",
})(Filter);
