import React from "react";
import { Field, reduxForm } from "redux-form";
import {LayoutFormBasis} from "../Fields/LayoutForm";
import {RenderInput} from "../Fields/RenderInput";
import {ButtonBack} from "../Buttons/ButtonBack";
import {ButtonSave} from "../Buttons/ButtonSave";

const Form = ({handleSubmit}) => {
   return (
      <form className="form" onSubmit={handleSubmit}>
         <div className="form-feedback__head form__control form__border">
            <div className="form__item">
               <b>Добавление пользователя</b>
            </div>
         </div>
         <LayoutFormBasis title="E-mail:" id="email">
            <div className="form__item form__item--min">
               <Field
                  id="email"
                  name="email"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="Пароль:" id="password">
            <div className="form__item form__item--min">
               <Field
                  id="password"
                  name="password"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="Имя:" id="username">
            <div className="form__item form__item--min">
               <Field
                  id="username"
                  name="username"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="Фамилия:" id="surname">
            <div className="form__item form__item--min">
               <Field
                  id="surname"
                  name="surname"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis title="Телефон:" id="phone">
            <div className="form__item form__item--min">
               <Field
                  id="phone"
                  name="phone"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>
         <div className="form__foot button-group flex">
            <ButtonBack to={`/users`} />
            <ButtonSave />
         </div>
      </form>
   );
};

const validate = (values) => {
   const errors = {};
   if (!values.username) {
      errors.username = "Поле обязательно для заполнения";
   }
   if (!values.password ) {
      errors.password  = "Поле обязательно для заполнения";
   }
   if (!values.email ) {
      errors.email  = "Поле обязательно для заполнения";
   }
   return errors;
};

export default reduxForm({
   form: "user-form-create",
   validate,
})(Form);
