import React, {useEffect, useState} from "react";
import { Header } from "../Header/Header";
import { TableList } from "../TableList/TableList";
import {Pagination} from "../Pagination/Pagination";
import API from "../../utils/API";
import {dateFormat} from "../../utils/helpers";

export const ApplicationParkingListContainer = () => {
   const [list, setList] = useState({
      items: []
   });

   const getList = (page = 1) => {
      API.get(`requestparking`, {params: {page}})
         .then(({data}) => {
            let dataList = data.items.map(item => ({
               id: item.id,
               type: item.type,
               name: item.name ,
               phone: item.phone,
               city: item.city,
               complex: item.parking,
               level: item.parkinglvl,
               number: item.parkingplace,
               date: dateFormat(item.date_create),
               links: {
                  delete: true,
               },
            }));

            setList({
               meta: data._meta,
               items: dataList
            });
         });
   }

   const handleDelete = (e) => (dispatch) => {
      API.delete(`requestparking/delete/${e}`)
         .then((r) => {
            if(r)
               getList();
         });
   }

   useEffect(() => {
      getList();
   },[]);

   let head = [
      "Id",
      "Тип заявки",
      "Имя",
      "Тел.",
      "Город",
      "Название объекта",
      "Уровень",
      "Номер",
      "Дата заявки",
      "Ссылки",
   ];

   return (
      <>
         <Header title="Паркинги и офисы: бронь" />
         <div className="card">
            <TableList handleDelete={handleDelete} head={head} list={list.items} />
            {
               list.meta &&
               <Pagination
                  onSetPage={getList}
                  pageCount={list.meta.pageCount}
                  currentPage={list.meta.currentPage}
                  itemCount={list.items.length}
                  totalCount={list.meta.totalCount}
               />
            }
         </div>
      </>
   );
};
