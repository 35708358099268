import React, { useEffect, useState } from "react";

import API from "../../../utils/API";
import { City } from "./City";

import "./Background.scss";

export const Background = () => {
   const [cities, setCities] = useState([]);

   useEffect(() => {
      API.get("city")
         .then((res) => {
            setCities(res.data.items);
         })
         .catch((error) => {
            console.log(error);
         });
   }, []);

   return (
      <div className="background-settings">
         <h2>Фон на главной странице городов</h2>
         <div className="background-settings__row">
            {cities.map((city) => (
               <City key={city.id} {...city} />
            ))}
         </div>
      </div>
   );
};
