import React from "react";

export const LayoutFormBasis = (props) => {
   return props.label === false ? (
      <div
         className="form__border flex"
         role="group"
         aria-labelledby={props.id}
         style={props.style ? props.style : null}
      >
         <div id={props.id} className="form__title form__s-title">
            {" "}
            {props.title}{" "}
         </div>
         {props.children}
      </div>
   ) : (
      <div className="form__border flex"
      style={props.style ? props.style : null}
      >
         <label htmlFor={props.id} className="form__title form__s-title">
            {props.title}
         </label>
         {props.children}
      </div>
   );
};
