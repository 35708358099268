import React from "react";
import { Field } from "redux-form";
import { RenderInput } from "../../../Fields/RenderInput";

export const Cottage = () => {
   return (
      <>
         <div className="feature__item flex">
            <div className="feature__sub feature-mr feature__sub--bg">
               <label className="form__s-title x-medium" htmlFor="total-area">
                  Общая площадь, м²
               </label>
            </div>
            <div className="feature__sub">
               <Field
                  id="total_area"
                  name="total_area"
                  className="control control--custom"
                  component={RenderInput}
               />
            </div>
         </div>

         <div className="feature__item flex">
            <div className="feature__sub feature-mr feature__sub--bg">
               <label className="form__s-title x-medium" htmlFor="houseroom">
                  Жилая площадь, м²
               </label>
            </div>
            <div className="feature__sub">
               <Field
                  id="living_area"
                  name="living_area"
                  className="control control--custom"
                  component={RenderInput}
               />
            </div>
         </div>

         <div className="feature__item flex">
            <div className="feature__sub feature-mr feature__sub--bg">
               <label className="form__s-title x-medium" htmlFor="kitchen-area">
                  Площадь кухни, м²
               </label>
            </div>
            <div className="feature__sub">
               <Field
                  id="kitchen_area"
                  name="kitchen_area"
                  className="control control--custom"
                  component={RenderInput}
               />
            </div>
         </div>

         <div className="feature__item flex">
            <div className="feature__sub feature-mr feature__sub--bg">
               <label className="form__s-title x-medium" htmlFor="room">
                  Комнат
               </label>
            </div>
            <div className="feature__sub">
               <Field
                  id="rooms"
                  name="rooms"
                  className="control control--custom"
                  component={RenderInput}
               />
            </div>
         </div>

         <div className="feature__item flex">
            <div className="feature__sub feature-mr feature__sub--bg">
               <label className="form__s-title x-medium" htmlFor="land">
                  Земельный участок (в сотках)
               </label>
            </div>
            <div className="feature__sub">
               <Field
                  id="plot_area"
                  name="plot_area"
                  className="control control--custom"
                  component={RenderInput}
               />
            </div>
         </div>
      </>
   );
};
