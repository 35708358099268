import React from "react";
import { Field, reduxForm } from "redux-form";
import { RenderInput } from "../../Fields/RenderInput";
import { LayoutFormBasis } from "../../Fields/LayoutForm";
import { ButtonBack } from "../../Buttons/ButtonBack";
import { ButtonSave } from "../../Buttons/ButtonSave";
import { WYSIWYGEditor } from "../../Fields/WYSIWYGEditor";
import { publishedOptions } from "../../Fields/options";
import { RenderSelect } from "../../Fields/RenderSelect";
import { validate } from "../../Fields/validate";
import { useHistory } from "react-router-dom";
import {RenderImage} from "../../Fields/RenderImage";


const Form = ({ handleSubmit, setMediaData }) => {
   let history = useHistory();
   return (
      <form className="form form--basis" onSubmit={(e) => handleSubmit(e)}>
         <div className="card-wrapper flex">
            <div className="card-content">
               <div className="card">
                  <fieldset className="fieldset">
                     <legend className="title-bold">Контактные данные</legend>
                     <LayoutFormBasis title="Наименование:" id="name">
                        <div className="form__item form__item--all">
                           <Field
                              id="name"
                              name="name"
                              className="control"
                              component={RenderInput}
                           />
                        </div>
                     </LayoutFormBasis>
                     <LayoutFormBasis title="Телефон в шапке:" id="phone">
                        <div className="form__item form__item--all">
                           <Field
                              id="phone"
                              name="phone"
                              className="control"
                              component={RenderInput}
                           />
                        </div>
                     </LayoutFormBasis>
                     <LayoutFormBasis
                        label={false}
                        title="Информация:"
                     >
                        <div className="form__item form__item--all">
                           <Field
                              component={WYSIWYGEditor}
                              name="info"
                              type="text"
                           />
                        </div>
                     </LayoutFormBasis>
                  </fieldset>
                  <div className="form__foot button-group flex">
                     <ButtonBack onClick={() => history.goBack()} />
                     <ButtonSave />
                  </div>
               </div>
            </div>
            <div className="card-content">
               <div className="card card--custom card--md">
                  <LayoutFormBasis title="Карта:" id="map-container">
                     <div className="form__item form__item--all">
                        <Field
                           id="map"
                           name="map"
                           className="control"
                           component={RenderInput}
                        />
                     </div>
                  </LayoutFormBasis>
               </div>

               <div className="card card--custom">
                  <LayoutFormBasis id="qr" title="QR-код">
                     <div className="form__item form__item--min">
                        <Field
                           name="qr"
                           id="qr"
                           dop="true"
                           component={RenderImage}
                           setMediaData={setMediaData}
                           handleDelete={false}
                        />
                     </div>
                  </LayoutFormBasis>
               </div>
            </div>
         </div>
      </form>
   );
};

export default reduxForm({
   form: "city-setting",
   destroyOnUnmount: true,
   validate
})(Form);
