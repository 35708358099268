import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.svg";
import { MainMenu } from "../Menu/Menu";
import {useDispatch} from "react-redux";
import {Logout} from "../../actions/adminAction";
import {receiveQuestionsTotal, receiveRequestsTotal, receiveReviewsTotal} from "../../actions/noticeActions";

export const Aside = () => {
   const dispatch = useDispatch();

   const handleLogOut = () => {
     dispatch(Logout())
   };
   dispatch(receiveQuestionsTotal());
   dispatch(receiveRequestsTotal());
   dispatch(receiveReviewsTotal());
   return (
      <aside className="side-menu">
         <Link to="/" className="side-menu__head">
            <img src={Logo} alt="Докмор" />
         </Link>
         <MainMenu handleLogOut={handleLogOut} />
      </aside>
   );
};
