import React from "react";

export const Header = ({ title }) => {
   return (
      <header className="header">
         <div className="header__inline flex flex--sb">
            <h1>{title}</h1>
            <p className="mb">Разработано студией SALAMAT</p>
         </div>
      </header>
   );
};
