import React, {useEffect} from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Header } from "../../Header/Header";
import { TableList } from "../../TableList/TableList";
import {useDispatch, useSelector} from "react-redux";
import {deleteComplex, receiveComplex} from "../../../actions/complexActions";
import {getComplexSelect} from "../../../selectors/complex-selectors";

export const ResidentialComplexContainer = () => {
   let { url } = useRouteMatch();

   let head = ["ID", "Название", "Город", "Ссылки"];
   let list = useSelector(getComplexSelect)
   const dispatch = useDispatch();


   useEffect(() => {
      dispatch(receiveComplex());
   }, [dispatch])

   return (
      <>
         <Header title="Новостройки" />
         <div className="card">
            <div className="card__head">
               <Link
                  to={`${url}/create`}
                  className="btn btn--green btn--no-border"
               >
                  Добавить
               </Link>
            </div>
            <TableList head={head} list={list} handleDelete={deleteComplex} uncialURL={'residential-complex/update'} />
         </div>
      </>
   );
};
