import React from "react";
import "./assets/style/app.scss";
import { Router, Route, Switch } from "react-router-dom";
import { history } from "./utils/history";
import { Aside } from "./components/Aside/Aside";
import { routes } from "./config/routes";
import {Login} from "./components/Login/Login";
import {PrivateRoute} from "./config/PrivateRoute";

export function App() {
   return (
      <Router history={history}>
         <Switch>
            <Route path="/login">
               <Login />
            </Route>

            <PrivateRoute path="/">
               <div className="wrapper flex">
                  <Aside />
                  <main>
                     <Switch>
                        {routes.map((route, i) => (
                           <RouteWithSubRoutes key={i} {...route} />
                        ))}
                     </Switch>
                  </main>
               </div>
            </PrivateRoute>
         </Switch>
      </Router>
   );
}

function RouteWithSubRoutes(route) {
   return (
      <Route
         path={route.path}
         render={(props) => (
            // pass the sub-routes down to keep nesting
            <route.component {...props} routes={route.routes} />
         )}
      />
   );
}
