import {noticeConstants} from "../../constants/noticeConstants";

const initialState = {
   questions: 0,
   request: 0,
   reviews: 0
}

export const noticeReducer = (state = initialState, action) => {
   switch (action.type) {
      case noticeConstants.SET_QUESTIONS:
         return {
            ...state,
            questions: action.payload,
         }
      case noticeConstants.SET_REQUEST:
         return {
            ...state,
            request: action.payload,
         }
      case noticeConstants.SET_REVIEWS:
         return {
            ...state,
            reviews: action.payload,
         }
      default:
         return state;
   }
}
