import React from "react";

export const IconUnVisibility = () => (
   <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.96892 18.2244C5.04994 18.2887 5.14681 18.3204 5.24324 18.3204C5.37269 18.3204 5.50082 18.2636 5.588 18.1544C5.73947 17.9642 5.70821 17.6872 5.51799 17.5358C5.1653 17.2548 4.82362 16.9497 4.50307 16.6287L1.24564 13.3713L4.60479 10.0122C7.83404 6.78334 12.5317 5.68124 16.8626 7.13515C17.0929 7.21133 17.3426 7.08804 17.4205 6.85776C17.4976 6.62747 17.3738 6.37737 17.1431 6.29988C12.4925 4.73897 7.4492 5.92253 3.98218 9.38911L0 13.3713L3.88003 17.2513C4.22392 17.5956 4.5907 17.9228 4.96892 18.2244Z" fill="black"/>
      <path d="M22.1198 9.38937C21.5263 8.79583 20.8755 8.26041 20.1851 7.7972C19.9834 7.66203 19.7091 7.71574 19.5739 7.91741C19.4383 8.11907 19.4925 8.39294 19.6941 8.52856C20.337 8.95963 20.9437 9.45894 21.4968 10.012L24.7542 13.2694L21.395 16.6285C18.1433 19.8802 13.2435 20.9797 8.91131 19.4272C8.68235 19.3448 8.43005 19.4642 8.34815 19.6931C8.26625 19.9221 8.38513 20.1739 8.6141 20.2563C10.0152 20.7582 11.4717 21.0017 12.9186 21.0017C16.2742 21.0017 19.5779 19.6909 22.0176 17.2511L25.9994 13.2694L22.1198 9.38937Z" fill="black"/>
      <path d="M13.4466 18.3943C12.4986 18.3943 11.5749 18.131 10.7739 17.6326C10.5683 17.5049 10.2966 17.5674 10.1676 17.7739C10.039 17.9804 10.1025 18.2517 10.309 18.3802C11.249 18.9658 12.3344 19.2749 13.4466 19.2749C16.7243 19.2749 19.3908 16.6084 19.3908 13.3307C19.3908 12.1485 19.0443 11.0063 18.3891 10.0275C18.2535 9.8254 17.9796 9.77124 17.7784 9.90642C17.5763 10.042 17.5222 10.3155 17.6573 10.5171C18.2152 11.3506 18.5102 12.3233 18.5102 13.3307C18.5102 16.1232 16.2386 18.3943 13.4466 18.3943Z" fill="black"/>
      <path d="M13.4466 8.26734C14.3559 8.26734 15.247 8.51084 16.0246 8.97184C16.2333 9.09557 16.5037 9.02688 16.6279 8.81729C16.7516 8.60814 16.6829 8.33823 16.4733 8.21407C15.5601 7.67292 14.5135 7.38672 13.4462 7.38672C10.1685 7.38672 7.50195 10.0532 7.50195 13.3309C7.50195 14.2485 7.70538 15.1287 8.10694 15.9468C8.18356 16.1027 8.33987 16.1934 8.50278 16.1934C8.56751 16.1934 8.634 16.1789 8.69608 16.1485C8.91448 16.0411 9.00474 15.7773 8.89774 15.5594C8.55606 14.8628 8.38258 14.1134 8.38258 13.3314C8.38302 10.5389 10.6546 8.26734 13.4466 8.26734Z" fill="black"/>
      <path d="M24.0505 2.45139C23.8783 2.27923 23.6 2.27923 23.4279 2.45139L2.95334 22.9259C2.78118 23.0981 2.78118 23.3763 2.95334 23.5485C3.0392 23.6344 3.15192 23.6775 3.26464 23.6775C3.37736 23.6775 3.49008 23.6344 3.57594 23.5485L24.0505 3.07399C24.2226 2.90227 24.2226 2.62355 24.0505 2.45139Z" fill="black"/>
   </svg>
);
