import React from 'react';
import {Label, Tag, Text} from 'react-konva';

export const Popup = ({position, title}) => {
   return (
      <Label
         position={{x: position.x, y: position.y}}
      >
         <Tag
            fill='#f6b800'
            pointerDirection='down'
            pointerWidth={5}
            pointerHeight={5}
         />
         <Text
            text={title}
            fontSize={14}
            padding={10}
            fontStyle="bold"
            fill='white'
            width={150}
            align="center"
         />
      </Label>
   )
};
