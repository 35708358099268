import { Header } from "../../Header/Header";
import { TabsSettings } from "./TabsSettings";
import React, {useState} from "react";

export const UpdateBuild = () => {

   const [isEditMode, setIsEditMode] = useState(true);

   return (
      <>
         <Header title="Редактировать новостройку" />
         <TabsSettings isEditMode={isEditMode}/>
      </>
   );
};
