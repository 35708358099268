import React, {useEffect, useState} from "react";
import { TableList } from "../../../../TableList/TableList";
import { Link, useRouteMatch } from "react-router-dom";
import {useSelector} from "react-redux";
import {deleteEntrance} from "../../../../../actions/complexActions";

export const PorchAll = () => {
   let { url } = useRouteMatch();

   const house = useSelector(state => state.complex.house);

   let head = ["ID", "Подъезд", "Ссылки"];
   const [list, setList] = useState([]);

   const handleDelete = (e) => (dispatch) => {
      dispatch(deleteEntrance(e))
   }

   useEffect(() => {
      if(house && house.entrances)
         setList(house.entrances.map((item, index) => (
            {
               id: item.id || null,
               porch: item.name,
               links:{
                  delete: true,
                  hide: true,
                  edit: `/edit-link/${index}`,
               }
            }
         )));
   },[house]);

   return (
      <>
         <div className="card__head card__head--border card__head--mb flex flex--sb flex--ac">
            <h2 className="dark mb-none">Подъезд</h2>
            <Link
               to={`${url}/porch/create`}
               className="btn btn--green btn--no-border"
            >
               + Добавить
            </Link>
         </div>
         <TableList head={head} list={list} urlEdit={`porch/edit`} uncialURL={`porch/update`} customItem={true} handleDelete={handleDelete} />
      </>
   );
};
