import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { useHistory } from "react-router-dom";

import { receiveCities } from "../../../actions/cityAction";
import { Header } from "../../Header/Header";
import { Tabs } from "./Tabs";
import { BasisForm } from "./BasisForm";

import {
   receiveCurrentCommercialProperty,
   updateCommercialProperty,
} from "../../../actions/commercialPropertyAction";
import { receiveComplex, receiveHouse } from "../../../actions/complexActions";
import { createMedia, deleteMedia } from "../../../actions/media";

import { getCitiesForFormSelector } from "../../../selectors/city-selectors";
import {
   getComplexSelect,
   getHouseListSelect,
} from "../../../selectors/complex-selectors";

import { isEmpty } from "../../../utils/utils";

const FORM_NAME = "update-form-commercial-property";

const validate = (values) => {
   const errors = {};
   if (!values.area) {
      errors.area = "Поле обязательно для заполнения";
   }
   if (!values.city) {
      errors.city = "Поле обязательно для заполнения";
   }
   return errors;
};

const Form = reduxForm({
   form: FORM_NAME,
   enableReinitialize: true,
   validate,
})(BasisForm);

const selector = formValueSelector(FORM_NAME);

const CommercialForm = connect((state) => {
   const targetCity = selector(state, "city");
   return {
      targetCity,
   };
})(Form);

const UpdateCommercialProperty = ({ match }) => {
   const [mediaData, setMediaData] = useState(null);
   const dispatch = useDispatch();
   const history = useHistory();
   const { id } = match.params;

   const complexes = useSelector(getComplexSelect);
   const cities = useSelector(getCitiesForFormSelector);
   const houses = useSelector(getHouseListSelect);

   const commercialProperty = useSelector(
      (state) => state.commercialProperty.currentCommercial
   );
   const complexGroups = complexes.map((complex) => ({
      label: complex?.name,
      options: [],
      id: complex?.id,
   }));
   houses.forEach((house) => {
      const complexId = house.complex_id;
      const currentComplex = complexGroups.find(
         (complex) => complex?.id === complexId
      );
      if (currentComplex) {
         currentComplex.options.push({ value: house.id, label: house.name });
      }
   });
   const houseGroups = complexGroups.filter((group) => group.options.length);

   const onSubmit = async (values) => {
      const formData = new FormData();

      formData.append("name", values.name);

      formData.append("city_id", values.city.value);
      formData.append("name_search", values.name_search);
      formData.append("address", values.address);
      formData.append("description", values.description);
      formData.append("area", values.area.label);
      formData.append("coord", values.coord);

      if(values.house_id && values.house_id.value)
         formData.append("house_id", values.house_id.value);

      if (mediaData) {
         formData.append("image", mediaData);
      }

      commercialProperty.images.forEach((image) => {
         if (!values.gallery_group.find((file) => +file.id === +image.id)) {
            dispatch(deleteMedia(image.id));
         }
      });

      values.gallery_group.forEach((file) => {
         if (!file.id) {
            const galleryFormData = new FormData();
            galleryFormData.append("image", file);
            galleryFormData.append("parent", "comrealty");
            galleryFormData.append("id_parent", id);
            galleryFormData.append("type", "");
            galleryFormData.append("published", 1);
            dispatch(createMedia(galleryFormData));
         }
      });

      await dispatch(updateCommercialProperty(formData, id));
      await dispatch(receiveCurrentCommercialProperty(id));
      history.push(`/commercial-property`);
   };

   const getCurrentHouse = (values, commercial) => {
      const currentHouse = values.find(
         (house) => house.id === Number(commercial.house_id)
      );
      return !isEmpty(currentHouse)
         ? { label: currentHouse.name, value: currentHouse.id }
         : { label: "", value: null };
   };

   const getCurrentCity = (values, commercial) => {
      const currentCity = values.find(
         (house) => house.value === Number(commercial.city_id)
      );
      return !isEmpty(currentCity)
         ? { label: currentCity.label, value: currentCity.value }
         : { label: "", value: "" };
   };

   useEffect(() => {
      if (!cities.length) {
         dispatch(receiveCities());
      }
      if (!complexes.length) {
         dispatch(receiveComplex());
      }
      if (!houses.length) {
         dispatch(receiveHouse());
      }
   }, [cities.length, complexes.length, dispatch, houses.length, id]);

   useEffect(() => {
      if (id && isEmpty(commercialProperty)) {
         dispatch(receiveCurrentCommercialProperty(id));
      }
   }, [commercialProperty, dispatch, id]);

   if (isEmpty(commercialProperty)) return null;
   if (isEmpty(cities)) return null;
   if (isEmpty(houses)) return null;

   return (
      <>
         <Header title="Изменить коммерческую недвижимость" />
         <Tabs
            content={
               <CommercialForm
                  onSubmit={onSubmit}
                  cities={cities}
                  mediaData={mediaData}
                  setMediaData={setMediaData}
                  houseGroups={houseGroups}
                  initialValues={{
                     name: commercialProperty.name,
                     coord: commercialProperty.coord,
                     address: commercialProperty.address,
                     name_search: commercialProperty.name_search,
                     description: commercialProperty.description,
                     preview:
                        "http://dc.websalamat.ru/img/" +
                        commercialProperty.image,
                     gallery_group: commercialProperty.images,
                     house_id: getCurrentHouse(houses, commercialProperty),
                     city: getCurrentCity(cities, commercialProperty),
                     area: {
                        label: commercialProperty.area,
                        value: commercialProperty.area,
                     },
                  }}
               />
            }
         />
      </>
   );
};

export default UpdateCommercialProperty;
