import React from "react";
import { ButtonDelete } from "../../Buttons/ButtonDelete";
import { ButtonEdit } from "../../Buttons/ButtonEdit";
import { useDispatch } from "react-redux";

export const ParkingPlacesList = ({ url, places, handleDelete }) => {
   const dispatch = useDispatch();
   return (
      <div className="right-list">
         <div className="right-list__head flex">
            <div className="right-list__first">Id</div>
            <div>№</div>
         </div>
         <div className="right-list__body">
            {places &&
               places.map((item) => (
                  <div key={item.id} className="list flex flex--ac">
                     <div className="right-list__first">{item.id}</div>
                     <div>{item.name}</div>
                     <div className="right-list__button">
                        <ButtonEdit url={`${url}/update/${item.id}`} />
                        <ButtonDelete
                           onClick={() => dispatch(handleDelete(item.id))}
                           w={24}
                           h={24}
                        />
                     </div>
                  </div>
               ))}
         </div>
      </div>
   );
};
