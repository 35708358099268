import React from "react";
import { IconVisibility } from "../Icons/IconVisibility";

export const ButtonVisibility = (props) => (
   <button
      className="btn-option btn-reset"
      title="Скрыть"
      aria-label="Удалить"
      onClick={props.onClick}
   >
      <IconVisibility />
   </button>
);
