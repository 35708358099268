import { pagesConstants } from "../../constants/pagesConstants";

const initialState = {
   isFetching: false,
   data: [],
};

export function pagesReducer(state = initialState, action) {
   switch (action.type) {
      case pagesConstants.FETCH_PAGES_SUCCESS:
         return {
            ...state,
            data: action.pages,
         };

      case pagesConstants.FETCH_PAGES_FAILURE:
         return {
            ...state,
            data: [],
         };

      case pagesConstants.DELETE_PAGE:
         return {
            ...state,
            data: state.data.filter((element) => element.id !== action.id),
         };

      default:
         return state;
   }
}

const initialStateInfo = {
   isFetching: false,
   data: null,
   inProgress: false,
};

export function pageInfo(state = initialStateInfo, action) {
   switch (action.type) {
      case pagesConstants.FETCH_PAGE_DETAILS_SUCCESS:
         return {
            ...state,
            data: action.page,
            inProgress: false,
            isFetching: false,
         };

      case pagesConstants.IS_FETCHING:
         return {
            ...state,
            isFetching: true,
         };

      case pagesConstants.SET_PAGE_SUCCESS:
         return {
            ...state,
            inProgress: true,
         };

      case pagesConstants.IN_PROGRESS_PAGE:
         return {
            ...state,
            inProgress: false,
         };

      default:
         return state;
   }
}
