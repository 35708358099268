import React, {useEffect} from "react";
import { Header } from "../../Header/Header";
import Form from "../Sections/Form";
import {Redirect, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {editPage, receivePageDetails, createPage} from "../../../actions/pagesAction";
import {Preloader} from "../../Preloader/Preloader";
import {publishedOptions} from "../../Fields/options";

export const ServicesContainer = () => {

   const { path } = useRouteMatch();
   const short = path.split('/').pop();

   const dispatch = useDispatch();
   const info = useSelector((state) => state.pageInfo);

   const handleSubmit = (values) => {
      const { data } = info;
      if (!Object.keys(data).length) {
         dispatch(createPage({
            ...values,
            short: 'services',
         }));
      } else {
         dispatch(editPage('services', values));
      }
   };

   useEffect(() => {
      dispatch(receivePageDetails(short));
   }, [dispatch, short]);

   if (info.inProgress) {
      return <Redirect to={{ pathname: "/pages" }} />;
   }

   if (info.isFetching) {
      return <Preloader />;
   }

   return (
      <>
         <Header title="Редактирование риэлторских услуг" />
         <div className="card">
            <Form
               initialValues={{
                  ...info.data,
                  published: (info && info.data && info.data.published) ? publishedOptions.filter((item) => {
                     return item.value === info.data.published
                  }) : publishedOptions[0],
               }}
               onSubmit={handleSubmit}
            />
         </div>
      </>
   );
};
