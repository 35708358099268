import React from "react";
import { Field, change } from "redux-form";

import { LayoutFormBasis } from "../../Fields/LayoutForm";
import { RenderInput } from "../../Fields/RenderInput";
import { RenderImage } from "../../Fields/RenderImage";
import { RenderDropzoneInput } from "../../Fields/RenderDropzoneInput";
import { RenderSelect } from "../../Fields/RenderSelect";
import { WYSIWYGEditor } from "../../Fields/WYSIWYGEditor";
import { ButtonSave } from "../../Buttons/ButtonSave";
import { ButtonBack } from "../../Buttons/ButtonBack";

import DISTRICTS from "../../../constants/districts";

import { isEmpty } from "../../../utils/utils";

const FORM_NAME = "form-commercial-property";

const NAMES_DISTRICT = Object.keys(DISTRICTS).map((city) => ({
   value: city,
   label: city,
}));

const AREAS_BY_DISTRICT = (city, cities) => {
   if (!isEmpty(city) && !isEmpty(DISTRICTS[city.label])) {
      const targetAreas = DISTRICTS[city.label];
      return targetAreas;
   }
   return [];
};

export const BasisForm = ({
   handleSubmit,
   setMediaData,
   targetCity,
   dispatch,
   cities,
   houseGroups,
}) => {
   return (
      <form className="form form--basis" onSubmit={handleSubmit}>
         <LayoutFormBasis id="name" title="Название превью:">
            <div className="form__item form__item--min">
               <Field
                  id="name"
                  name="name"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis
            id="name-search"
            title="Название для результатов поиска:"
         >
            <div className="form__item form__item--min">
               <Field
                  id="name-search"
                  name="name_search"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis
            id="address-search"
            title="Адрес в результатах поиска:"
         >
            <div className="form__item form__item--min">
               <Field
                  id="address-search"
                  name="address"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis id="preview" title="Превью">
            <div className="form__item form__item--min">
               <Field
                  name="preview"
                  id="preview"
                  dop="true"
                  component={RenderImage}
                  setMediaData={setMediaData}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis label={false} id="gallery_group" title="Галерея">
            <div className="form__group">
               <Field
                  id="gallery_group"
                  name="gallery_group"
                  className="form__group"
                  component={RenderDropzoneInput}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis
            label={false}
            id="district-group"
            title="Район расположения"
         >
            <div className="form__group flex">
               <div className="form__item">
                  <Field
                     id="city"
                     name="city"
                     placeholder="Выберите город"
                     options={cities}
                     onChange={() => dispatch(change(FORM_NAME, "area", ""))}
                     component={RenderSelect}
                  />
               </div>
               <div className="form__item">
                  <Field
                     id="area"
                     name="area"
                     options={AREAS_BY_DISTRICT(targetCity, cities)}
                     placeholder="Выберите"
                     component={RenderSelect}
                  />
               </div>
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis id="address" title="Дом на карте">
            <div className="form__item form__item--min">
               <Field
                  name="coord"
                  id="address"
                  placeholder="Укажите ссылку / координаты / указать на карте"
                  component={RenderInput}
                  className="control"
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis
            label={false}
            id="home-group"
            title="Привязать к дому"
         >
            <div className="form__item form__item--min">
               <Field
                  name="house_id"
                  id="house_id"
                  placeholder="Выберите"
                  component={RenderSelect}
                  options={houseGroups}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis label={false} title="Описание">
            <div className="form__item form__item--all">
               <Field
                  component={WYSIWYGEditor}
                  id="description"
                  name="description"
                  type="text"
               />
            </div>
         </LayoutFormBasis>
         <div className="form__foot button-group flex">
            <ButtonBack />
            <ButtonSave type="submit" />
         </div>
      </form>
   );
};
