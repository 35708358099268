import React, {useEffect, useState} from "react";
import { Header } from "../Header/Header";
import { TableList } from "../TableList/TableList";

import API from "../../utils/API";

export const ProfitBaseListContainer = () => {

   let head = ["ID", "Тип", "Название", "Значение", "Ссылки"];
   const [list, setList] = useState([]);

   useEffect(() => {
      API.get('settings')
         .then(({data}) => {
            const items = [];
            data.items.forEach(review => {
               items.push({
                  id: review.id,
                  type: review.type,
                  title: review.title,
                  value: review.value,
                  links: {
                     edit: `/${review.id}`
                  }
               });
            });
            setList(items);
         }).catch(console.log);
   }, []);

   return (
      <>
         <Header title="ProfitBase" />
         <div className="card table_auto_width">
            <TableList
               uncialURL={'profit-base/update'}
               head={head}
               list={list}
            />
         </div>
      </>
   );
};
