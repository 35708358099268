function limitAttributes(stage, newAttrs) {
   const box = stage.findOne('Image').getClientRect();
   const minX = -box.width + stage.width() / 2;
   const maxX = stage.width() / 2;

   const x = Math.max(minX, Math.min(newAttrs.x, maxX));

   const minY = -box.height + stage.height() / 2;
   const maxY = stage.height() / 2;

   const y = Math.max(minY, Math.min(newAttrs.y, maxY));

   const scale = Math.max(0.05, newAttrs.scale);

   return { x, y, scale };
}

export const ZoomStage  = (stage, scaleBy) => {
   const oldScale = stage.scaleX();


   const pos = {
      x: stage.width() / 2,
      y: stage.height() / 2
   };

   const mousePointTo = {
      x: pos.x / oldScale - stage.x() / oldScale,
      y: pos.y / oldScale - stage.y() / oldScale
   };

   const newScale = Math.max(0.05, oldScale * scaleBy);

   const newPos = {
      x: -(mousePointTo.x - pos.x / newScale) * newScale,
      y: -(mousePointTo.y - pos.y / newScale) * newScale
   };

   const newAttrs = limitAttributes(stage, { ...newPos, scale: newScale });

   stage.to({
      x: newAttrs.x,
      y: newAttrs.y,
      scaleX: newAttrs.scale,
      scaleY: newAttrs.scale,
      duration: 0.1
   });
   stage.batchDraw();
};
