import React from "react";
import { IconPlus } from "../Icons/IconPlus";

export const ButtonAdd = ({ onClick, ...rest }) => (
   <button
      className="btn-option btn-reset btn-del"
      title="Добавить"
      aria-label="Добавить"
      onClick={onClick}
      type="button"
   >
      <IconPlus {...rest} />
   </button>
);
