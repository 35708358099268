import React, { useState } from "react";

export const RenderFile = ({
   input: { value, ...input },
   setMediaData,
   ...custom
}) => {
   const [file, setFile] = useState(null);

   const handleChange = (e) => {
      setFile(e.target.files[0]);
      setMediaData(e.target.files[0]);
   };

   const handleDelete = (e) => {
      e.preventDefault();
      setFile(null);
      setMediaData("");
   };

   return (
      <>
         <div className="upload btn btn--green flex-inline flex--ac item-indent">
            <svg
               xmlns="http://www.w3.org/2000/svg"
               width="26"
               height="18"
               viewBox="0 0 26 18"
               fill="none"
               aria-hidden="true"
               className="item-indent"
            >
               <path
                  d="M20.9625 6.66018C20.2258 2.85594 16.9433 0 13 0C9.86917 0 7.15 1.80839 5.79583 4.45482C2.535 4.80768 0 7.61951 0 11.0268C0 14.6767 2.91417 17.6429 6.5 17.6429H20.5833C23.5733 17.6429 26 15.1729 26 12.1295C26 9.21839 23.7792 6.85866 20.9625 6.66018ZM15.1667 9.92411V14.3348H10.8333V9.92411H7.58333L13 4.41071L18.4167 9.92411H15.1667Z"
                  fill="white"
               />
            </svg>
            Загрузить
            <input
               accept=".docx"
               {...input}
               {...custom}
               onChange={(e) => handleChange(e)}
            />
         </div>

         {typeof value === "string" && value !== "" && (
            <>
               <span className="dark">{value}</span>
               <button
                  className="btn-text btn-reset x-medium"
                  onClick={handleDelete}
               >
                  Удалить
               </button>
            </>
         )}

         {file && (
            <>
               <span className="dark">{file.name}</span>
               <button
                  className="btn-text btn-reset x-medium"
                  onClick={handleDelete}
               >
                  Удалить
               </button>
            </>
         )}
      </>
   );
};
