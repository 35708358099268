import React from "react";
import { Header } from "../../Header/Header";
import { CanvasCreate } from "../../Canvas/CanvasCreate/CanvasCreate";
import Image from "../../../assets/img/plan.jpg";
import {useSelector} from "react-redux";

export const NewBuildCreate = () => {
   const complex = useSelector(state => state.complex.complexDetail);
   return (
      <>
         <Header title="Добавить дом на карте" />
         <div className="card card-create">
            <CanvasCreate image={complex.photoGenPlan || Image} />
         </div>
      </>
   );
};
