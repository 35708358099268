import React from "react";
import { Field, reduxForm } from "redux-form";
import { RenderInput } from "../Fields/RenderInput";
import { LayoutFormBasis } from "../Fields/LayoutForm";

const Form = () => {
   return (
      <form className="form form--basis">
         <fieldset className="fieldset">
            <legend className="title-bold">Безопасность</legend>
            <LayoutFormBasis title="E-mail:" id="email">
               <div className="form__item form__item--min">
                  <Field
                     id="email"
                     name="email"
                     className="control"
                     component={RenderInput}
                  />
               </div>
            </LayoutFormBasis>
            <LayoutFormBasis title="Пароль:" id="password">
               <div className="form__item form__item--min">
                  <Field
                     id="password"
                     name="password"
                     className="control"
                     component={RenderInput}
                  />
               </div>
            </LayoutFormBasis>
         </fieldset>
         <button className="btn btn--green btn--no-border btn--max">
            Сохранить
         </button>
      </form>
   );
};

export default reduxForm({
   form: "setting-form",
   enableReinitialize: true,
})(Form);
