import React, {useEffect, useCallback} from "react";
import {useDropzone} from "react-dropzone";
import {IconPlus} from "../Icons/IconPlus";
import {ButtonDelete} from "../Buttons/ButtonDelete";

export const RenderDropzone = ({id = "design", files = [], setFiles, setDeleteIDs}) => {

   const onDrop = useCallback(acceptedFiles => {
      setFiles(files => {
         let newFiles = [...files];
         let arr = acceptedFiles.map((file) =>
            Object.assign(file, {
               preview: URL.createObjectURL(file),
            })
         )
         newFiles = newFiles.concat(arr);
         return newFiles;
      })
   }, [setFiles])

   const {getRootProps, getInputProps} = useDropzone({
      accept: "image/*",
      onDrop
   });

   const handleDelete = (e, file) => {
      e.preventDefault();
      const newFiles = [...files];
      newFiles.splice(newFiles.indexOf(file), 1);
      if (file.id) {
         setDeleteIDs(file.id);
      }
      setFiles(newFiles);
   };

   const thumbs = files.map((file) => (
         <div className="thumb" key={file.preview || file.id}>
            <img alt="" src={file.preview || `http://dc.websalamat.ru/frontend/web/img/${file.image}`} className="thumb__img"/>
            <ButtonDelete onClick={(e) => handleDelete(e, file)}/>
         </div>
      ));

   useEffect(
      () => () => {
         files.forEach((file) => URL.revokeObjectURL(file.preview));
      },
      [files]
   );

   return (
      <div className="dropzone-group flex">
         <div
            {...getRootProps({
               className: "dropzone flex flex--ac flex--jc-center",
            })}
         >
            <label htmlFor={id} className="visually-hidden">
               Загрузить фотографии
            </label>
            <input id={id} {...getInputProps()} />
            <IconPlus/>
         </div>
         <aside className="flex">{thumbs}</aside>
      </div>
   );
};
