import {feedbackConstants} from "../../constants/feedbackConstants";

const initialState = {data: []};

const feedbackReducer = (state = initialState, action) => {
   switch (action.type) {
      case feedbackConstants.SET_FEEDBACK:
         return {...action.payload};
      default:
         return state;
   }
}

export default feedbackReducer;
