import React from 'react';

export const ContextMenu = ({x, y, deleteShape, saveShape}) => {
   return (
      <div className="context-menu"
           style={{
              position: "absolute",
              left: x,
              top: y
           }}
      >
         <button onClick={(e) => deleteShape(e)}>Удалить</button>
         <button onClick={(e) => saveShape(e)}>Сохранить</button>
      </div>
   )
};
