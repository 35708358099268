import React from "react";
import { RenderInput } from "../../Fields/RenderInput";
import { Field, FieldArray, reduxForm } from "redux-form";

const renderManager = ({ fields, meta: { touched, error, submitFailed } }) => {
   return (
      <>
         <button
            className="btn-reset"
            type="button"
            title="Добавить менеджера"
            aria-label="Добавить менеджера"
            onClick={() => fields.push({})}
         >
            <svg
               xmlns="http://www.w3.org/2000/svg"
               width="26"
               height="26"
               viewBox="0 0 26 26"
               fill="none"
               aria-hidden={true}
            >
               <path d="M13 0V26M0 13H26" stroke="#84C225" strokeWidth="5"/>
            </svg>
         </button>
         {(touched || submitFailed) && error && (
            <span className="red-txt">{error}</span>
         )}
         {fields.map((manager, index) => (
            <div className="item flex field-group flex--ac" key={index}>
               <div className="form__item">
                  <label
                     className="visually-hidden"
                     htmlFor={`${manager}.name`}
                  >
                     ФИО
                  </label>
                  <Field
                     id={`${manager}.name`}
                     name={`${manager}.name`}
                     component={RenderInput}
                     placeholder="ФИО"
                     className="control control--custom"
                  />
               </div>
               <div className="form__item">
                  <label className="visually-hidden" htmlFor={`${manager}.phone`}>
                     Телефон
                  </label>
                  <Field
                     id={`${manager}.phone`}
                     name={`${manager}.phone`}
                     component={RenderInput}
                     placeholder="Телефон"
                     className="control control--custom"
                  />
               </div>
               <div className="form__item">
                  <label className="visually-hidden" htmlFor={`${manager}.email`}>
                     E-mail
                  </label>
                  <Field
                     id={`${manager}.email`}
                     name={`${manager}.email`}
                     component={RenderInput}
                     placeholder="E-mail"
                     className="control control--custom"
                  />
                  <Field
                     id={`${manager}.category`}
                     type="hidden"
                     name={`${manager}.category`}
                     component="input"
                  />
                  <Field
                     id={`${manager}.city_id`}
                     type="hidden"
                     name={`${manager}.city_id`}
                     component="input"
                  />
                  <Field
                     id={`${manager}.id`}
                     type="hidden"
                     name={`${manager}.id`}
                     component="input"
                  />
               </div>
               <button
                  className="btn-reset"
                  onClick={() => fields.remove(index)}
                  type="button"
                  title="Удалить менеджера"
                  aria-label="Удалить менеджера"
               >
                  <svg
                     aria-hidden="true"
                     width="37"
                     height="37"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M29.145 10.76l-3.136-3.135a1.39 1.39 0 00-1.97 0l-5.654 5.655-5.655-5.655a1.39 1.39 0 00-1.97 0L7.625 10.76a1.39 1.39 0 000 1.97l5.655 5.655-5.655 5.655a1.39 1.39 0 000 1.97l3.135 3.135a1.39 1.39 0 001.97 0l5.655-5.655 5.655 5.655a1.39 1.39 0 001.97 0l3.135-3.136a1.39 1.39 0 000-1.97l-5.655-5.654 5.655-5.655a1.39 1.39 0 000-1.97z"
                        fill="#DA251D"
                     />
                  </svg>
               </button>
            </div>
         ))}
      </>
   )
};

const Form = ({handleSubmit}) => {
   return (
      <form onSubmit={(e) => handleSubmit(e)}>
         <div
            role="group"
            aria-labelledby="manager-data"
            className="flex flex--ac field-wrap"
         >
            <div id="manager-data" className="title-bold">
               Новостройки и Парковки
            </div>
            <FieldArray name="manager" component={renderManager} />
         </div>
         <div
            role="group"
            aria-labelledby="manager-data-resellers"
            className="flex flex--ac field-wrap"
         >
            <div id="manager-data-resellers" className="title-bold">
               Вторичное жилье
            </div>
            <FieldArray name="manager_resellers" component={renderManager} />
         </div>
         <div
            role="group"
            aria-labelledby="manager-data-commercial"
            className="flex flex--ac field-wrap"
         >
            <div id="manager-data-commercial" className="title-bold">
               Коммерческая недвижимость
            </div>
            <FieldArray name="manager_commercial" component={renderManager} />
         </div>

         <button className="btn btn--green btn--no-border btn--secondary title-bold btn--center">
            Сохранить
         </button>
      </form>
   );
};

export const validateManager = values => {
   const errors = {};
   if(values)
      Object.keys(values).map((group) => {
         values[group].map((item,innerIndex) => {
            if(!item.name){
               if(!errors[group]){
                  errors[group] = Array();
                  errors[group][innerIndex] = {
                     name: "Заполните поле."
                  }
               }
            }
         });
      });
   return errors
};

export default reduxForm({
   form: "fieldArrays", // a unique identifier for this forms
   validate: validateManager,
   enableReinitialize: true,
})(Form);
