import { workerConstants } from "../../constants/workerConstants";

const initialState = {
   isFetching: false,
   data: [],
};

export function workerReducer(state = initialState, action) {
   switch (action.type) {
      case 'Get Worker':
         return {
            ...state,
            data: action.worker.data
         };
      case 'Delete Worker':
         return {
            ...state,
            data: null
         };
      case workerConstants.FETCH_WORKER_SUCCESS:
         return {
            ...state,
            data: action.payload.data
         };
      case workerConstants.FETCH_WORKER_FAILURE:
         return {
            ...state,
            data: [],
            isFetching: false
         };

      case workerConstants.IS_FETCHING_WORKER:
         return {
            ...state,
            isFetching: action.status
         };

      case workerConstants.DELETE_WORKER:
         return {
            ...state,
            data: state.data.filter(element => element.id !== action.payload.id)
         };

      default:
         return state;

   }
}
