import React, {useEffect, useState} from "react";
import { DescriptionContainer } from "./Description/DescriptionContainer";
import { PorchAll } from "./Porch/PorchAll/PorchAll";
import { Design } from "./Design/Design";
import { FlatsAll } from "./Flats/FlatsAll/FlatsAll";
import { TabsContent } from "../../Tabs/TabsContent";

export const Settings = () => {

   const  [activeTab , setActiveTab] = useState(0);
   const handleSave = e => {
      setActiveTab(e);
   }
   const tabs = [
      {
         label: "Описание",
         components: <DescriptionContainer handleSave={() => setActiveTab(1)} />,
      },
      {
         label: "Шаблон",
         components: <PorchAll />,
      },
      {
         label: "Квартиры",
         components: <FlatsAll />,
      },
      {
         label: "Дизайн МОП",
         components: <Design setActiveTab={handleSave} />,
      },
   ];

   useEffect(() => {
      const tab = localStorage.getItem("houseFormActiveTab");
      if(tab)
         setActiveTab(parseInt(tab));
   },[]);

   useEffect(() => {
      localStorage.setItem("houseFormActiveTab", activeTab);
   },[activeTab]);

   return (
      <>
         <div className="card">
            <TabsContent handleSave={handleSave} tabs={tabs} activeTab={activeTab} />
         </div>
      </>
   );
};
