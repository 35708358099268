import React from "react";
import {Link} from "react-router-dom";
import {ButtonDelete} from "../../Buttons/ButtonDelete";

export const CitesList = ({ data, url, handleDelete }) => (
   data.map(city => <li className="list-sub__item flex flex--sb flex--ac" key={city.id}>
      <Link className="list-sub__link" to={`${url}/${city.id}/settings`}>
         {city.name}
      </Link>
   </li>)
);
