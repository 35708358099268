import React from "react";
import {Link, useRouteMatch} from "react-router-dom";
import { ButtonDelete } from "../../Buttons/ButtonDelete";
import { ButtonEdit } from "../../Buttons/ButtonEdit";
import {useDispatch, useSelector} from "react-redux";
import {deleteComplexHome, deleteSocial} from "../../../actions/complexActions";

export const RightList = ({ url, urlForName = false }) => {
   const complex = useSelector(state => state.complex.complexDetail);
   const dispatch = useDispatch();

   return (
      <div className="right-list">
         <div className="right-list__head">Название</div>
         <div className="right-list__body">

                  {
                     (complex && complex.houses )
                     &&
                     complex.houses.map((item,index) =>
                        <div key={index} className="list flex flex--sb flex--ac">
                           {
                              item.id
                              ?
                                 (
                                    <Link to={urlForName ? urlForName.start + item.id + urlForName.end : `${url}/${item.id}/settings`} className="list__name">
                                       {item.name}
                                    </Link>
                                 )
                              :(
                                    <div>
                                       {item.name}
                                    </div>
                               )
                           }
                           <div className="list__button">
                              {
                                 item.id &&
                                    <ButtonEdit
                                       url={`${url}/update/${item.id}`}
                                       house={item}
                                    />
                              }

                              <ButtonDelete
                                 onClick={() => dispatch(deleteComplexHome(item.id))}
                                 w={24}
                                 h={24}
                              />
                           </div>
                        </div>
                     )
                  }


            {
               (complex && complex.social )
               &&
               complex.social.map((item,index) =>
                  <div key={index} className="list flex flex--sb flex--ac">
                     <div>{item.name}</div>
                     <div className="list__button">
                        <ButtonEdit
                           url={`${url}/social-update/${item.id}`} />
                        <ButtonDelete
                           onClick={() => dispatch(deleteSocial(item.id))}
                           w={24}
                           h={24}
                        />
                     </div>
                  </div>
               )
            }
         </div>
      </div>
   );
};
