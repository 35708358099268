import React, {useEffect, useState} from "react";
import { Header } from "../../Header/Header";
import { CanvasSVGPlaceMark } from "../../Canvas/CanvasCreate/CanvasSVGPlaceMark";
import Image from "../../../assets/img/plan.jpg";
import {useDispatch, useSelector} from "react-redux";
import {
   IconCafe,
   IconFitness,
   IconHose,
   IconIcePalace,
   IconMarket, IconPark,
   IconParking,
   IconPlus, IconSchool,
   IconTablet
} from "../../Icons/complexIcons";
import {ButtonBack} from "../../Buttons/ButtonBack";
import {ButtonSave} from "../../Buttons/ButtonSave";
import {useHistory, useRouteMatch} from "react-router-dom";
import useImage from "use-image";
import {saveSocial, updateSocial} from "../../../actions/complexActions";
import API from "../../../utils/API";

const icons = require.context( "../../../assets/img/icon/complex",false);
const paths = icons.keys();
const iconsImages = paths.map(path => icons(path));

export const SocialBuild = () => {
   const complex = useSelector(state => state.complex.complexDetail);
   const history = useHistory();
   const dispatch = useDispatch();

   let { params:{ social_id } } = useRouteMatch();

   const [social, setSocial] = useState({});
   const [icon, setIcon] = useState(false);

   const [image] = useImage(icon);

   const toggleSocial = async (e) => {
      setSocial({...social,...e});
      const key = await paths.map((item, index) => item.indexOf(e.type) !== -1 ? index : false).filter(item => item !== false)[0];
      setIcon(iconsImages[key]);
   }

   const savePoints = (points) => {
      setSocial({...social,points: points});
   }

   const  handleSave = () => {
      if(social)
      {
         let formData = new FormData();
         formData.append("name", social.name);
         formData.append("complex_id", complex.id);
         formData.append("type", social.type);
         formData.append("coord", JSON.stringify(social.points));

         if(social_id)
            dispatch(updateSocial(formData, social_id));
         else
            dispatch(saveSocial({social: formData, complex: complex}));

         history.goBack();
      }
   }

   useEffect(() => {
      if(social_id)
         API.get(`social/view/${social_id}`)
            .then(async ({data}) => {
               setSocial(Object.assign(data, {points: data.coord ? JSON.parse(data.coord) : false}));
               await toggleSocial(data);
            });
   },[social_id]);

   return (
      <>
         <Header title="Добавить соцобъект на карте" />
         <div className="card">
            <div className="houseSocial">
               <div className="houseSocial_col" onClick={() => toggleSocial({ type: 'childHome', name: 'Детский сад' })}>
                  <IconHose stroke={social.type === 'childHome' && "var(--green)"} />
               </div>
               <div className="houseSocial_col" onClick={() => toggleSocial({ type: 'hospital', name: 'Поликлиника' })}>
                  <IconPlus stroke={social.type === 'hospital' && "var(--green)"} />
               </div>
               <div className="houseSocial_col" onClick={() => toggleSocial({ type: 'parking', name: 'Паркинг' })}>
                  <IconParking  stroke={social.type === 'parking' && "var(--green)"}  />
               </div>
               <div className="houseSocial_col" onClick={() => toggleSocial({ type: 'shop', name: 'Магазин' })}>
                  <IconMarket stroke={social.type === 'shop' && "var(--green)"}  />
               </div>
               <div className="houseSocial_col" onClick={() => toggleSocial({ type: 'pharmacy', name: 'Аптека' })}>
                  <IconTablet stroke={social.type === 'pharmacy' && "var(--green)"}  />
               </div>
               <div className="houseSocial_col" onClick={() => toggleSocial({ type: 'cafe', name: 'Кафе' })}>
                  <IconCafe stroke={social.type === 'cafe' && "var(--green)"}  />
               </div>
               <div className="houseSocial_col" onClick={() => toggleSocial({ type: 'fitness', name: 'Фитнес клуб' })}>
                  <IconFitness stroke={social.type === 'fitness' && "var(--green)"}  />
               </div>
               <div className="houseSocial_col" onClick={() => toggleSocial({ type: 'icePalace', name: 'Ледяной дворец' })}>
                  <IconIcePalace stroke={social.type === 'icePalace' && "var(--green)"}  />
               </div>
               <div className="houseSocial_col" onClick={() => toggleSocial({ type: 'park', name: 'Парк' })}>
                  <IconPark stroke={social.type === 'park' && "var(--green)"}  />
               </div>
               <div className="houseSocial_col" onClick={() => toggleSocial({ type: 'school', name: 'Школа' })}>
                  <IconSchool stroke={social.type === 'school' && "var(--green)"}  />
               </div>
            </div>
            <CanvasSVGPlaceMark
               icon={image}
               image={complex.photoGenPlan || Image}
               points={social.points || false}
               savePoints={savePoints}
            />
            <div className="form__foot button-group flex" style={{ position: 'relative' }}>
               <ButtonBack onClick={() => history.goBack()} />
               <ButtonSave onClick={() => handleSave()} />
            </div>
         </div>
      </>
   );
};
