import React from "react";
import { ParkingLevelsListContainer } from "./ParkingLevelsListContainer";
import { TabsContent } from "../../Tabs/TabsContent";

export const ParkingTabs = (props) => {
   const tabs = [
      {
         label: "Общие",
         components: props.component,
      },
      {
         label: "Парковочные места",
         components: <ParkingLevelsListContainer />,
      },
   ];

   return (
      <>
         <div className="card">
            <TabsContent tabs={tabs} />
         </div>
      </>
   );
};
