export const workerConstants = {
   FETCH_WORKER_SUCCESS: "FETCH_WORKER_SUCCESS",
   FETCH_WORKER_FAILURE: "FETCH_WORKER_FAILURE",

   IS_FETCHING_WORKER: "IS_FETCHING_WORKER",
   DELETE_WORKER: "DELETE_WORKER",

   GET_WORKER: "GET_WORKER",

   // FETCH_CITY_DETAILS_SUCCESS: "FETCH_CITY_DETAILS_SUCCESS",
   // FETCH_CITY_DETAILS_FAILURE: "FETCH_CITY_DETAILS_FAILURE",
};
