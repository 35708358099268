import React, { useEffect, useState, useCallback } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { reduxForm } from "redux-form";
import { useDispatch, useSelector } from "react-redux";

import { Header } from "../../Header/Header";
import {
   receiveCreateOffice,
   receiveGenPlanCommercialProperty,
   receiveOfficesByCommrealty,
} from "../../../actions/commercialPropertyAction";

import { Form } from "./OfficeForm";
import { isEmpty } from "../../../utils/utils";

const NAME_FORM = "create_office";

const validate = (values) => {
   const errors = {};
   if (!values.id_1s) {
      errors.id_1s = "Поле обязательно для заполнения";
   }
   if (!values.name) {
      errors.name = "Поле обязательно для заполнения";
   }
   if (!values.price) {
      errors.price = "Поле обязательно для заполнения";
   }
   if (!values.square) {
      errors.square = "Поле обязательно для заполнения";
   }
   if (!values.status) {
      errors.status = "Поле обязательно для заполнения";
   }
   return errors;
};
const CreateOfficeForm = reduxForm({ form: NAME_FORM, validate })(Form);

export const CreateOffice = () => {
   const [mediaData, setMediaData] = useState();
   const [data, setData] = useState({});
   const [image, setImage] = useState(null);
   const match = useRouteMatch();
   const dispatch = useDispatch();
   const history = useHistory();
   const { id } = match.params;

   const offices = useSelector((state) => state.commercialProperty.offices);
   const commercialProperty = useSelector(
      (state) => state.commercialProperty.currentCommercial
   );

   const onSubmit = async (values) => {
      try {
         const formData = new FormData();
         formData.append("image", mediaData);

         formData.append("plan", JSON.stringify({ points: values.points }));
         if (id) {
            formData.append("comrealty_id", id);
         }
         delete values.plan;
         delete values.points;
         for (let key in values) {
            formData.append(key, values[key]);
         }

         await dispatch(receiveCreateOffice(formData));
         history.goBack();
      } catch (error) {
         console.error(error.message);
      }
   };

   const handleReceiveGenPlanCommercialProperty = useCallback(async () => {
      const imgUrl = await receiveGenPlanCommercialProperty(id);
      setImage(imgUrl);
   }, [id]);

   useEffect(() => {
      handleReceiveGenPlanCommercialProperty();
   }, [handleReceiveGenPlanCommercialProperty]);

   useEffect(() => {
      if (id && isEmpty(offices)) {
         dispatch(receiveOfficesByCommrealty(id));
      }
   }, [offices, dispatch, id]);

   return (
      <>
         <Header title="Добавить офис" />
         <CreateOfficeForm
            data={data}
            offices={offices}
            setMediaData={setMediaData}
            onSubmit={onSubmit}
            image={
               commercialProperty.imageplan
                  ? `http://dc.websalamat.ru/img/${commercialProperty.imageplan}`
                  : Image
            }
            nameForm={NAME_FORM}
            isUpdating={false}
            urlBack={`/commercial-property/update/${id}`}
         />
      </>
   );
};
