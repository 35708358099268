import React, {useState} from "react";
import { useRouteMatch } from "react-router-dom";
import Form from "./Form";
import { TabsContent } from "../../Tabs/TabsContent";
import { GeneralPlan } from "./GeneralPlan";
import {useDispatch} from "react-redux";
import {addComplex, updateComplex} from "../../../actions/complexActions";

export const TabsSettings = ({isEditMode = false}) => {
   let match  = useRouteMatch();

   const [genPlanMedia, setGenPlanMedia] = useState(false);

   const handleSubmit = (value) => {
      if (value.photo.length !== 0) {
         //console.log(value.photo[0]);
         setGenPlanMedia(value.photo[0]);
      }
   };

   const [mediaData, setMediaData] = useState(null);
   const dispatch = useDispatch();

   const onSubmit = (data) => {
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('city_id',  data.city_id.value);
      formData.append('area', data.area.value);
      formData.append('image', mediaData);

      formData.append('genplan', genPlanMedia);

      formData.append('order', data.order);
      formData.append('published', '1');
      dispatch(isEditMode ? updateComplex(match.params.id, formData) : addComplex(formData));
   }

   const tabs = [
      {
         label: "Общая",
         components: <Form isEditMode={isEditMode} onSubmit={onSubmit} setMediaData={setMediaData} id={match.params.id}/>,
      },
      {
         label: "Генплан",
         components: <GeneralPlan url={match.url} genPlanMedia={genPlanMedia} handleSubmit={handleSubmit} />,
      },
   ];

   return (
      <>
         <div className="card">
            <TabsContent tabs={tabs} activeTab={1} />
         </div>
      </>
   );
};
