import {requestConstants} from "../../constants/requestConstants";

const initialState = {
   items: [],
   meta: null,
}

export const requestReducer = (state = initialState, action) => {
   switch (action.type) {
      case requestConstants.SET_ITEMS:
         return {
            ...action.payload.data,
         }
      default:
         return state;
   }
}
