import {apartsConstants} from "../../constants/apartsConstants";

const initialState = {
   apartInfo: {},
   hasError: false,
   entrances: [],
   tplFloors: [],
   tplAparts: []
}

export const apartReducer = (state = initialState, action) => {
   switch (action.type) {
      case apartsConstants.SET_ITEM:
         return {
            ...state,
            apartInfo: action.payload.data,
         }
      case apartsConstants.SET_ENTRANCES:
         return {
            ...state,
            entrances: action.payload.data,
         }
      case apartsConstants.SET_TPL_FLOORS:
         return {
            ...state,
            tplFloors: action.payload.data,
         }
      case apartsConstants.SET_TPL_APARTS:
         return {
            ...state,
            tplAparts: action.payload.data,
         }
      case apartsConstants.CLEAR_ITEM:
         return {
            ...state,
            apartInfo: {},
            entrances: [],
            tplFloors: [],
            tplAparts: []
         }
      default:
         return state;
   }
}
