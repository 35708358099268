import React, { useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { TableList } from "../../TableList/TableList";
import { getParkingLevelsListSelect } from "../../../selectors/parking-selectors";
import { useDispatch, useSelector } from "react-redux";
import { deleteParkingLevel, receiveParkingLevels } from "../../../actions/parkingActions";

export const ParkingLevelsListContainer = () => {
   let { url, params: { id } } = useRouteMatch();
   const dispatch = useDispatch();

   const isUpdating = !!id;

   let head = ["ID", "Уровень", "Ссылки"];
   const parkingLevelList = useSelector(getParkingLevelsListSelect);
   const parkingLevelTable = parkingLevelList.map(level => ({
      id: level.id,
      level: level.name,
      links: {
         delete: true,
         edit: true,
      }
   }));

   const updateParkingLevels = () => {
      if (isUpdating) {
         dispatch(receiveParkingLevels(id));
      }
   };

   useEffect(() => {
      updateParkingLevels();
   }, [dispatch]);

   return (
      <>
         <div className="card__head card__head--border card__head--mb flex flex--sb flex--ac">
            <h2 className="dark mb-none">Уровни паркинга</h2>
            <Link
               to={`/parking/create/parking-level`}
               className="btn btn--green btn--no-border"
            >
               + Добавить уровень
            </Link>
         </div>
         <TableList
            handleDelete={deleteParkingLevel}
            withPage={false}
            pageName="parking/parking-level"
            head={head}
            list={parkingLevelTable}
         />
      </>
   );
};
