import React, {useEffect, useState} from "react";
import {IconPlus} from "../Icons/IconPlus";
import {IconDelete} from "../Icons/IconDelete";

export const RenderMultiField = ({
                               input,
                               label,
                               meta: { touched, error, initial },
                               ...custom
                            }) => {

   const [fields, setFields] = useState([]);

   useEffect(() => {
      if(initial)
      {
         setFields(input.value);
      }
   },[initial]);

   useEffect(() => {
      if(fields && fields.length > 0)
         input.onChange(fields);
   },[fields]);

   const addInput = () => {
      setFields([...fields,{title: "", value: ""}]);
   }

   const deleteField = (item,i) => {
      const items = fields.filter((item, index) => index !== i);
      setFields(items);
   }

   const handleInput = (key, index, e) => {
      let text = e.target.value.trim();
      setFields(fields.map((item,i) => {
         if(i !== index)
         {
            return item;
         }
         else
         {
            return {
               ...item,
               [key]: text
            }
         }
      }));
   }

   return (
      <table className="wrapperMulti">
         <thead>
            <tr>
               <th>Заголовок в табах</th>
               <th>Ссылка на 3D тур</th>
               <th>Действия</th>
            </tr>
         </thead>
         <tbody>
            {
               fields.length > 0 &&
                  fields.map((item,i) => (
                     <tr key={i}>
                          <td className="form__item form__item-mr">
                             <input onChange={(e) => handleInput("title",i,e)} className="control" type="text" placeholder={custom.placeholder} name={`${input.name}[${i}][title]`} value={item.title} />
                             {touched && error && <span className="block red-txt">{error}</span>}
                          </td>
                           <td className="form__item form__item-mr">
                              <input onChange={(e) => handleInput("value",i,e)} className="control" placeholder={custom.placeholder} type="text" name={`${input.name}[${i}][value]`} value={item.value} />
                              {touched && error && <span className="block red-txt">{error}</span>}
                           </td>
                           <td>
                              <div className="deleteInner btnT" onClick={() => deleteField(item,i)}>
                                 <IconDelete stroke="#fff" />
                              </div>
                           </td>
                     </tr>
                  ))
            }
         </tbody>
         <tfoot>
            <tr>
               <td colSpan={2}></td>
               <td align="center">
                  <div className="addInner btnT" onClick={addInput}>
                     <IconPlus opacity={1} stroke="#fff" />
                  </div>
               </td>
            </tr>
         </tfoot>
      </table>
   );
};
