import API from '../utils/API';
import {resellerConstants} from "../constants/resellerConstants";

export const setResellerObjects = (data) => ({type: resellerConstants.SET_OBJECTS, payload: {data}});
export const setFetching = () => ({type: resellerConstants.SET_FETCHING});
export const setUnfetching = () => ({type: resellerConstants.SET_UNFETCHING});
export const setResellerObjectDetail = (data) => ({type: resellerConstants.SET_RESELLER_DETAIL, payload: {data}});
export const setResellerFilter = (data) => ({type: resellerConstants.SET_FILTER, payload: {data}});

export const handleError = (error) => ({type: resellerConstants.SET_ERROR, payload: {error}});

export const receiveResellerObjects = (filter = {}) => (dispatch) => {

   dispatch(setFetching());
   API.get('resale', {
      params: filter,
   }).then(data => {
      const { data: itemData} = data;
      dispatch(setResellerObjects(itemData));
   }).catch((error) => dispatch(handleError(error)));
}

export const createResellerObject = (data, endPoint = 'resale', callBack) => (dispatch) => {
   dispatch(setFetching());
   API.post(`${endPoint}/create`, data).then(response => {
      const {data: result } = response;
      if (result !== false) {
         dispatch(setUnfetching());
         callBack();
      } else {
         throw result;
      }
   }).catch((error) => dispatch(handleError(error)));
};

export const deleteResellerObject = (id, endPoint = 'resale', callBack) => (dispatch) => {
   dispatch(setFetching());
   API.delete(`${endPoint}/delete/${id}`).then(() => {
         dispatch(setUnfetching());
         callBack();
      }).catch((error) => dispatch(handleError(error)));
};

export const updateResellerObject = (id, data, endPoint = 'resale', callBack) => (dispatch) => {
   dispatch(setFetching());
   API.put(`${endPoint}/update/${id}`, data).then(() => {
      dispatch(setUnfetching());
      callBack();
   }).catch((error) => dispatch(handleError(error)));
};

export const receiveResellerObjectDetail = (id) => (dispatch) => {
   dispatch(setFetching());
   API.get(`resale/view/${id}`).then(data => {
      const { data: itemData} = data;
      dispatch(setResellerObjectDetail(itemData));
   }).catch((error) => dispatch(handleError(error)));
}
