import { adminConstants } from "../../constants/adminConstants";

let loggedInStatus = JSON.parse(localStorage.getItem("loggedIn"));

const initialState = {
   loggedIn: loggedInStatus ? true : false
};


export function authentication(state = initialState, action) {
   switch (action.type) {
      case adminConstants.LOGIN_SUCCESS:
         return {
            loggedIn: action.status
         };
         
      case adminConstants.LOGOUT:
         return {
            loggedIn: false
         };
      default:
         return state;
   }
}
