import React from "react";

export const FieldImage = ({ input: { value, ...input }, ...custom }) => {
   const handleChange = (e) => {
      input.onChange(e);
   };

   return (
      <label className="upload btn btn--green">
         Загрузить генплан
         <input
            type="file"
            {...input}
            {...custom}
            onChange={(e) => handleChange(e)}
         />
      </label>
   );
};
