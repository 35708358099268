import React from "react";
import { Field } from "redux-form";
import { RenderInput } from "../../../Fields/RenderInput";

export const Land = () => {
   return (
      <>
         <div className="feature__item flex">
            <div className="feature__sub feature-mr feature__sub--bg">
               <label className="form__s-title x-medium" htmlFor="square">
                  Общая площадь, в сотках
               </label>
            </div>
            <div className="feature__sub">
               <Field
                  id="plot_area"
                  name="plot_area"
                  className="control control--custom"
                  component={RenderInput}
               />
            </div>
         </div>
         <div className="feature__item flex">
            <div className="feature__sub feature-mr feature__sub--bg">
               <label className="form__s-title x-medium" htmlFor="number">
                  Кадастровый номер
               </label>
            </div>
            <div className="feature__sub">
               <Field
                  id="cadastr"
                  name="cadastr"
                  className="control control--custom"
                  component={RenderInput}
               />
            </div>
         </div>
         <div className="feature__item flex">
            <div className="feature__sub feature-mr feature__sub--bg">
               <label className="form__s-title x-medium" htmlFor="link">
                  Ссылка на кадастровую карту
               </label>
            </div>
            <div className="feature__sub">
               <Field
                  id="cadastr_link"
                  name="cadastr_link"
                  className="control control--custom"
                  component={RenderInput}
               />
            </div>
         </div>
      </>
   );
};
