/* создание страницы */
import API from "../utils/API";
import { cityConstants} from "../constants/cityConstants";
import {redirect} from "./redirect";

const isFetchingCity = (status) => ({
   type: cityConstants.IS_FETCHING_CITY,
   status
});

/* получение городов */
export function receiveCities() {
   return (dispatch) => {
      dispatch(isFetchingCity(true));

      API.get("city")
         .then((res) => {
            dispatch({ type: cityConstants.FETCH_CITY_SUCCESS,  city: res.data.items});
            dispatch(isFetchingCity(false))
         })
         .catch((error) => {
            dispatch({ type: cityConstants.FETCH_CITY_FAILURE,  error});
         });
   };
}

/* удаление города */
export function deleteCity(id) {
   return (dispatch) => {
      API.delete(`city/delete/${id}`)
         .then((res) => {
            dispatch({ type: cityConstants.DELETE_CITY,  id: res.data.id});
         })
         .catch((error) => {
            console.log(error)
         })
   };
}

/* create city */
export function createCity(values) {
   return (dispatch) => {
      API.post("city/create", values)
         .then(() => {

         })
         .catch((err) => {
            console.log(err);
         });
   };
}

/* получение детальной страницы */
export function receiveCityDetails(id) {
   return (dispatch) => {
      dispatch(isFetchingCity(true));
      API.get(`city/${id}`)
         .then((res) => {
            dispatch({ type: cityConstants.FETCH_CITY_DETAILS_SUCCESS,  data: res.data});
            dispatch(isFetchingCity(false))
         })
         .catch((err) => {
            console.log(err.message);
         });
   };
}

/* редактирование city */
export function updateCity(id, values) {
   return (dispatch) => {
      API.put(`city/update/${id}`, values)
         .then(({data}) => {
            if(values.get("qr")){
               let data = new FormData();
               data.append('qr',values.get("qr"));
               API.post(`city/updateimage/${id}`, data);
            }
         })
         .catch((err) => {
            console.log(err, 'err');
         });
   }
}
