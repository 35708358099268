import API from '../utils/API';
import { apartsConstants } from "../constants/apartsConstants";

export const setApart = (data) => ({type: apartsConstants.SET_ITEM, payload: {data}});
export const seEntrances = (data) => ({type: apartsConstants.SET_ENTRANCES, payload: {data}});
export const setTplFloors = (data) => ({type: apartsConstants.SET_TPL_FLOORS, payload: {data}});
export const setTplAparts = (data) => ({type: apartsConstants.SET_TPL_APARTS, payload: {data}});

export const clearApart = (data) => ({type: apartsConstants.CLEAR_ITEM, payload: {data}});

export const getApartsByHomeId = (id, page) => {
   return new Promise(async (resolve, reject) => {
      API.get(`apart`,{ params: {
            house_id: id,
            sort: 'floor,name',
            page: page
         }})
         .then(async ({data, config}) => {
            console.log(config);
            if(data)
               resolve(data)
         })
         .catch((err) => reject(err))
   });
}

export const deleteApart = (id) => {
   return new Promise(async (resolve, reject) => {
      API.delete(`apart/delete/${id}`).then(res => resolve(true)).catch(err => reject(false));
   });
}


export const receiveApart = (id, initialize) => (dispatch) => {
   API.get(`apart/view/${id}`)
      .then(async ({data}) => {
         if(data){
            await new Promise(res => setTimeout(res, 500));
            const tplApart = await getTplApart(data.tplapart_id);
            dispatch(setApart(data));

            let status = '';
            switch(data.status){
               case('1'):
                  status = 'Продан';
                  break;
               case('2'):
                  status = 'В продаже';
                  break;
               case('3'):
                  status = 'Забронирован';
                  break;
               default:
                  break;
            }

            initialize({
               ...data,
               tplapart_id: {label: tplApart.name, value: data.tplapart_id},
               status: {label: status, value: data.status},
               floor: {label: data.floor, value: data.floor}
            });
         }
      })
      .catch((err) => console.log("set apart: ", err))
}

export const getEntrances = (id) => (dispatch) => {
      API.get(`entrance/house/${id}`)
         .then(async ({data}) => {
            if (data.items.length > 0) {
               dispatch(seEntrances(
                  data.items.map(item => ({
                     value: item.id,
                     label: item.name
                  }))
               ));
            }
         })
         .catch((err) => dispatch(seEntrances([])));
}

export const getTplApart = async (id)  => {
   const result = await  API.get(`tplapart/view/${id}`)
      .then(async ({data}) => {
         if(data)
            return data;
      })
      .catch((err) => (false));
   return result;
}


export const getTplFloors = (id) => (dispatch) => {
   API.get(`tplfloor/entrance/${id}`)
      .then(async ({data}) => {
         if(data.items.length > 0){
            dispatch(setTplFloors(
               data.items.map(item => ({
                  value: item.id,
                  label: item.name
               }))
            ));
         }
      })
      .catch((err) => dispatch(setTplFloors([])));
}

export const getTplApartByFloorId = (id) => (dispatch) => {
   API.get(`tplapart/tplfloor/${id}`)
      .then(async ({data}) => {
         if(data.items.length > 0){
            dispatch(setTplAparts(
               data.items.map(item => ({
                  value: item.id,
                  label: item.name
               }))
            ));
         }
      })
      .catch((err) => dispatch(setTplAparts([])));
}

export const createApart = (data) => (dispatch) => {
   return new Promise(async (resolve, reject) => {
      API.post(`apart/create`, data).then(res => resolve(true)).catch(err => reject(false));
      dispatch(clearApart());
   });
}

export const updateApart = (id, data) => (dispatch) => {
   return new Promise(async (resolve, reject) => {
      API.put(`apart/update/${id}`, data).then(res => resolve(true)).catch(err => reject(false));
      dispatch(clearApart());
   });
}

