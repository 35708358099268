import React, {useEffect, useState} from 'react';
import Konva from "konva";
import {Stage, Layer, Group} from 'react-konva';
import {BaseImage} from "../General/BaseImage";
import {useCheckSize} from "../General/useCheckSize";
import {Popup} from "./Popup";
import {Figure} from "./Figure";
import {ZoomStage} from '../General/ZoomStage'
import {useRouteMatch, Redirect} from "react-router-dom";

Konva.hitOnDragEnabled = true;

export const CanvasPreview = ({image , complexInfo, customUrl, updateByIndexItem = false}) => {
  let { url } = useRouteMatch();
  const ref = React.useRef();
  const [size, setSize] = useCheckSize();
  const [scale, setScale] = useState(1);
  const { houses } = complexInfo;
  const [data, setData] = useState(false);
   console.log('CanvasPreview houses are: ', complexInfo);
  useEffect(() => {
     if(houses)
     {
        const loadHouses = houses.map(item => {
           return {
              ...item,
              points: (item.plan && JSON.parse(item.plan).points) ? JSON.parse(item.plan).points : [],
              popup: (item.plan && JSON.parse(item.plan).popup) ? JSON.parse(item.plan).popup : null,
           }
        })
        setData(loadHouses);
     }
  },[complexInfo]);
  /*const data = [
     {
        id: 2,
        popup: {
           title: 'Хей',
           position: {x: 170.56603773584905, y: 334.22514643773405}
        },
        points: [{id: 1, x: 392.8301886792453, y: 554.8480063224854},{id: 2, x: 366.79245283018867, y: 394.62149021099214},{id: 3, x: 359.4339622641509, y: 359.51886123956956},{id: 4, x: 341.3207547169812, y: 357.25417549947775},{id: 5, x: 305.66037735849056, y: 361.2173755446384},{id: 6, x: 262.0754716981132, y: 370.8422899400285},{id: 7, x: 224.71698113207546, y: 381.03337577044147},{id: 8, x: 140.9433962264151, y: 395.75383308103807},{id: 9, x: 106.98113207547169, y: 398.5846902561527},{id: 10, x: 140.37735849056602, y: 327.8132608782847},{id: 11, x: 47.54716981132076, y: 335.73966096860596},{id: 12, x: 32.830188679245275, y: 336.87200383865184},{id: 13, x: 22.07547169811321, y: 360.6512041096155},{id: 14, x: 1.6981132075471699, y: 394.0553187759692},{id: 15, x: 1.1320754716981132, y: 456.3341766284931},{id: 16, x: 0, y: 558.8112063676459},{id: 17, x: 36.79245283018868, y: 638.641378705881},{id: 18, x: 147.16981132075472, y: 608.634292649665},{id: 19, x: 152.83018867924528, y: 620.5238927851468},{id: 20, x: 200.37735849056602, y: 606.9357783445963},{id: 21, x: 216.79245283018867, y: 597.8770353842291},{id: 22, x: 235.47169811320754, y: 592.7814924690225},{id: 23, x: 285.2830188679245, y: 584.8550923787014},{id: 24, x: 327.1698113207547, y: 575.7963494183343},{id: 25, x: 339.62264150943395, y: 563.9067492828525},{id: 26, x: 354.90566037735846, y: 566.7376064579672}]
     }
  ];*/

  const zoomStage = (stage, scaleBy) => {
     ZoomStage(stage, scaleBy)
  };

  const [redirect, setRedirect] = useState(false);

  const handleRedirect = (e) => {
     setRedirect(e)
  };

  console.log(image);

   return (
     <div className="frame flex flex--ac flex--jc-center">
         {redirect !== false &&
            <Redirect
               push
               to={{
                  pathname: customUrl ? `${customUrl}/${redirect}` : `${url}/build/update/${redirect}`,
               }}
            />
         }

           {/*<div className="frame__text">*/}
           {/*   <span className="title-medium">Загрузите генплан</span>*/}
           {/*</div>*/}
           <div className="frame-canvas">
              <Stage
                 ref = {ref}
                 width={size.width}
                 height={size.height}
                 scaleX={scale}
                 scaleY={scale}
                 className="canvas"
                 draggable={true}
              >
                  <Layer>
                     <Group>
                        <BaseImage photo={image} size={size} setScale={setScale} setSize={setSize} />
                        {
                           data &&
                           data.map((polygon,index) => (
                              <Figure
                                 key={index}
                                 polygon={polygon}
                                 handleRedirect={() => handleRedirect(updateByIndexItem ? index : polygon.id)}
                              />
                           ))
                        }
                        {data &&
                           data.map((title,index) => {
                              if (!title.popup) return null;
                              const pos = { x: title.popup.position.x + 17, y: title.popup.position.y + 27 };
                              return (
                                 <Popup
                                    key={index}
                                    title={title.popup.title}
                                    position={{x: pos.x, y:pos.y}}
                                 />
                              )
                        })}
                     </Group>
                  </Layer>
              </Stage>
              <div className="zoom-container">
                 <button
                    type="button"
                    onClick={() => {
                       zoomStage(ref.current, 1.2);
                    }}
                 >
                    +
                 </button>
                 <button
                    type="button"
                    onClick={() => {
                       zoomStage(ref.current, 0.8);
                    }}
                 >
                    -
                 </button>
              </div>
           </div>
        </div>
  )
};




