import React, { useEffect } from "react";

import { Header } from "../../Header/Header";
import { TableList } from "../../TableList/TableList";
import { Link, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
   handleCommercialPropertyDelete,
   receiveCommercialProperty,
} from "../../../actions/commercialPropertyAction";

export const CommercialPropertyListContainer = () => {
   const { url } = useRouteMatch();

   const dispatch = useDispatch();

   const head = ["ID", "Название", "Адрес", "Город", "Район", "Ссылки"];
   const list = useSelector((state) => state.commercialProperty.commercials);

   useEffect(() => {
      dispatch(receiveCommercialProperty());
   }, [dispatch]);

   return (
      <>
         <Header title="Коммерческая недвижимость" />
         <div className="card">
            <div className="card__head">
               <Link
                  to={`${url}/create`}
                  className="btn btn--green btn--no-border"
               >
                  Добавить
               </Link>
            </div>

            <TableList
               head={head}
               list={list}
               handleDelete={handleCommercialPropertyDelete}
               uncialURL={`/commercial-property/update`}
            />
         </div>
      </>
   );
};
