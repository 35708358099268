import React, {useEffect, useState} from "react";
import {Link, useRouteMatch} from "react-router-dom";
import {TableList} from "../../TableList/TableList";
import {useDispatch, useSelector} from "react-redux";
import {
   getResellerIsLoading,
   getResellerObjectsSelect,
   getResellerFilter
} from "../../../selectors/reseller-selectors";
import {
   receiveResellerObjects,
   deleteResellerObject,
   updateResellerObject,
   setResellerFilter,
} from "../../../actions/resellerActions";

import {options as categoryOptions} from "../CreateOrUpdate/Form/ResellersForm";
// import { Pagination } from "../../Pagination/Pagination";

export const ResellersListContainer = () => {
   const {url} = useRouteMatch();
   const head = ["ID", "Цена, руб", "Адресс", "Категория", "Ссылки"];

   const listItems = useSelector(getResellerObjectsSelect);
   const isLoading = useSelector(getResellerIsLoading);
   const filter = useSelector(getResellerFilter);

   // const {totalCount, pageCount, currentPage} = metaData || {};
   // const totalCount = listItems.length;

   const dispatch = useDispatch();
   const updateList = () => {
      dispatch(receiveResellerObjects(filter));
   };

   const getEndPoint = (id) => {
      const { category } = listItems.find((i) => i.id === id);
      const { endPoint } = categoryOptions.find(
         (cat) => cat.category_id === parseInt(category, 10)
      );
      return endPoint;
   }

   const handleDelete = (id) => {
      return () => dispatch(deleteResellerObject(id, getEndPoint(id), updateList));
   }

   const handleVisibility = (id, visibled) => {
      const data = {
         published: !!visibled ? 1 : 0,
      }
      dispatch(updateResellerObject(id, data, getEndPoint(id), updateList));
   }



   useEffect(() => {
      updateList();
   }, [dispatch, JSON.stringify(filter)]);

   const list = (listItems || []).map(item => {
      const {id, price, category, address, published } = item;
      const {label: category_label} = categoryOptions.find(cat => parseInt(category, 10) === cat.category_id) || {label: ''};
      return {
         id,
         price,
         address,
         category_label,
         links: {
            delete: true,
            edit: true,
            hide: false,
            unhide: !published,
         },
      }
   });

   const onSetPage = (page) => {
      dispatch(setResellerFilter({
         ...filter,
         page,
      }))
   }

   const filtersColumn = [{
      columnName: 'Категория',
      filterOptions: [
         {
            label: 'Зем.участки',
            value: '3',
            filterKey: 'category',
         },
         {
            label: 'Коттеджи',
            value: '2',
            filterKey: 'category',
         },
         {
            label: '1',
            value: '1',
            filterKey: 'rooms',
         },
         {
            label: '2',
            value: '2',
            filterKey: 'rooms',
         },
         {
            label: '3',
            value: '3',
            filterKey: 'rooms',
         },
         {
            label: '4+',
            value: '4+',
            filterKey: 'rooms',
         }
      ],
   }];

   const handleFilterChange = ({value, filterKey}) => {
      // устанавливаем фильтр и сбрасываем страницу
      dispatch(setResellerFilter({
         [filterKey]: value,
         page: 1,
      }))
   }

   return (
      <div className={`card${isLoading ? ' form--loading' : ''}`}>
         <div className="card__head">
            <Link
               to={`${url}/resellers-create`}
               className="btn btn--green btn--no-border"
            >
               Добавить
            </Link>
         </div>
         <TableList head={head} list={list || []} handleDelete={handleDelete} handleVisibility={handleVisibility} uncialURL={'/resellers/resellers/update'} onChangeFilter={handleFilterChange} filtersColumn={filtersColumn} />
         {/*<Pagination pageCount={pageCount} currentPage={currentPage} onSetPage={onSetPage} totalCount={totalCount} itemCount={Array.isArray(listItems) && listItems.length} />*/}
      </div>
   );
};
