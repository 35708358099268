import React, {useEffect, useState} from "react";
import { Field, reduxForm } from "redux-form";
import { RenderInput } from "../../../Fields/RenderInput";
import { LayoutFormBasis } from "../../../Fields/LayoutForm";
import { RenderImage } from "../../../Fields/RenderImage";
import { WYSIWYGEditor } from "../../../Fields/WYSIWYGEditor";
import { ButtonBack } from "../../../Buttons/ButtonBack";
import { ButtonSave } from "../../../Buttons/ButtonSave";
import {getCurrentPromo} from "../../../../actions/promoAction";

const Form = ({ handleSubmit, setMediaData, initialize, id, isEditMode }) => {
   const [lengthText, setLengthText] = useState(0);
   const handleLengthChange = (e) => {
      const count = e.target.value.length;
      setLengthText(count);
   };

   useEffect(() => {
      if (isEditMode) {
         getCurrentPromo(id, initialize);
      } else {
         initialize({
            date_begin: Math.floor(Date.now() / 1000),
            date_end: Math.floor(Date.now() / 1000),
         });
      }
   }, []);

   const dateNormalize = (value) => {
      return Date.parse(value) / 1000;
   };

   const dateFormatter = (number) => {
      const date = number ? number : Date.now() / 1000;
      return ("" + (new Date(date * 1000)).toISOString()).replace(/T(.+)$/,'')
   };

   return (
      <form className="form" onSubmit={handleSubmit}>
         <LayoutFormBasis id="title" title="Заголовок:">
            <div className="form__item form__item--all">
               <Field
                  id="title"
                  name="name"
                  className="control"
                  component={RenderInput}
                  placeholder="Название акции"
                  maxLength="95"
                  onChange={handleLengthChange}
                  required
               />
               <p className="mb-none min red-txt form__txt">{lengthText}/95</p>
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis id="photo" title="Изображение">
            <div className="form__item form__item--min">
               <Field name="image"
                      id="photo"
                      setMediaData={setMediaData}
                      component={RenderImage}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis id="sort" title="Сортировка">
            <div className="form__item">
               <Field
                  id="sort"
                  name="order"
                  type="number"
                  className="control"
                  component={RenderInput}
                  placeholder="1"
                  required
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis id="date_begin" title="Срок действия с:">
            <div className="form__item">
               <Field
                  id="date_begin"
                  name="date_begin"
                  type="date"
                  className="control"
                  component={RenderInput}
                  required
                  format={dateFormatter}
                  normalize={dateNormalize}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis id="date_end" title="Срок действия по:">
            <div className="form__item">
               <Field
                  id="date_end"
                  name="date_end"
                  type="date"
                  className="control"
                  component={RenderInput}
                  required
                  format={dateFormatter}
                  normalize={dateNormalize}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis label={false} id="description-group" title="Описание">
            <div className="form__item form__item--all">
               <Field
                  component={WYSIWYGEditor}
                  name="text"
                  type="text"
                  required
               />
            </div>
         </LayoutFormBasis>
         <Field
            id="published"
            name="published"
            type="hidden"
            className="control"
            component={RenderInput}
            required
         />
         <div className="form__foot button-group flex">
            <ButtonBack to={'/pages/offer'} />
            <ButtonSave />
         </div>
      </form>
   );
};

export default reduxForm({
   form: "offer-form",
})(Form);
