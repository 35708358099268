import React, {useEffect, useState} from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { TableList } from "../../../../Floor/TableListCustom";
import {deleteApart, getApartsByHomeId} from "../../../../../actions/apartsAction";
import {getStatus} from "../../../../../utils/helpers";
import {Pagination} from "../../../../Pagination/Pagination";

export const FlatsAll = () => {
   let { url, params } = useRouteMatch();
   const { home_id } = params;
   const [list , setList] = useState(false);



   const getApartList = (home_id, page = 1) => {
      getApartsByHomeId(home_id, page)
         .then(result =>
         setList({
               items: result.items.map(item => ({
                  id: item.id,
                  entrance: item.entrance_name,
                  floor: item.floor,
                  rooms: item.rooms,
                  name: item.name,
                  status: getStatus(item.status),
                  links: {
                     delete: true,
                     edit: `/${home_id}/apart/edit`,
                  }
               })),
            links: result._links,
            meta: result._meta
            }
         ));
   }

   const handleDelete = (id) => {
      deleteApart(id)
         .then(r => getApartList(home_id));
   }

   const onSetPage = (page) => {
      getApartList(home_id, page);
   }

   useEffect(() => {
      if(home_id)
         getApartList(home_id);
   },[home_id]);

   let head = [
      "ID",
      "Подъезд",
      "Этаж",
      "Комнат",
      "Квартира",
      "Статус",
      "Ссылки",
   ];

   return (
      <>
         <div className="card__head card__head--border card__head--mb flex flex--sb flex--ac">
            <h2 className="dark mb-none">Список квартир</h2>
            <Link
               to={`/${home_id}/apart-create`}
               className="btn btn--green btn--no-border"
            >
               + Добавить
            </Link>
         </div>
         <TableList
            handleDelete={handleDelete}
            head={head} list={list.items || []} />

         {
            list.meta &&
            <Pagination
               onSetPage={onSetPage}
               pageCount={list.meta.pageCount}
               currentPage={list.meta.currentPage}
               itemCount={list.items.length}
               totalCount={list.meta.totalCount}
            />
         }

      </>
   );
};
