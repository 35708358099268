import React, {useEffect} from "react";
import { Header } from "../../Header/Header";
import ResellersForm, { options as categoryOptions } from "./Form/ResellersForm";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router-dom";
import {getResellerIsLoading, getCurrentResellerObject} from "../../../selectors/reseller-selectors";
import {
   createResellerObject,
   setResellerObjectDetail,
   receiveResellerObjectDetail,
   updateResellerObject,
} from "../../../actions/resellerActions";
import {getCitiesForFormSelector} from "../../../selectors/city-selectors";
import {receiveCities} from "../../../actions/cityAction";
import {createMedia, deleteMedia} from "../../../actions/media";
import { balcony } from "./Form/Apartment";

export const ResellersCreateContainer = () => {
   const { path, params } = useRouteMatch();
   const mode = path.includes('update') ? 'edit' : 'create';
   const { id } = params || {};

   const isLoading = useSelector(getResellerIsLoading);
   const оbjectDetail = useSelector(getCurrentResellerObject);
   const dispatch = useDispatch();

   // загрузка деталей об объекте и очищение при демонтаже
   useEffect(() => {
      if (mode == 'edit' && id) {
         dispatch(receiveResellerObjectDetail(id));
      }
      return () => dispatch(setResellerObjectDetail({}));
   }, [mode, id]);


   const cities = useSelector(getCitiesForFormSelector);

   useEffect(() => {
      if (!cities.length) {
         dispatch(receiveCities());
      }
   }, [dispatch]);

   const history = useHistory();

   const initialValues = {};

   if (оbjectDetail) {

      Object.keys(оbjectDetail).forEach(key => {
         initialValues[key] = оbjectDetail[key];
      });

      if (оbjectDetail.hasOwnProperty('category')) {
         initialValues.category = categoryOptions.find(cat => cat.category_id === +оbjectDetail.category);
      } else {
         initialValues.category = categoryOptions[0];
      }

      if (оbjectDetail.hasOwnProperty('area')) {
         initialValues.area =  {
            value: оbjectDetail.area || '',
            label: оbjectDetail.area || '',
         }
      } else {
         initialValues.area = {
            value: '',
            label: '',
         };
      }

      if (оbjectDetail.hasOwnProperty('city_id') && cities.length) {
         initialValues.city_id = cities.find(city => city.value === +оbjectDetail.city_id);
      } else {
         initialValues.city_id = '';
      }

      if (оbjectDetail.hasOwnProperty('balcony')) {
         initialValues.balcony = balcony.find(balcon => balcon.value === оbjectDetail.balcony);
      } else {
         initialValues.balcony = {value: '', label: ''};
      }
   } else {
      initialValues.category = categoryOptions[0];
   }

   const handleSubmitCreate = (values) => {
      const formData = new FormData();
      const { category } = values;
      const { endPoint } = category || {};
      Object.keys(values).forEach(key => {
         if (['category'].indexOf(key) < 0) {
            const value = (values[key] && typeof values[key] === 'object' && values[key].hasOwnProperty('value')) ? values[key].value : values[key];
            if (key == 'images') {
               value.forEach(val => formData.append(key+ '[]', val))
            } else {
               formData.append(key, value);
            }
         }
      });

      dispatch(createResellerObject(formData, endPoint, () => history.push('/resellers')))
   };

   const handleSubmitEdit = (values) => {
      const formData = new FormData();
      const { category, id } = values;
      const { endPoint } = category || {};
      Object.keys(values).forEach(key => {
         if (['category', 'id'].indexOf(key) < 0) {
            const value = (values[key] && typeof values[key] === 'object' && values[key].hasOwnProperty('value')) ? values[key].value : values[key];
            if (key == 'images') {
               value.forEach(val => {
                  // добавляем новые картинки
                  if (val instanceof File) {
                     const imageFormData = new FormData();
                     imageFormData.append('image', val);
                     imageFormData.append('parent', 'resale');
                     imageFormData.append('id_parent', id);
                     imageFormData.append('type', '');
                     imageFormData.append('published', 1);
                     dispatch(createMedia(imageFormData))
                  }
               })
            } else {
               value !== null && formData.append(key, value);
            }
         }
      });

      // удаляем лишние картинки
      if (оbjectDetail.hasOwnProperty('images')) {
         оbjectDetail.images.forEach(image => {
            if (!values.images.find(img => img.id === image.id)) {
               dispatch(deleteMedia(image.id));
            };
         });
      }
      dispatch(updateResellerObject(id, formData, endPoint, () => history.push('/resellers')))
   };

   if (mode == 'edit' && (!оbjectDetail || !Object.keys(оbjectDetail).length || !cities.length)) {
      return null;
   }

   return (
      <>
         <Header title={`${mode == 'edit' ? 'Редактировать' : 'Добавить'}`} />
         <div className="card">
            <ResellersForm
               cities={cities}
               mode={mode}
               onSubmit={mode == 'edit' ? handleSubmitEdit : handleSubmitCreate}
               initialValues={initialValues}
               isLoading={isLoading}
            />
         </div>
      </>
   );
};
