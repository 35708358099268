import React from "react";
import { Link } from "react-router-dom";
import { ButtonDelete } from "../Buttons/ButtonDelete";

export const PagesList = ({ url, handleDelete, data }) => {
   return (
      <>
         <ul className="list-none list-sub">
            <li className="list-sub__item">
               <Link className="list-sub__link" to={`${url}/offer`}>
                  Акции
               </Link>
            </li>
            <li className="list-sub__item">
               <Link className="list-sub__link" to={`${url}/news`}>
                  Новости
               </Link>
            </li>
            <li className="list-sub__item">
               <Link className="list-sub__link" to={`${url}/contacts`}>
                  Контакты
               </Link>
            </li>
            <li className="list-sub__item">
               <Link className="list-sub__link" to={`${url}/poll`}>
                  Опросник
               </Link>
            </li>
            <li className="list-sub__item">
               <Link className="list-sub__link" to={`${url}/trade-in`}>
                  Текст для “Трейд-Ин”
               </Link>
            </li>

            {data.map((page) => {
               return (
                  <li key={page.id} className="list-sub__item flex flex--sb">
                     <Link
                        className="list-sub__link"
                        to={`${url}/${page.short}/update`}
                     >
                        {page.name}
                     </Link>
                     {
                       /* [20,21].includes(page.id) &&
                           <ButtonDelete onClick={() => handleDelete(page.short)} />*/
                     }
                  </li>
               );
            })}
         </ul>
      </>
   );
};
