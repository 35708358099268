import React from "react";

export const ButtonUnpublish = (props) => {
   return (
      <button
         className="btn btn--red btn--no-border flex flex--ac"
         title="Опубликовать"
         onClick={props.onClick}
         type={props.type}
         {...props}
      >
         <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            aria-hidden="true"
            className="item-indent"
         >
            <path
               d="M12 0C5.38338 0 0 5.38292 0 12C0 18.6171 5.38338 24 12 24C18.6166 24 24 18.6171 24 12C24 5.38292 18.6166 0 12 0ZM12 23.0769C5.89246 23.0769 0.923077 18.1075 0.923077 12C0.923077 5.89246 5.89246 0.923077 12 0.923077C18.1075 0.923077 23.0769 5.89246 23.0769 12C23.0769 18.1075 18.1075 23.0769 12 23.0769Z"
               fill="white"
            />
            <path
               d="M7 17L17 7M17 17L7 7"
               stroke="white"
               strokeLinecap="round"
            />
         </svg>
         Снять с публикации
      </button>
   );
};
