import React from "react";
import { Field, reduxForm } from "redux-form";
import { WYSIWYGEditor } from "../Fields/WYSIWYGEditor";
import { ButtonBack } from "../Buttons/ButtonBack";
import { ButtonSave } from "../Buttons/ButtonSave";

const FormEditor = ({ handleSubmit }) => (
   <form className="form" onSubmit={(e) => handleSubmit(e)}>
      <Field
         component={WYSIWYGEditor}
         id="description"
         name="description"
         type="text"
      />
      <div className="form__foot button-group flex">
         <ButtonBack to={`/pages`} />
         <ButtonSave />
      </div>
   </form>
);

export default reduxForm({
   form: "editor",
   enableReinitialize: true,
})(FormEditor);
