import React from "react";
import Form from "./Form";
import {useRouteMatch} from "react-router-dom";
import {useDispatch} from "react-redux";
import {deleteManager, saveManager, updateManager} from "../../../actions/managersActions";

export const ManagersContainer = ({ managers, setTab }) => {
   const dispatch = useDispatch();

   let { params } = useRouteMatch();
   let cityId = params.id;

   const validateArray = (arr) => {
      for (let key in arr)
      {
         if(arr[key].length)
            return true;
      }
      return false;
   }

   const handleSubmit = (values) => {
      if(values){

         if(validateArray(values))
         {
            for (const [category,value] of Object.entries(values) ){
               value.forEach(manager => {
                  let formData = new FormData();
                  Object.keys(manager).map(key => {
                     formData.append(key,manager[key]);
                  });

                  formData.append('city_id',cityId);
                  formData.append('category',category);

                  if(manager.id)
                     dispatch(updateManager(formData,manager.id,cityId));
                  else
                     dispatch(saveManager(formData,cityId));
               });
            }
         }

         if(validateArray(managers))
         {
            for (const [key,value] of Object.entries(managers) ){
               value.forEach(user => {
                  let find = values[key].some(item => item.id === user.id);
                  if(!find)
                     dispatch(deleteManager(user.id,cityId));
               });
            }
         }

         setTab(0);
      }
   };

   return (
      <div className="card">
         <Form
            initialValues={{...managers}}
            onSubmit={(values) => handleSubmit(values)}
         />
      </div>
   );
};
