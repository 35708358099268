import React from "react";
import Popover from "@material-ui/core/Popover";
import {IconExpand} from "../Icons/IconExpand";

export const Filter = ({filterOptions, onSelect}) => {
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [selectedOption, setSelectedOption] = React.useState(null);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const open = Boolean(anchorEl);
   const id = open ? 'simple-popover' : undefined;

   const handleSelectFilter = (filter) => {
      setSelectedOption(filter.label);
      onSelect(filter);
   }

   return (
      <span className="table__filter">
         <IconExpand onClick={handleClick} />
         <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
               vertical: 20,
               horizontal: 'center',
            }}
            transformOrigin={{
               vertical: 'top',
               horizontal: 'center',
            }}
         >
            <ul className="table__filter-list">
               {filterOptions.map((filter, i) => {
                  const { label, value, filterKey} = filter;
                  return <li key={i} className={`table__filter-list-item${label === selectedOption ? ' table__filter-list-item_selected' : ''}`} onClick={() => handleSelectFilter(filter)}>{label}</li>
               })}
            </ul>
         </Popover>
      </span>
   )
};
