import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Header } from "../../Header/Header";
import { Form } from "./Form";

import "../Poll.scss";

export const CreateOrUpdatePoll = () => {
   const { path, params } = useRouteMatch();
   const mode = path.includes("update") ? "edit" : "create";
   const { id } = params || {};

   return (
      <>
         <Header title={"Добавить/редактировать"} />
         <Form mode={mode} id={id} />
      </>
   );
};
