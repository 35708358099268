import API from '../utils/API';
import {floorConstants} from "../constants/floorConstants";

export const setFloor = (data) => ({type: floorConstants.SET_FLOOR, payload: {data}});
export const clearFloor = (data) => ({type: floorConstants.CLEAR_FLOOR, payload: {data}});

export const setTplApart = (data) => ({type: floorConstants.SET_TPL_APART, payload: {data}});
export const clearTplApart = (data) => ({type: floorConstants.CLEAR_TPL_APART, payload: {data}});

export const receiveFloor = (id, initialize) => (dispatch) => {
   API.get(`tplfloor/view/${id}`)
      .then(async ({data}) => {
         if(data){
            dispatch(setFloor({
               ...data,
               image: data.image ? 'http://dc.websalamat.ru/img/' + data.image : false,
            }));
            initialize(data);
         }
      })
      .catch((err) => console.log("set floor: ", err))
}

export const receiveTplApart = (id, initialize) => (dispatch) => {
   API.get(`tplapart/view/${id}`)
      .then(async ({data}) => {
         if(data){
            const tplApartData = {
               ...data,
               image2d: data.image2d ? 'http://dc.websalamat.ru/img/' + data.image2d : false,
               image3d: data.image3d ? 'http://dc.websalamat.ru/img/' + data.image3d : false,
            }
            dispatch(setTplApart(tplApartData));
            initialize(tplApartData);
         }
      })
      .catch((err) => console.log("set floor: ", err))
}

export const getFloors = (id) => {
   return new Promise(async (resolve, reject) => {
      API.get(`tplfloor/entrance/${id}`)
         .then(async ({data}) => {
            if(data)
                  resolve(data.items)
         })
         .catch((err) => reject(err))
   });
}

export const addFloor = (data) => {
   return new Promise(async (resolve, reject) => {
      API.post(`tplfloor/create`, data).then(res => resolve(true)).catch(err => reject(false));
      clearFloor();
   });
}

export const addTplApart = (data) => {
   return new Promise(async (resolve, reject) => {
      API.post(`tplapart/create`, data).then(res => resolve(true)).catch(err => reject(false));
      clearTplApart();
   });
}

export const deleteFloor = (id) => {
   return new Promise(async (resolve, reject) => {
      API.delete(`tplfloor/delete/${id}`).then(res => resolve(true)).catch(err => reject(false));
   });
}

export const deleteTplApart = (id) => {
   return new Promise(async (resolve, reject) => {
      API.delete(`tplapart/delete/${id}`).then(res => resolve(true)).catch(err => reject(false));
   });
}

export const updateTplApart = (id, data) => {
   return new Promise(async (resolve, reject) => {
      await API.put(`tplapart/update/${id}`, data).then(res => console.log(res)).catch(err => reject(false));
      await new Promise(res => setTimeout(res, 500));

      const formData = new FormData();
      if(data.get("image2d"))
         formData.append("image2d", data.get("image2d"));

      if(data.get("image3d"))
         formData.append("image3d", data.get("image3d"));

      if(data.get("image2d") || data.get("image3d"))
         API.post(`tplapart/updateimage/${id}`, formData).then(res => console.log(res)).catch(err => reject(false));

      resolve(true);
      clearTplApart();
   });
}

export const updateFloor = (id, data) => {
   return new Promise(async (resolve, reject) => {
      await API.put(`tplfloor/update/${id}`, data).then(res => console.log(res)).catch(err => reject(false));
      await new Promise(res => setTimeout(res, 500));
      if(data.get("image"))
      {
         const formData = new FormData();
         formData.append("image", data.get("image"));
         API.post(`tplfloor/updateimage/${id}`, formData).then(res => console.log(res)).catch(err => reject(false));
      }
      resolve(true);
      clearFloor();
   });
}
