import React, {useEffect} from "react";
import { Field, reduxForm } from "redux-form";
import { validate } from "../Fields/validate";
import { LayoutFormBasis } from "../Fields/LayoutForm";
import {useDispatch} from "react-redux";
import {receiveTplApart} from "../../actions/floorActions";
import {useRouteMatch} from "react-router-dom";
import {RenderImage} from "../Fields/RenderImage";

const images = require.context( "../../assets/img/compas",false);
const paths = images.keys();

paths.forEach(function(item,key) {
   paths[key] = item.substr(2);
});


const DescriptionForm = ({ initialize, handleSubmit, handleSetImage, handleDelete }) => {
   const dispatch = useDispatch();
   let match  = useRouteMatch();
   const { tpl_apart_id } = match.params;

   useEffect(() => {
      if(tpl_apart_id)
         dispatch(receiveTplApart(tpl_apart_id,initialize));
   },[tpl_apart_id]);

   return (
      <form className="form form--description form-inner" onSubmit={handleSubmit}>
         <LayoutFormBasis
            title={
               <div className="street-w">
                  <LayoutFormBasis title="№ строительной">
                     <div className="form__item form__item-mr">
                        <label>
                           <Field
                              className="control"
                              name="name"
                              placeholder="Кв-15"
                              component="input"
                           />
                        </label>
                     </div>
                  </LayoutFormBasis>
                  <LayoutFormBasis title="Комнат">
                     <div className="form__item form__item-mr street-w">
                        <label>
                           <Field
                              className="control"
                              name="cnt"
                              placeholder="1"
                              component="input"
                           />
                        </label>
                     </div>
                  </LayoutFormBasis>
               </div>
               }
            id="street">
               <div className="form__group-inline form__group-bottom flex">
                  <LayoutFormBasis id="photo" title="2D планировка">
                     <div className="form__item form__item--min">
                        <Field
                           name="image2d"
                           id="photo"
                           component={RenderImage}
                           handleDelete={handleDelete}
                           setMediaData={(image) => {
                              handleSetImage({
                                 name: "image2d",
                                 image: image
                              })
                           }}
                        />
                     </div>
                  </LayoutFormBasis>
                  <LayoutFormBasis id="photo" title="3D планировка">
                     <div className="form__item form__item--min">
                        <Field
                           name="image3d"
                           id="photo"
                           component={RenderImage}
                           handleDelete={handleDelete}
                           setMediaData={(image) => {
                              handleSetImage({
                                 name: "image3d",
                                 image: image
                              })
                           }}
                        />
                     </div>
                  </LayoutFormBasis>
               </div>
         </LayoutFormBasis>
         <br/>
      </form>
   );
};

export default reduxForm({
   form: "floor-description",
   validate,
})(DescriptionForm);
