import React, {useEffect, useState} from "react";
import { Field, reduxForm } from "redux-form";
import { validate } from "../../Fields/validate";
import { RenderInput } from "../../Fields/RenderInput";
import { ButtonBack } from "../../Buttons/ButtonBack";
import { ButtonSave } from "../../Buttons/ButtonSave";
import { RenderImage } from "../../Fields/RenderImage";
import { RenderSelect } from "../../Fields/RenderSelect";
import { LayoutFormBasis } from "../../Fields/LayoutForm";
import {receiveCities} from "../../../actions/cityAction";
import {useDispatch, useSelector} from "react-redux";
import {getCitiesForFormSelector} from "../../../selectors/city-selectors";
import {receiveComplexById} from "../../../actions/complexActions";
import AreasData from "../../../constants/districts";

const Form = ({setMediaData, handleSubmit, isEditMode, id, initialize}) => {

   const dispatch = useDispatch();
   const cities = useSelector(getCitiesForFormSelector);
   const complex = useSelector(state => state.complex.complexDetail);
   const FormData = useSelector(state => state.form['info-building'].values)
   const [areas, setAreas] = useState(null);
   const activeCity = (FormData && FormData.city_id) || false;

   useEffect(() => {
      dispatch(receiveCities());
   }, [dispatch])

   useEffect(() => {
      if(id){
         dispatch(receiveComplexById(id, initialize));
      }
   }, [id]);

   useEffect(() => {
      if(activeCity)
      {
         const areasLoad = AreasData[activeCity.label];
         setAreas(areasLoad);
      }
   },[activeCity]);

   return (
      <form className="form form--basis" onSubmit={handleSubmit}>
         <LayoutFormBasis id="name" title="Название">
            <div className="form__item form__item--min">
               <Field
                  id="name"
                  name="name"
                  className="control control--w"
                  component={RenderInput}
                  placeholder="ЖК “Такой-то”"
                  required
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis id="sort" title="Сотритровка">
            <div className="form__item">
               <Field
                  id="sort"
                  name="order"
                  type="number"
                  className="control"
                  component={RenderInput}
                  placeholder="1"
                  required
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis
            label={false}
            id="location-area"
            title="Район расположения"
         >
            <div className="form__group flex">
               <div className="form__item">
                  <Field
                     id="city_id"
                     name="city_id"
                     placeholder="Выберите"
                     component={RenderSelect}
                     options={cities}
                     required
                  />
               </div>
               <div className="form__item">
                  <Field
                     id="area"
                     name="area"
                     placeholder="Выберите"
                     component={RenderSelect}
                     options={areas}
                  />
               </div>
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis id="photo" title="Превью">
            <div className="form__item form__item--min">
               <Field
                  name="photo"
                  id="photo"
                  component={RenderImage}
                  setMediaData={setMediaData}
               />
            </div>
         </LayoutFormBasis>

         <div className="form__foot button-group flex">
            <ButtonBack />
            <ButtonSave />
         </div>
      </form>
   );
};

export default reduxForm({
   form: "info-building",
   validate,
   destroyOnUnmount: true
})(Form);
