import {reviewsConstants} from "../../constants/reviewsConstants";

const initialState = {
   questions: [],
   questionDetail: false,
   themes: false,
   hasError: false,
   refresh: false,
}

export const reviewReducer = (state = initialState, action) => {
   switch (action.type) {
      case reviewsConstants.SET_REFRESH:
         return {
            ...state,
            refresh: action.payload.data,
         }
      case reviewsConstants.SET_ITEMS:
         return {
            ...state,
            questions: action.payload.data,
         }
      case reviewsConstants.SET_DETAIL:
         return {
            ...state,
            questionDetail: action.payload.data,
         }
      case reviewsConstants.SET_THEMES:
         return {
            ...state,
            themes: action.payload.data,
         }
      case reviewsConstants.SET_ERROR:
         return {
            ...state,
            hasError: true,
         }
      default:
         return state;
   }
}
