import React from "react";
import JoditEditor from "jodit-react";
import {MAIN_URL} from '../../utils/API';

const urlMedia = `${MAIN_URL}v1/media`;

export const WYSIWYGEditor = ({id, input}) => {
   return (
      <JoditEditor
         id={id}
         value={input.value}
         config={{
            readonly: false,
            minHeight: '500px',
            uploader: {
               url: `${urlMedia}/create`,
               method: 'post',
               format: 'json',
               insertImageAsBase64URI: false,
               processData: true,
               headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
               },
               filesVariableName: () => {
                  return 'image';
               },
               process: (resp) => {
                  const { id, image } = resp;
                     return {
                        baseurl: `${MAIN_URL}img/`,
                        files: [image],
                        isImages: [true],
                     }
               },
               isSuccess: (resp) => {
                  const { id, image } = resp;
                  return {success: {data: {
                     baseurl: `${MAIN_URL}img/`,
                     files: [image],
                     isImages: [true],
                  }}}
               },
            },
            filebrowser: {
               createNewFolder: false,
               deleteFolder: false,
               moveFolder: false,
               moveFile: false,
               ajax: {
                  method: 'get',
                  url: urlMedia,
                  data: {},
                  async: true,
                  processData: true,
                  dataType: "json",
                  prepareData: ({path}) => {
                     if (path) {
                        const paths = path.replace(/\/$/, '').split('/');
                        return {
                           page: paths.pop(),
                        }

                     }
                     return null
                  },
                  process: (resp) => {
                     const {items, _meta} = resp;
                     const { pageCount } = _meta;
                     return {
                        data: {
                           sources: {
                              default: {
                                 files: items.filter(item => !!item.image).map(item => ({
                                    file: item.image,
                                    thumb: item.image,
                                 })),
                                 folders: Array.from({length: parseInt(pageCount, 10)}).map((_, index) => (index + 1).toString() ),
                                 path: '/img',
                                 baseurl: MAIN_URL,
                              }
                           },
                        }
                     };
                  }
               },
            }
         }}
         onBlur={input.onChange}
      />
   )
};
