import { Header } from "../../Header/Header";
import { TabsSettings } from "./TabsSettings";
import React from "react";

export const CreateBuild = () => {
   return (
      <>
         <Header title="Добавить новостройку" />
         <TabsSettings />
      </>
   );
};
