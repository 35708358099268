export const commercialPropertyConstants = {
   SET_COMMERCIAL_PROPERTY: "SET_COMMERCIAL_PROPERTY",
   SET_CURRENT_COMMERCIAL_PROPERTY: "SET_CURRENT_COMMERCIAL_PROPERTY",
   SET_OFFICES_COMMERCIAL_PROPERTY: "SET_OFFICES_COMMERCIAL_PROPERTY",
   SET_OFFICE_COMMERCIAL_PROPERTY: "SET_OFFICE_COMMERCIAL_PROPERTY",
   UPDATE_OFFICE_COMMERCIAL_PROPERTY: "UPDATE_OFFICE_COMMERCIAL_PROPERTY",
   REMOVE_OFFICE_COMMERCIAL_PROPERTY: "REMOVE_OFFICE_COMMERCIAL_PROPERTY",
   SET_CURRENT_OFFICE_COMMERCIAL_PROPERTY:
      "SET_CURRENT_OFFICE_COMMERCIAL_PROPERTY",
   RESET_COOMERCIAL_PROPERTY: "RESET_COOMERCIAL_PROPERTY",
};
