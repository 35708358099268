import React from 'react';

export const ChangeText = ({position, value, handleTextEdit, handleTextareaKeyDown, editVisible}) => {
   return (
      <input
         className="control"
         value={value}
         style={{
            display: editVisible ? 'block' : 'none',
            position: 'absolute',
            top: position.textX + 'px',
            left: position.textY + 'px',
            width: 420 + 'px',
            height: 50 + 'px',
         }}
         onChange={(e) => handleTextEdit(e)}
         onKeyDown={(e) => handleTextareaKeyDown(e)}
      />
   )
};
