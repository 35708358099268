import React, { useEffect } from "react";
import { Header } from "../../Header/Header";
import Form from "./Form";
import { useDispatch, useSelector } from "react-redux";
import { editPage, receivePageDetails } from "../../../actions/pagesAction";
import { useRouteMatch, Redirect } from "react-router-dom";
import { Preloader } from "../../Preloader/Preloader";
import {publishedOptions} from "../../Fields/options";

export const UpdateSectionPageContainer = () => {
   let { params } = useRouteMatch();
   let id = params.id;

   let dispatch = useDispatch();
   const info = useSelector((state) => state.pageInfo);

   useEffect(() => {
      dispatch(receivePageDetails(id));
   }, [dispatch, id]);

   const handleSubmit = (values) => {
      values.published = values.published.value;

      dispatch(editPage(id, values));
   };

   if (info.inProgress) {
      return <Redirect to={{ pathname: "/pages" }} />;
   }

   if (info.isFetching) {
      return <Preloader />;
   }

   return info.isFetching || info.data == null ? (
      <Preloader />
   ) : (
      <>
         <Header title={info.data.name} />
         <div className="card">

            <Form
               initialValues={{
                  ...info.data,
                  published: publishedOptions.filter((item) => {
                     return item.value === info.data.published
                  })
               }}
               onSubmit={(values) => {
                  handleSubmit(values);
               }}
            />
         </div>
      </>
   );
};
