import API from "../utils/API";

const getPoll = () =>
   API.get("/poll")
      .then((res) => {
         return res.data.items;
      })
      .catch((error) => {
         console.warn(error);
      });

const getPollView = (id) =>
   API.get(`/poll/view/${id}`)
      .then(({ data }) => {
         return data;
      })
      .catch((error) => {
         console.warn(error);
      });

const createAnswer = (idPoll, name) =>
   API.post(`/poll/createanswer/${idPoll}?name=${name}`)
      .then()
      .catch((error) => {
         console.warn(error);
      });

const deleteAnswer = (id) =>
   API.delete(`/poll/deleteanswer/${id}`)
      .then(({ status }) => (status === 200 ? true : false))
      .catch((error) => {
         console.warn(error);
      });

const createPoll = ({ name, answers }, history) =>
   API.post("/poll/create", { name })
      .then(({ data }) => {
         if (data.id) {
            answers.forEach((answer) => {
               createAnswer(data.id, answer.name);
            });

            history.push(`/poll/update/${data.id}`);
         }
      })
      .catch((error) => {
         console.warn(error);
      });

const publicPoll = (id, publish) =>
   API.put(`/poll/status/${id}?publish=${publish}`)
      .then(({ status }) => (status === 200 ? true : false))
      .catch((error) => {
         console.warn(error);
      });

// const answersForUpdate = (answers) =>
//    answers.filter((item) => typeof item["id"] !== "undefined");

const answersForCreate = (answers) =>
   answers.filter((item) => typeof item["id"] === "undefined");

const updatePoll = (id, name, answers) => {
   const promiseUpdatePoll = new Promise((resolve, reject) => {
      API.put(`/poll/update/${id}`, { name })
         .then(({ status }) => {
            if (status === 200) {
               resolve(true);
            } else {
               reject(false);
            }
         })
         .catch((error) => {
            console.warn(error);
         });
   });

   const promiseUpdateAnswer = new Promise((resolve, reject) => {
      if (answersForCreate(answers).length !== 0) {
         answersForCreate(answers).forEach((answer) => {
            createAnswer(id, answer.name);
         });
         resolve(true);
      }
      resolve(true);
   });

   return Promise.all([promiseUpdatePoll, promiseUpdateAnswer]).then(
      (values) => {
         console.log(values);
         return true;
      }
   );
};

const deletePoll = (id) =>
   API.delete(`/poll/delete/${id}`)
      .then(({ status }) => (status === 200 ? true : false))
      .catch((error) => {
         console.warn(error);
      });

export const pollApi = {
   getPoll,
   getPollView,
   createPoll,
   updatePoll,
   deletePoll,
   publicPoll,
   createAnswer,
   deleteAnswer,
};
