import React, { useEffect } from "react";
import { Field, change, submit } from "redux-form";

import { LayoutFormBasis } from "../../Fields/LayoutForm";
import { RenderInput } from "../../Fields/RenderInput";
import { RenderImage } from "../../Fields/RenderImage";
import { ButtonBack } from "../../Buttons/ButtonBack";

import { OfficeCanvas } from "./OfficeCanvas";

import { isEmpty } from "../../../utils/utils";
import Image from "../../../assets/img/plan.jpg";

const JUSTIFY_CONTENT = { justifyContent: "space-between" };
const PADDING = { padding: "0 30px", display: "flex", alignItems: "center" };

export const Form = ({
   setMediaData,
   handleSubmit,
   data,
   initialize,
   urlBack,
   image,
   offices,
   dispatch,
   nameForm,
   isUpdating,
   preview,
}) => {
   useEffect(() => {
      if (!isEmpty(data) && !isEmpty(data.plan)) {
         initialize({ ...data, points: data.plan.points });
      }
   }, [data, initialize]);

   return (
      <form className="form form--basis" onSubmit={handleSubmit}>
         <div style={{ display: "flex" }}>
            <div>
               <LayoutFormBasis
                  id="id_1s"
                  style={JUSTIFY_CONTENT}
                  title="Id офиса:"
               >
                  <div className="form__item form__item--min">
                     <Field
                        id="id_1s"
                        name="id_1s"
                        className="control"
                        component={RenderInput}
                     />
                  </div>
               </LayoutFormBasis>
               <LayoutFormBasis
                  id="name"
                  style={JUSTIFY_CONTENT}
                  title="Офис №"
               >
                  <div className="form__item form__item--min">
                     <Field
                        id="name"
                        name="name"
                        className="control"
                        component={RenderInput}
                     />
                  </div>
               </LayoutFormBasis>
               <LayoutFormBasis
                  id="square"
                  style={JUSTIFY_CONTENT}
                  title="Площадь, м²"
               >
                  <div className="form__item form__item--min">
                     <Field
                        id="square"
                        name="square"
                        className="control"
                        component={RenderInput}
                     />
                  </div>
               </LayoutFormBasis>
               <LayoutFormBasis
                  id="price"
                  style={JUSTIFY_CONTENT}
                  title="Цена, руб:"
               >
                  <div className="form__item form__item--min">
                     <Field
                        id="price"
                        name="price"
                        className="control"
                        component={RenderInput}
                     />
                  </div>
               </LayoutFormBasis>
               <LayoutFormBasis
                  id="status"
                  style={JUSTIFY_CONTENT}
                  title="Статус"
               >
                  <div className="form__item form__item--min">
                     <Field
                        id="status"
                        name="status"
                        className="control"
                        component={RenderInput}
                     />
                  </div>
               </LayoutFormBasis>
            </div>
            <LayoutFormBasis id="image" title="2D планировка:" style={PADDING}>
               <div className="form__item form__item--min">
                  <Field
                     name="image"
                     id="image"
                     dop="true"
                     component={RenderImage}
                     setMediaData={setMediaData}
                  />
               </div>
            </LayoutFormBasis>
         </div>
         <div className="card card-create">
            <OfficeCanvas
               offices={offices}
               image={image || Image}
               currentPoints={data.plan ? data.plan.points : []}
               idUpdating={isUpdating ? data.id : false}
               onPoints={(points) =>
                  dispatch(change(nameForm, "points", points))
               }
               onSave={() => dispatch(submit(nameForm))}
            />
         </div>
         <div className="form__foot button-group flex">
            <ButtonBack to={urlBack} />
         </div>
      </form>
   );
};
