export const REDIRECT = "REDIRECT";

// action creators
export const redirect = link => {
   return { type: REDIRECT, payload: link };
};

export const redirectReducer = (state = {}, action) => {
   switch (action.type) {
      case REDIRECT:
         return { redirectTo: action.payload };
      default:
         return state;
   }
};
