import API from "../utils/API";
import { pagesConstants } from "../constants/pagesConstants"

const isFetchingPage = () => ({
   type: pagesConstants.IS_FETCHING,
});

export const inProgressPage = () => ({
   type: "IN_PROGRESS_PAGE",
});

/* получение страниц */
export function receivePages() {
   return (dispatch) => {
      API.get("page")
         .then((res) => {
            dispatch(fetchPagesSuccess(res.data.items));
         })
         .catch((err) => {
            dispatch(fetchPagesFailure(err));
         });
   };
}

const fetchPagesSuccess = (pages) => ({
   type: pagesConstants.FETCH_PAGES_SUCCESS,
   pages,
});

const fetchPagesFailure = (error) => ({
   type: pagesConstants.FETCH_PAGES_FAILURE,
   payload: {
      ...error,
   },
});

/* получение детальной страницы */
export function receivePageDetails(short) {
   return (dispatch) => {
      dispatch(isFetchingPage());
      API.get(`page/${short}`)
         .then((res) => {
            dispatch(fetchPageDetailsSuccess(res.data));
         })
         .catch((err) => {
            console.log(err.message);
            dispatch(fetchPageDetailsSuccess({}));
         });
   };
}

const fetchPageDetailsSuccess = (page) => ({
   type: pagesConstants.FETCH_PAGE_DETAILS_SUCCESS,
   page,
});

/* создание страницы */
export function createPage(values) {
   return (dispatch) => {
      API.post("page/create", values)
         .then((res) => {
            dispatch(setPageSuccess(res.data));
            dispatch(inProgressPage());
         })
         .catch((err) => {
            console.log(err);
         });
   };
}

/* редактировать страницу */
export function editPage(short, values) {
   return (dispatch) => {
      API.put(`page/update/${short}`, values)
         .then((res) => {
            dispatch(setPageSuccess(res.data));
            dispatch(inProgressPage());
         })
         .catch((err) => {
            console.log(err);
         });
   };
}

const setPageSuccess = (boolean) => ({
   type: pagesConstants.SET_PAGE_SUCCESS,
   boolean,
});

/* удалить страницу страницу */
export function deletePage(short) {
   return (dispatch) => {
      API.delete(`page/delete/${short}`)
         .then((res) => {
            console.log(res);
            dispatch(deletePageSuccess(res.data.id));
         })
         .catch((err) => {
            console.log(err);
         });
   };
}

const deletePageSuccess = (id) => ({
   type: pagesConstants.DELETE_PAGE,
   id,
});
