import React, {useState} from "react";
import Form from "./Form";
import {Redirect} from "react-router-dom";
import API from "../../utils/API";

export const ProfitBaseEdit = ({match}) => {
   const [redirect, setRedirect] = useState(null);

   const onSubmit = (formData) => {
      API.put(`settings/update/${match.params.id}`, formData)
         .then(() => {
            setRedirect(true);
         });
   }

   if(redirect)
      return <Redirect to="/profit-base" />;

   return (
      <div className="card">
         <Form
            id={match.params.id}
            onSubmit={onSubmit}
         />
      </div>
   );
};
