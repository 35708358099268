import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { ParkingTabs } from "./ParkingTabs";
import { Header } from "../../Header/Header";
import BasisForm from "./BasisForm";

import { getCitiesForFormSelector } from "../../../selectors/city-selectors";
import { getParkingLevelsListSelect, getParkingSelect } from "../../../selectors/parking-selectors";
import { getComplexSelect, getHouseListSelect } from "../../../selectors/complex-selectors";

import { receiveCities } from "../../../actions/cityAction";
import { receiveComplex, receiveHouse } from "../../../actions/complexActions";
import {
   createParkingObject, getParkingById, updateParkingObject, updateParkingLevelObject,
} from "../../../actions/parkingActions";
import { createMedia, deleteMedia } from "../../../actions/media";

export const ParkingCreateOrUpdate = () => {
   const [mediaData, setMediaData] = useState(null);
   const history = useHistory();
   let { params: { id } } = useRouteMatch();

   const isUpdating = !!id;

   const dispatch = useDispatch();
   const cities = useSelector(getCitiesForFormSelector);
   const complexes = useSelector(getComplexSelect);
   const houses = useSelector(getHouseListSelect);
   const currentParking = useSelector(getParkingSelect);
   const currentParkingLevels = useSelector(getParkingLevelsListSelect);
   const currentHouse = houses.find(house => house.id === +currentParking?.house_id);
   const currentCity = cities.find(city => city.value === +currentParking?.city_id);

   if (currentParking && isUpdating) {
      currentParking.house = currentHouse ? {label: currentHouse.name, value: currentHouse.id} : undefined;
      currentParking.city = currentCity ? currentCity : undefined;
      if (currentParking.image.indexOf('websalamat') === -1) {
         currentParking.preview = 'http://dc.websalamat.ru/img/' + currentParking.image;
      }
   }

   const isDataFull = !isUpdating || (
      isUpdating && currentParking &&
      (currentParking?.house_id ? currentParking?.house?.value :  true) &&
      (currentParking?.city_id ? currentParking?.city?.value : true)
   );

   if (isDataFull && currentParking) {
      currentParking.gallery_group = currentParking.images;
   }

   const complexGroups = complexes.map(complex => ({ label: complex?.name, options: [], id: complex?.id }));
   houses.forEach(house => {
      const complexId = house.complex_id;
      const currentComplex = complexGroups.find(complex => complex?.id === complexId);
      if (currentComplex) {
         currentComplex.options.push({ value: house.id, label: house.name });
      }
   });
   const houseGroups = complexGroups.filter(group => group.options.length);

   const updateParking = () => {
      if (isUpdating) {
         dispatch(getParkingById(id));
      }
   };

   useEffect(() => {
      updateParking();
      if (!cities.length) {
         dispatch(receiveCities());
      }
      if (!complexes.length) {
         dispatch(receiveComplex());
      }
      if (!houses.length) {
         dispatch(receiveHouse());
      }
   }, [dispatch]);

    const addParkingIdToLevels = result => {
       if (currentParkingLevels?.length) {
          currentParkingLevels.forEach(level => {
             if (!level.parking_id) {
                dispatch(updateParkingLevelObject(
                   { ...level, parking_id: currentParking?.id || result.id },
                   () => {},
                   level.name,
                   level.id,
                ));
             }
          })
       }
       history.push('/parking');
    };

   const handleSubmit = values => {
      const formData = new FormData();
      let imageFormData = null;
      formData.append('name', values.name);
      formData.append('brief', values.brief);
      if (!isUpdating) {
         formData.append('image', mediaData);
         values.gallery_group.forEach(image => {
            formData.append('images[]', image);
         });
      }

      formData.append('city_id', values.city?.value);
      formData.append('coord', values.coord);

      if(values.house)
         formData.append('house_id', values.house?.value);

      formData.append('description', values.description);
      if (isUpdating) {
         formData.append('id', currentParking?.id);

         // удаляем media из gallery_group
         currentParking.gallery_group.forEach(image => {
            if (!values.gallery_group.find(file => +file.id === +image.id)) {
               dispatch(deleteMedia(image.id));
            }
         });
         // добавляем media в gallery_group
         values.gallery_group.forEach(file => {
            if (!file.id) {
               const galleryFormData = new FormData();
               galleryFormData.append('image', file);
               galleryFormData.append('parent', 'parking');
               galleryFormData.append('id_parent', id);
               galleryFormData.append('type', '');
               galleryFormData.append('published', 1);
               dispatch(createMedia(galleryFormData));
            }
         })
      }

      if (mediaData) {
         imageFormData = new FormData();
         imageFormData.append('image', mediaData);
      }

      const action = isUpdating ? updateParkingObject : createParkingObject;

      dispatch(action(formData, addParkingIdToLevels, currentParking?.id, imageFormData));
   };

   return (
      <>
         <Header title={isUpdating ? "Редактировать паркинг" : "Добавить паркинг"} />

         {isDataFull ? (
            <ParkingTabs component={
               <BasisForm
                  cities={ cities }
                  houseGroups={ houseGroups }
                  setMediaData={ setMediaData }
                  onSubmit={ handleSubmit }
                  initialValues={ currentParking }
               />
            } />
         ) : null}
      </>
   );
};
