import React from "react";
import { IconUnVisibility } from "../Icons/IconUnVisibility";

export const ButtonUnVisibility = (props) => (
   <button
      className="btn-option btn-reset"
      title="Опубликовать"
      aria-label="Опубликовать"
      onClick={props.onClick}
   >
      <IconUnVisibility />
   </button>
);
