import React, {useEffect, useState} from "react";
import {RenderDropzone} from "./RenderDropzone";


export const RenderDropzoneInput = (props) => {
   const { className, input } = props;
   const { onChange, value } = input || {};

   const [files, setFiles] = useState(value || []);
   const [deleteIDs, setDeleteIDs] = useState([]);


   useEffect(() => {
      onChange(files);
   },[files, onChange]);


   return (
      <div className={className || 'form__group'}>
         <RenderDropzone setFiles={setFiles} files={files} setDeleteIDs={setDeleteIDs} />
      </div>
   )
}
