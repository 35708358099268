import React from "react";
import { Header } from "../Header/Header";
import Form from "./Form";
import { Background } from "./Background";

export const SettingContainer = () => {
   return (
      <>
         <Header title="Настройки" />
         <Background />
      </>
   );
};
