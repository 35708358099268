import React from "react";
import { Field, reduxForm } from "redux-form";
import { LayoutFormBasis } from "../../../Fields/LayoutForm";
import { RenderInput } from "../../../Fields/RenderInput";
import { useHistory } from "react-router-dom";
import { CanvasAddParkingPlace } from "../../../Canvas/CanvasCreate/CanvasAddParkingPlace";
import { ButtonBack } from "../../../Buttons/ButtonBack";
import { ButtonSave } from "../../../Buttons/ButtonSave";

const BasisForm = ({ handleSubmit, image, setNewPoint, defaultPoints }) => {
   let history = useHistory();
   return (
      <form className="form" onSubmit={handleSubmit}>
         <LayoutFormBasis id="name" title="Имя:">
            <div className="form__item form__item--min">
               <Field
                  id="name"
                  name="name"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>
         <LayoutFormBasis id="id_1s" title="Id места:">
            <div className="form__item form__item--min">
               <Field
                  id="id_1s"
                  name="id_1s"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis id="square" title="Площадь, м²">
            <div className="form__item form__item--min">
               <Field
                  name="square"
                  id="square"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis id="price" title="Цена, руб">
            <div className="form__item form__item--min">
               <Field
                  name="price"
                  id="price"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>

         <LayoutFormBasis id="status" title="Статус">
            <div className="form__item form__item--min">
               <Field
                  name="status"
                  id="status"
                  className="control"
                  component={RenderInput}
               />
            </div>
         </LayoutFormBasis>

         <CanvasAddParkingPlace
            customPoints={defaultPoints}
            setNewPoint={setNewPoint}
            image={image}
         />

         <div className="form__foot button-group flex">
            <ButtonBack onClick={() => history.goBack()} />
            <ButtonSave type="submit" />
         </div>
      </form>
   );
};

export default reduxForm({
   form: "form-parking-place",
   enableReinitialize: true,
})(BasisForm);
